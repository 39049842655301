// Function to calculate statistics based on the results for a specific stake range
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
    const moneyResult = Number(tip.moneyresult);
    return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
    const moneyResult = Number(tip.moneyresult);
    return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
    const stake = Number(tip.telikoposo);
    return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average stake
  const avgStake = totalBets !== 0 ? totalStake / totalBets : 0;

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
    const odds = Number(tip.preresultodds);
    return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
    totalBets,
    winRate: Math.round(winRate),
    totalProfit: Math.round(totalProfit),
    yieldValue: yieldValue.toFixed(2),
    avgStake: avgStake.toFixed(2),
    avgOdds: avgOdds.toFixed(2),
  };
}

export const Pontarismatwn = ({ resultedTips }) => {
  // Group results by stake ranges
  const resultsByStakes = {};

  resultedTips.forEach((tip) => {
    const stake = Number(tip.telikoposo);

    if (isNaN(stake)) return;

    let range;
    if (stake <= 50) {
      // Grouping for stakes up to 50
      range = `${Math.floor((stake - 1) / 10) * 10 + 1}-${Math.floor((stake - 1) / 10) * 10 + 10}`;
    } else {
      // Grouping for stakes above 50
      range = `${Math.floor((stake - 1) / 50) * 50 + 1}-${Math.floor((stake - 1) / 50) * 50 + 50}`;
    }

    if (!resultsByStakes[range]) {
      resultsByStakes[range] = [];
    }
    resultsByStakes[range].push(tip); // Group tips by stake range
  });

  // Sort stake ranges numerically for consistent display
  const sortedStakes = Object.keys(resultsByStakes).sort((a, b) => {
    const [minA] = a.split('-').map(Number);
    const [minB] = b.split('-').map(Number);
    return minA - minB;
  });

  return (
    <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
          <tr className="stattr">
            <th scope="col" className="px-6 py-3">Stakes</th>
            <th scope="col" className="px-6 py-3">Picks</th>
            <th scope="col" className="px-6 py-3">Win Rate</th>
            <th scope="col" className="px-6 py-3">Profit</th>
            <th scope="col" className="px-6 py-3">Yield</th>
            <th scope="col" className="px-6 py-3">Avg Stake</th>
            <th scope="col" className="px-6 py-3">Avg Odds</th>
          </tr>
        </thead>
        <tbody>
          {sortedStakes.map((range) => {
            const stats = calculateEidos(resultsByStakes[range]);
            return (
              <tr key={range} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {range}
                </th>
                <td className="px-6 py-4">{stats.totalBets}</td>
                <td className="px-6 py-4">{stats.winRate}%</td>
                <td className="px-6 py-4">{stats.totalProfit}</td>
                <td className="px-6 py-4">{stats.yieldValue}%</td>
                <td className="px-6 py-4">{stats.avgStake}</td>
                <td className="px-6 py-4">{stats.avgOdds}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
