import { Link } from "react-router-dom";

import './Button.css'

export const Button = (props) => {
  if (props.href) {
    const classes = props.buttonClasses ? props.buttonClasses : "font-medium text-blue-600 dark:text-blue-500 hover:underline";
    return (
      <a href={props.href} target="_blank" rel="noreferrer" className={classes}>
        {props.children}
      </a>
    );
  }

  if (props.to) {
    const classes = props.likeButton 
      ? "text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" 
      : props.buttonClasses 
        ? props.buttonClasses 
        : "font-medium text-blue-600 dark:text-blue-500 hover:underline";
    return (
      <Link to={props.to} className={classes} onClick={props.onClick} style={props.style}>
        {props.children}
      </Link>
    );
  }

  const classes = props.buttonClasses 
    ? props.buttonClasses 
    : `text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800 ${props.danger ? 'danger' : ""}`;
  return (
    <button type={props.type} id={props.id} onClick={props.onClick} className={classes} disabled={props.disabled} >
      {props.children}
    </button>
  );
};
