function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
      const moneyResult = Number(tip.moneyresult);
      return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
      const moneyResult = Number(tip.moneyresult);
      return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
      const stake = Number(tip.telikoposo);
      return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average stake
  const avgStake = totalBets !== 0 ? totalStake / totalBets : 0;

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
      const odds = Number(tip.preresultodds);
      return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
      totalBets,
      winRate: Math.round(winRate),
      totalProfit: Math.round(totalProfit),
      yield: yieldValue.toFixed(2),
      avgStake: avgStake.toFixed(2),
      avgOdds: avgOdds.toFixed(2)
  };
}


export const Eidous = ({ resultedTips }) => {
  const preGameResults = resultedTips.filter(tip => tip.eidostip === "REGULAR" && tip.moneyresult !== "Cancelled") || [];
  const longTermResults = resultedTips.filter(tip => tip.eidostip === "LONG TERM BET" && tip.moneyresult !== "Cancelled") || [];
  const liveBetResults = resultedTips.filter(tip => tip.eidostip === "LIVE BET" && tip.moneyresult !== "Cancelled") || [];

  const preGameStats = calculateEidos(preGameResults);
  const longTermStats = calculateEidos(longTermResults);
  const liveBetStats = calculateEidos(liveBetResults);


  return (
    <div className="relative overflow-x-auto sm:rounded-lg">
              <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
                  <tr className="stattr">
                    <th scope="col" className="px-6 py-3">Stakes/Pick</th>
                    <th scope="col" className="px-6 py-3">Picks</th>
                    <th scope="col" className="px-6 py-3">Win Rate</th>
                    <th scope="col" className="px-6 py-3">Profit</th>
                    <th scope="col" className="px-6 py-3">Yield</th>
                    <th scope="col" className="px-6 py-3">Avg Stake</th>
                    <th scope="col" className="px-6 py-3">Avg Odds</th>
                  </tr>
                </thead> 
                <tbody>   
                    {preGameResults.length > 0 && <tr className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Pre-Game
                          </th>
                          <td className="px-6 py-4">{preGameStats.totalBets}</td>
                          <td className="px-6 py-4">{preGameStats.winRate}%</td>
                          <td className="px-6 py-4">{preGameStats.totalProfit}</td>
                          <td className="px-6 py-4">{preGameStats.yield}%</td>
                          <td className="px-6 py-4">{preGameStats.avgStake}</td>
                          <td className="px-6 py-4">{preGameStats.avgOdds}</td>
                        </tr>
                      }
                      {longTermResults.length > 0 && <tr className="border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Long Term
                          </th>
                          <td className="px-6 py-4">{longTermStats.totalBets}</td>
                          <td className="px-6 py-4">{longTermStats.winRate}%</td>
                          <td className="px-6 py-4">{longTermStats.totalProfit}</td>
                          <td className="px-6 py-4">{longTermStats.yield}%</td>
                          <td className="px-6 py-4">{longTermStats.avgStake}</td>
                          <td className="px-6 py-4">{longTermStats.avgOdds}</td>
                        </tr>
                      }
                      {liveBetResults.length > 0 && <tr className="border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Live
                          </th>
                          <td className="px-6 py-4">{liveBetStats.totalBets}</td>
                          <td className="px-6 py-4">{liveBetStats.winRate}%</td>
                          <td className="px-6 py-4">{liveBetStats.totalProfit}</td>
                          <td className="px-6 py-4">{liveBetStats.yield}%</td>
                          <td className="px-6 py-4">{liveBetStats.avgStake}</td>
                          <td className="px-6 py-4">{liveBetStats.avgOdds}</td>
                        </tr>
                      }
                </tbody>
              </table>
            </div>
  )
}
