import { BrowserRouter as Router } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';

import { AuthContext } from './shared';
import './App.css';



import { AllRoutes } from './routes/AllRoutes';

let logoutTimer

const App = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState()
  const [idUser, setIdUser] = useState(null);
  const [otherUserId, setOtherUserId] = useState(null);
  const [userRole, setUserRole] = useState(null)


  const login = useCallback((uid, role, token, expirationDate) => {
    setToken(token)
    const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60)
    setTokenExpirationDate(tokenExpirationDate)
    localStorage.setItem('userData', JSON.stringify({userId: uid, token, userRole: role, expiration: tokenExpirationDate.toISOString()}))
    setIdUser(uid)
    setUserRole(role);
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setIdUser(null)
    setTokenExpirationDate(null)
    setOtherUserId(null)
    setUserRole(null);
    localStorage.removeItem('userData')
  }, [])

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime()
      logoutTimer = setTimeout(logout, remainingTime)
    } else {
      clearTimeout(logoutTimer)
    }
  }, [token, logout, tokenExpirationDate])

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'))
    if (storedData && storedData.token && new Date(storedData.expiration) > new Date()) {
      login(storedData.userId, storedData.userRole, storedData.token, new Date(storedData.expiration))
    }
  }, [login])

  const getOtherUserId = useCallback((ouid) => {
    setOtherUserId(ouid);
}, []);
  return (
    <div className="App">
      <AuthContext.Provider value={{isLoggedIn: !!token, token, userId: idUser, otherUserId: otherUserId, role: userRole, login: login, logout: logout, getOtherUserId: getOtherUserId}} >
        <Router>
          <AllRoutes />
        </Router>
      </AuthContext.Provider>
    </div>
  );
}

export default App;
