import { useState, useEffect, useContext } from "react"

import { useTitle, Button, Input, Backdrop, ErrorModal, Modal, useForm, AuthContext, LoadingSpinner, useHttpClient, ImageUpload } from "../shared/"
import { VALIDATOR_REQUIRE, VALIDATOR_COLOR } from "../shared/util/validators"

export const Etaireies = ({pageTitle}) => {
  useTitle(pageTitle);
  const [confirmDeleteModal,  setConfirmDeleteModal] = useState(false);
  const [etaireiesAll, setEtaireiesAll ] = useState([]);
  const [showEtairiaModal, setShowEtairiaModal] = useState(false);
  const [updatedEtaireiaId, setUpdatedEtaireiaId] = useState(false);
  const [updatedEtaireiaName, setUpdatedEtaireiaName] = useState(false);
  const [updatedEtaireiaAffiliate, setUpdatedEtaireiaAffiliate] = useState(false);
  const [updatedEtaireiaBackgroundColor, setUpdatedEtaireiaBackgroundColor] = useState(false);
  const [updatedEtaireiaImage, setUpdatedEtaireiaImage] = useState(false);
  const [newEtaireiaModal, setNewEtaireiaModal] = useState(false);
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  
  

  const [etairiaEditFormState, etairiaEditInputHandler, etaireiaEditSetFormData] = useForm({
    editetairiaid: {
      value: '',
      isValid: false
    },
    editetairianame: {
      value: '',
      isValid: false
    },
    editetairiaaffiliate: {
      value: '',
      isValid: false
    },
    editetairiacolor: {
      value: '',
      isValid: false
    },
    editetairialogo: {
      value: '',
      isValid: true
    }
  },
  false);

  const [newEtairiaFormState, newEtairiaInputHandler] = useForm({
    etairianame: {
      value: '',
      isValid: false
    },
    etairiaaffiliate: {
      value: '',
      isValid: false
    },
    etairiacolor: {
      value: '',
      isValid: false
    },
    etairialogo: {
      value: '',
      isValid: true
    }
  },
  false);

  const cancelModal = () => {
    setShowEtairiaModal(false);
    setUpdatedEtaireiaName(null);
    setUpdatedEtaireiaId(null); 
    // if (error) {
    //   setError(false);
    // }
  }

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
  }

  useEffect(() => {
    const fetchEtairies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/etaireies`
        )

        setEtaireiesAll(responseData.etairies)
      } catch (err) {
        
      }
    }

    fetchEtairies()
  }, [sendRequest, auth.userId])

  useEffect(() => {
    
    if (updatedEtaireiaName && updatedEtaireiaName.length > 1 ) {
      etaireiaEditSetFormData({
        editetairiaid: {
          value: updatedEtaireiaId,
          isValid: true
        },
        etairianame: {
          value: updatedEtaireiaName,
          isValid: true
        },
        etairiaaffiliate: {
          value: updatedEtaireiaAffiliate,
          isValid: true
        },
        etairiacolor: {
          value: updatedEtaireiaBackgroundColor,
          isValid: true
        }
      },
      true
    )
    }
  }, [updatedEtaireiaName, etaireiaEditSetFormData, updatedEtaireiaAffiliate, updatedEtaireiaBackgroundColor, updatedEtaireiaImage, updatedEtaireiaId])

  const etaireiaClickHandler = (id) => {
    setShowEtairiaModal(true);
    const etaireia = etaireiesAll.find( etairia => etairia.id === id);
    if (etaireia) {
      setUpdatedEtaireiaId(etaireia.id); 
      setUpdatedEtaireiaName(etaireia.name);
      setUpdatedEtaireiaAffiliate(etaireia.affiliate);
      setUpdatedEtaireiaBackgroundColor(etaireia.background_color);
      setUpdatedEtaireiaImage(etaireia.image);      
    }
  }


  const editEtaireiaHandler = async (e) => {
    e.preventDefault();
    const imageSrc = e.target.querySelector(".image-upload__preview img")?.src || null;
    console.log(imageSrc)
    try {
      const formData = new FormData()
      formData.append('id', updatedEtaireiaId)
      formData.append('name', e.target.editetairianame.value)
      formData.append('affiliateLink', e.target.editetairiaaffiliate.value)
      formData.append('backgroundColor', e.target.editetairiacolor.value)
      if (etairiaEditFormState.inputs.editetairialogo) {
        formData.append('image', etairiaEditFormState.inputs.editetairialogo.value)
      }
      formData.append('previousImage', imageSrc)
      
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/etaireies`,
        'PATCH',
        formData
      )

      const updatedEtaireia = {...etaireiesAll.find(etairia => etairia.id === updatedEtaireiaId)}
      const updatedIndex = etaireiesAll.findIndex((etair) =>  etair.id === updatedEtaireiaId);
        updatedEtaireia.name = responseData.etairia.name;
        updatedEtaireia.affiliate = responseData.etairia.affiliate;
        updatedEtaireia.background_color = responseData.etairia.background_color;
        updatedEtaireia.image = responseData.etairia.image;

        etaireiesAll[updatedIndex] = updatedEtaireia;
        setShowEtairiaModal(false);
        setEtaireiesAll(etaireiesAll);
    } catch (err) {
      
    }    
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const confirmDeleteHandler = async () => {
    
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/etaireies`,
          'DELETE',
          JSON.stringify({
            id: updatedEtaireiaId
          }),
          {
            'Content-Type': 'application/json'
          }
        )

        setEtaireiesAll(prevEtair => prevEtair.filter(etair => etair.id !== responseData.id));
        setShowEtairiaModal(false);
        setConfirmDeleteModal(false);
        setUpdatedEtaireiaId(null); 
        setUpdatedEtaireiaName(null);
        setUpdatedEtaireiaAffiliate(null);
        setUpdatedEtaireiaBackgroundColor(null);
        setUpdatedEtaireiaImage(null); 
      } catch (err) {
        
      }    
  }

  const submitEtaireiaHandler = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData()
      formData.append('name', newEtairiaFormState.inputs.etairianame.value)
      formData.append('affiliateLink', newEtairiaFormState.inputs.etairiaaffiliate.value)
      formData.append('backgroundColor', newEtairiaFormState.inputs.etairiacolor.value)
      formData.append('image', newEtairiaFormState.inputs.etairialogo.value)
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/etaireies`,
        'POST',
        formData
      )    
  
        setEtaireiesAll(prevEtair => [...prevEtair, responseData.etairia]);
        setNewEtaireiaModal(true);
        
    } catch (err) {
      console.log(err)
    }
    

    
  }

  const cancelNewAEtaireiaModal = () => {
    setNewEtaireiaModal(false);
  }

  return (
    <>
      {showEtairiaModal && <Backdrop onClick={cancelModal}/>}
      {isLoading && <LoadingSpinner asOverlay/>}
      <ErrorModal error={error} onClear={clearError}/>
      <Modal childClass="w-full" show={showEtairiaModal} onsubmit={editEtaireiaHandler} onCancel={cancelModal} header="Επεξεργασία/διαγραφή εταιρείας" footerClass="flex w-full justify-between" footer={
          <>
            <Button type="submit" disabled={!etairiaEditFormState.isValid} >
              Ενημέρωση
            </Button>
            <Button danger type="button" onClick={deleteModalHandler}>
              Διαγραφή
            </Button>
          </>}>
            <Input label="ID εταιρείας" initialValue={updatedEtaireiaId} initialValid={true} id="editetairiaid" name="editetairiaid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={etairiaEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
            <Input label="Μετονομασία εταιρείας" initialValue={updatedEtaireiaName} initialValid={true} id="editetairianame" name="editetairianame" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={etairiaEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <Input label="Αλλαγή affiliate link" initialValue={updatedEtaireiaAffiliate} initialValid={true} id="editetairiaaffiliate" name="editetairiaaffiliate" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={etairiaEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <Input label="Αλλαγή χρώματος background" initialValue={updatedEtaireiaBackgroundColor} initialValid={true} id="editetairiacolor" name="editetairiacolor" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={etairiaEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <ImageUpload label="Προσθήκη logo" onInput={etairiaEditInputHandler} initialValue={updatedEtaireiaImage !== null ? `${process.env.REACT_APP_BACKEND_URL}/${updatedEtaireiaImage}` : null} initialValid={true} id="editetairialogo" name="editetairialogo" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
      </Modal>
      <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή εταιρείας" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτήν την εταιρεία; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
      <Modal 
        childClass="w-full"
        show={newEtaireiaModal} 
        onCancel={cancelNewAEtaireiaModal} 
        header="Προσθήκη εταιρείας" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelNewAEtaireiaModal}>
              Ok
            </Button>
          </>}
      >
        <p>Η εταιρεία προστέθηκε με επιτυχία! </p>
      </Modal>
      <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Εταιρείες</h1>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στην εταιρεία που θέλετε να επεξεργαστείτε/διαγράψετε</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
          {etaireiesAll && etaireiesAll.length > 0 ? (etaireiesAll.map((etaireia) => {
            const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
            return (
              <Button
                key={etaireia.id}// Add a unique key for each element in the list
                buttonClasses={classes}
                onClick={() => etaireiaClickHandler(etaireia.id)}
              >
                {etaireia.name}
              </Button>
            );
          })
          ) : (
            <p className="text-base">Δεν βρέθηκαν εταιρείες</p>
          )
          }
        </div>
        <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη Εταιρείας</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitEtaireiaHandler}>
          <div className="mb-5">
          <Input label="Όνομα εταιρείας" id="etairianame" name="etairianame" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={newEtairiaInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
          <Input label="Affiliate link" id="etairiaaffiliate" name="etairiaaffiliate" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={newEtairiaInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
          <Input label="Κωδικός χρώματος background" id="etairiacolor" name="etairiacolor" type="text" placeholder="Κωδικός της μορφής #6666FF" validators={[VALIDATOR_REQUIRE(), VALIDATOR_COLOR()]} onInput={newEtairiaInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να ξεκινάει με #" required/>
          <ImageUpload label="Logo εταιρείας" onInput={newEtairiaInputHandler} id="etairialogo" name="etairialogo" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
          </div>
          <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!newEtairiaFormState.isValid}>Προσθήκη</Button>
          </form>
    </>
  )
}

