import { useContext } from "react";

import { useTitle, Card, Button, Input, AuthContext, ErrorModal, useForm, LoadingSpinner, useHttpClient } from "../shared"
import { VALIDATOR_REQUIRE } from "../shared/util/validators";

export const UserLogin = ({ pageTitle }) => {
  useTitle(pageTitle);

  const { isLoading, error, sendRequest, clearError } = useHttpClient() 

  const [formState, inputHandler] = useForm({
    username: {
      value: '',
      isValid: false
    },
    password: {
      value: '',
      isValid: false
    }
  }, false);

  const auth = useContext(AuthContext);
  

  const authSubmitHandler = async (event) => {
    event.preventDefault();
    console.log(process.env.REACT_APP_BACKEND_URL)
    try {
      const responseData = await  sendRequest(
        process.env.REACT_APP_BACKEND_URL, 
        'POST', 
        JSON.stringify({
          username: formState.inputs.username.value,
          password: formState.inputs.password.value
        }),
        {
          'Content-Type': 'application/json',
        }

      )

      auth.login(responseData.userId, responseData.userRole, responseData.token);
    } catch (err) {
      
    }

  }

  return (
    <>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError}/>
      <Card>
          <form className="space-y-6" onSubmit={authSubmitHandler}>
            <h1 className="text-xl font-medium text-gray-900 dark:text-white">Συνδεθείτε στον διαχειριστή προγνωστικών</h1>
            <Input label="Email/Username" id="username" name="username" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={inputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <Input label="Password" id="password" name="password" type="password" validators={[VALIDATOR_REQUIRE()]} onInput={inputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <div className="flex items-start">
            {/* eslint-disable-next-line*/}
                <a href="#" className="hide-it me-auto text-sm text-blue-700 hover:underline dark:text-blue-500">Υπενθύμηση κωδικού</a>
            </div>
            <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!formState.isValid}>Σύνδεση</Button>
        </form>
      </Card>
    </>
  )
}
