import { Button } from '../shared'
import { useTitle } from '../shared'



export const PageNotFound = ({ pageTitle }) => {
    useTitle(pageTitle);

  return (
    <main>
      <section className="flex flex-col justify-center px-2">
        <div className="flex flex-col items-center my-4">
          <h1 className="text-7xl text-gray-700 font-bold my-10 dark:text-white">404, Η σελίδα δεν βρέθηκε</h1>
          
        </div>
        <div className='flex justify-center my-4'>
            <Button to="/" likeButton>
                Επιστροφή στην αρχική
            </Button>
          
        </div>
      </section>
    </main>
  )
}
