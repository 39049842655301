import { useEffect,useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
 // eslint-disable-next-line
import { useTitle, Card, Button, Agoras, Athlimatos, Bookmakers, Eidous, Mhna, Pontarismatwn, XwrwnPrwtathlimatwn, LoadingSpinner, useHttpClient, ErrorModal  }
 from "../shared";

export const Statistika = ( { pageTitle } ) => {
  useTitle(pageTitle);

  const { userid } = useParams();

  const profitClass = useRef(null)

  const formattedProfit = useRef(0)


  const [selected, setSelected] = useState({});
  const [seasonsOverall, setseasonsOverall] = useState([]);
  const [queriedSeason, setQueriedSeason] = useState(null);
  const [tipsWithResults, setTipsWithResults] = useState([]);
  const [userInfoOverall, setUserInfoOverall] = useState([])
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userid}`)
        setCurrentUser(responseData.user)
      } catch (err) {
        
      }
    }

    fetchUser()
  }, [sendRequest, userid])

    useEffect(() => {
      const fetchUserOverall =  async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/statistika/userOverall`,
          )

          const userOverall = responseData.overall;
          setUserInfoOverall(userOverall)
          console.log(userOverall)
          if (userOverall.length > 0) {
            // Ensure we get a flat array of seasons, without duplicates
            const userOverallSeasons = userOverall[0].season;
        
            // Sort the seasons by the starting year
            const sortedOverallSeasons = userOverallSeasons.sort((a, b) => {
              const yearA = parseInt(a.split('-')[0], 10);
              const yearB = parseInt(b.split('-')[0], 10);
              return yearB - yearA;
            });
        
            // Set the properly formatted and sorted seasons to state
            setseasonsOverall(sortedOverallSeasons);
            setQueriedSeason(sortedOverallSeasons[0]);
          }
        } catch (err) {
          
        }
        
      }

      fetchUserOverall()
    }, [sendRequest, userid]);
    
    
    useEffect(() => {
      
      const fetchTipsWithResults = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/statistika/tipsWithResults`,
            'POST',
            JSON.stringify({
              queriedSeason
            }),
            {
              "Content-Type": "application/json"
            }
          )

          const userTipsWithResults = responseData.userResults
          setTipsWithResults(userTipsWithResults)
        } catch (err) {
          
        }
      }
      if (queriedSeason) {
        fetchTipsWithResults()
      }
    }, [userid, sendRequest, queriedSeason])

    useEffect(() => {
      if (userInfoOverall.length > 0 && userInfoOverall[0].totalprofit) {
        profitClass.current = userInfoOverall[0].totalprofit >= 0 ? 'text-green-400' : 'text-red-400';
        formattedProfit.current = userInfoOverall[0].totalprofit >= 0 ? `+${userInfoOverall[0].totalprofit}` : `${userInfoOverall[0].totalprofit}`;
      } else {
        formattedProfit.current = 0
      }
    },  [userInfoOverall])

    

  if (!currentUser) {
    
      return (
        <Card>
          <h1>Ο χρήστης δεν βρέθηκε</h1>
        </Card>
      );
    
  }


  const selectChangeHandler = (event, season) => {
    setSelected(prevState => ({ ...prevState, [season]: event.target.value }));
    if (event.target.value) {
      setQueriedSeason(event.target.value);
      // const currentTips = closedUserTips.filter(tip => tip.season === event.target.value);
      // setUserTips(currentTips)
    }
    
    
    

  };

  

  


  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <div className='flex justify-between border-1' >
        <h1 className="mb-3 text-2xl font-medium text-gray-900 dark:text-white">
          <span className="uppercase">{currentUser.username}</span> - Στατιστικά
        </h1>
        <div className='flex gap-x-1'>
          <Button to={`/users/${currentUser.id}/prognwstika`} buttonClasses={`text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800`}>⚽ Bets</Button>
          <Button to={`/users/${currentUser.id}/statistika`} buttonClasses={`text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800`}>📈 Στατιστικά</Button>
        </div>
      </div>

      <h2 className="mb-1 mt-7 text-xl font-medium text-gray-900 dark:text-white" >Overall</h2>
        <div className="mb-5 w-fit">
        <select 
            id={`season`}
            name={`season`}
            onChange={(event) => selectChangeHandler(event, 'season')} 
            value={selected['season'] || ''}
                 className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
            >
            <option value="">Επιλογή season</option>
                {seasonsOverall.map(option => (
            <option key={option} value={option}>{option}</option>
            ))}
        </select>
        </div>
        <div className='stat-tables'>
              <div className='w-full mb-4 bg-betblue overflow-x-auto shadow-md sm:rounded-t-lg'>
                <div className='flex justify-between items-center p-4'>
                  <h2 className="mb-1 text-xl font-medium text-white dark:text-white">Προγνωστικά</h2>
                  <p className="text-xl text-white">Overall Profit: <span className={profitClass.current}>{formattedProfit.current}</span></p> 
                </div>
              </div>
            <Eidous resultedTips={tipsWithResults}/>
            <Mhna resultedTips={tipsWithResults}/>
            <Bookmakers resultedTips={tipsWithResults} userid={userid}/>
            <Athlimatos resultedTips={tipsWithResults} userid={userid}/>
            <XwrwnPrwtathlimatwn resultedTips={tipsWithResults} userid={userid}/>
            <Agoras resultedTips={tipsWithResults} userid={userid}/>
            <Pontarismatwn resultedTips={tipsWithResults}/>
        </div>
    </>
  )
}
