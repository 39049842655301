import { useState, useContext, useEffect } from 'react';

import { VALIDATOR_REQUIRE } from '../shared/util/validators';
import { useTitle, Input, Button, Modal, useForm, Backdrop, ErrorModal, AuthContext, LoadingSpinner, useHttpClient, ImageUpload } from '../shared'

export const Xwres = ({pageTitle}) => {
  useTitle(pageTitle);
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [ xwresAll, setXwresAll ] = useState([]);
  const [ athlhmataAll, setAthlhmataAll ] = useState([]);
  const [xwresAthlimata, setXwresAthlimata] = useState([])
  const [confirmDeleteModal,  setConfirmDeleteModal] = useState(false);
  const [showXwraModal, setShowXwraModal] = useState(false);
  const [updatedXwraId,setUpdatedXwraId] = useState(null);
  const [updatedXwraName,setUpdatedXwraName] = useState(null);
  const [updatedShmaiaImage, setUpdatedXwraShmaia] = useState(false)
  const [newXwraModal, setNewXwraModal] = useState(false);

  const [xwraEditFormState, xwraEditInputHandler, xwraSetFormData] = useForm({
    editxwraid: {
      value: '',
      isValid: false
    },
    editxwraname: {
      value: '',
      isValid: false
    },
    editshmaiaxwras: {
      value: '',
      isValid: true
    }
  },
  false);

  const [xwraNewFormState, xwraNewInputHandler] = useForm({
    newxwra: {
      value: '',
      isValid: false
    },
    shmaiaxwras: {
      value: '',
      isValid: true
    }
  },
  false);

  useEffect(() => {
    const fetchXwresAthlimata = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xwres`
        )

        setXwresAll(responseData.xwres)
        setAthlhmataAll(responseData.athlimata)
      } catch (err) {
        
      }
      
    }

    fetchXwresAthlimata()
  }, [sendRequest, auth.userId])

  useEffect(() => {
    if (updatedXwraName) {
      xwraSetFormData({
        editxwraid: {
          value: updatedXwraId,
          isValid: true
        },
        editxwraname: {
          value: updatedXwraName,
          isValid: true
        }
      },
      true)
    }
    
  }, [updatedXwraName, xwraSetFormData, updatedXwraId]);

  const cancelModal = () => {
    setShowXwraModal(false);
    setUpdatedXwraName(null);
    setUpdatedXwraId(null); 
    // if (error) {
    //   setError(false);
    // }
  }

  const xwraClickHandler = async (id) => {
    const xwra = xwresAll.find( xwr => xwr.id === id);

    if (xwra) {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/tasosnaz/xwres/${id}`
        )
        



        setUpdatedXwraId(xwra.id); 
        setUpdatedXwraName(xwra.name);
        setXwresAthlimata(responseData.athimataForXwra)
        setUpdatedXwraShmaia(xwra.image)
        setShowXwraModal(true)
      } catch (err) {
        
      }
           
    }
  }

  const editXwraHandler = async (e) => {
    e.preventDefault();
    const imageSrc = e.target.querySelector(".image-upload__preview img")?.src || null;
    const athlhmataForCountry = Array.from(document.querySelectorAll('input[name="sports"]'))
        .filter(input => input.value)
        .map(input => parseInt(input.id, 10));

    try {
        const formData = new FormData();
        formData.append('id', updatedXwraId);
        formData.append('name', e.target.editxwraname.value);
        formData.append('sports', JSON.stringify(athlhmataForCountry));
        formData.append('image', xwraEditFormState.inputs.editshmaiaxwras ? xwraEditFormState.inputs.editshmaiaxwras.value : null);
        formData.append('previousImage', imageSrc);

        const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xwres`,
            'PATCH',
            formData
        );

        

        const updatedXwra = {...xwresAll.find(xwra => xwra.id === parseInt(responseData.xwra.id, 10))}

        const updatedIndex = xwresAll.findIndex((xwra) =>  xwra.id === updatedXwraId);
        updatedXwra.name = responseData.xwra.name;
        updatedXwra.image = responseData.xwra.image;
        xwresAll[updatedIndex] = updatedXwra;
        setXwresAll(xwresAll);

        setShowXwraModal(false);
        setXwresAthlimata([]);
        setUpdatedXwraName(null);
        setUpdatedXwraShmaia(null);
        setUpdatedXwraId(null);
    } catch (err) {
        console.error(err);
    }
};


  // console.dir(xwresAll, { depth: null });

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const submitXwraHandler = async (e) => {
    e.preventDefault();

      try {
        const formData = new FormData()
        formData.append('name', xwraNewFormState.inputs.newxwra.value)
        formData.append('image', xwraNewFormState.inputs.shmaiaxwras.value)
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/tasosnaz/xwres`,
          'POST',
          formData
        )


        setXwresAll(prevXwres => [...prevXwres, responseData.xwra]);
        setNewXwraModal(true);
      } catch (err) {
        
      }

  }

  const confirmDeleteHandler = async () => {

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xwres`,
        'DELETE',
        JSON.stringify({
          id: updatedXwraId
        }),
        {
          'Content-type': 'application/json'
        }
      )
      
      setXwresAll(prevXwres => prevXwres.filter(xwr => xwr.id !== responseData.id));
      setShowXwraModal(false);
      setConfirmDeleteModal(false);    
      setUpdatedXwraName(null);
      setUpdatedXwraId(null); 
      setXwresAthlimata([])
    } catch (err) {
      
    }
  }

  const cancelNewXwraModal = () => {
    setNewXwraModal(false);
  }


  return (
    <>    
          {showXwraModal && <Backdrop onClick={cancelModal}/>}
          <ErrorModal error={error} onClear={clearError}/>
          {isLoading && <LoadingSpinner asOverlay/>}
          <Modal childClass="w-full" show={showXwraModal} onsubmit={editXwraHandler} onCancel={cancelModal} header="Επεξεργασία/διαγραφή Χώρας" footerClass="flex w-full justify-between" footer={
            <>
              <Button type="submit" disabled={!xwraEditFormState.isValid} >
                Ενημέρωση
              </Button>
              <Button danger type="button" onClick={deleteModalHandler}>
                Διαγραφή
              </Button>
            </>}>
            <Input label="ID χώρας" initialValue={updatedXwraId} initialValid={true} id="editxwraid" name="editxwraid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={xwraEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
            <Input label="Μετονομασία Χώρας" initialValue={updatedXwraName} initialValid={true} id="editxwraname" name="editxwraname" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={xwraEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <ImageUpload label="Προσθήκη σημαίας" onInput={xwraEditInputHandler} initialValue={updatedShmaiaImage && typeof updatedShmaiaImage === 'string'
            ? updatedShmaiaImage.includes(process.env.REACT_APP_BACKEND_URL) 
                ? updatedShmaiaImage 
                : `${process.env.REACT_APP_BACKEND_URL}/${updatedShmaiaImage}`
            : null} initialValid={true} id="editshmaiaxwras" name="editshmaiaxwras" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
              {athlhmataAll.length > 0 && (
                <>
                    <p>Αντιστοίχηση σε άθλημα</p>
                    {athlhmataAll.map(athlhma => {
                        let exeiXwra = false;
                        if (xwresAthlimata && xwresAthlimata.length > 0) {
                          const xwraSeAthlima = xwresAthlimata.find(athl => athl.athlima_id === athlhma.id);
                          if (xwraSeAthlima) exeiXwra = true;
                        }
                       return (<Input 
                        id = {athlhma.id}
                        name = "sports"
                        key={athlhma.id}
                        element = 'input'
                        type = 'checkbox'
                        label = {athlhma.name}
                        validators = {[]}
                        onInput = {xwraEditInputHandler}
                        value={athlhma.id}
                        initialValid={true}
                        initialName = {athlhma.name}
                        initialChecked = {exeiXwra}
                    />)
})}
                </>
                )}
        </Modal>
        <Modal
          childClass="w-full"
          show={confirmDeleteModal} 
          onCancel={cancelDeleteModal} 
          header="Διαγραφή χώρας" 
          footerClass="flex w-full justify-between" 
          footer={
            <>
              <Button onClick={cancelDeleteModal}>
                Ακύρωση
              </Button>
              <Button danger onClick={confirmDeleteHandler}>
                Διαγραφή
              </Button>
            </>}
        >
          <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτήν την Χώρα; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
        </Modal>
        <Modal 
        childClass="w-full"
        show={newXwraModal} 
        onCancel={cancelNewXwraModal} 
        header="Προσθήκη χώρας" 
        footerClass="flex w-full justify-between" 
        footer={
            <Button onClick={cancelNewXwraModal}>
              Ok
            </Button>
          }
      >
        <p>Η χώρα προστέθηκε με επιτυχία! </p>
      </Modal>
        <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Χώρες</h1>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στην χώρα για επεξεργασία/διαγραφή αλλά και προσθαφαίρεση αθλημάτων.</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
          {xwresAll && xwresAll.map((xwra) => {
            const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
            return (
              <Button
                key={xwra.id}// Add a unique key for each element in the list
                buttonClasses={classes}
                onClick={() => xwraClickHandler(xwra.id)}
              >
                {xwra.name}
              </Button>
            );
          })}
        </div>
        <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη Χώρας</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitXwraHandler}>
        <div className="mb-5">
        <Input label="Νέα χώρα" id="newxwra" name="newxwra" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={xwraNewInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
        </div>
        <ImageUpload label="Σημαία χώρας" onInput={xwraNewInputHandler} id="shmaiaxwras" name="shmaiaxwras" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!xwraNewFormState.isValid}>Προσθήκη</Button>
        </form>
    </>
  )
}
