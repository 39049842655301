import { useState, useEffect, useContext } from 'react';

import { VALIDATOR_REQUIRE } from '../shared/util/validators';
import { useTitle, Input, Button, Modal, useForm, Backdrop, ErrorModal, LoadingSpinner, useHttpClient, AuthContext } from '../shared'

export const Agores = ({pageTitle}) => {
  useTitle(pageTitle);
  const [ agoresAll, setAgoresAll ] = useState([]);
  const [ athlhmataAll, setAthlhmataAll ] = useState([]);
  const [agoresAthlimata, setAgoresAthlimata] = useState([])
  const [confirmDeleteModal,  setConfirmDeleteModal] = useState(false);
  const [showAgoraModal, setShowAgoraModal] = useState(false);
  const [updatedAgoraId,setUpdatedAgoraId] = useState(null);
  const [updatedAgoraName,setUpdatedAgoraName] = useState(null);
  const [newAgoraModal, setNewAgoraModal] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const auth = useContext(AuthContext)
  

  const [agoraEditFormState, agoraEditInputHandler, agoraSetFormData] = useForm({
    editagoraid: {
      value: '',
      isValid: false
    },
    editagoraname: {
      value: '',
      isValid: false
    }
  },
  false);

  const [agoraNewFormState, agoraNewInputHandler] = useForm({
    newagora: {
      value: '',
      isValid: false
    }
  },
  false);

  useEffect(() => {
    if (updatedAgoraName) {
      agoraSetFormData({
        editagoraid: {
          value: updatedAgoraId,
          isValid: false
        },
        editagoraname: {
          value: updatedAgoraName,
          isValid: true
        }
      },
      true)
    }
    
  }, [updatedAgoraName, agoraSetFormData, updatedAgoraId]);

  useEffect(() => {
    const fetchAgoresAthlimata = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/agores`)

        setAgoresAll(responseData.agores)
        setAthlhmataAll(responseData.athlimata)
      } catch (err) {
        
      }
      
    } 

    fetchAgoresAthlimata()
  }, [sendRequest, auth.userId])

  const cancelModal = () => {
    setShowAgoraModal(false);
    setUpdatedAgoraName(null);
    setUpdatedAgoraId(null);
    setAgoresAthlimata([]) 
    // if (error) {
    //   setError(false);
    // }
  }

  const agoraClickHandler = async (id) => {
    
    const agora = agoresAll.find( agor => agor.id === id);
    if (agora) {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/agores/${id}`)
        
        setUpdatedAgoraId(agora.id); 
        setUpdatedAgoraName(agora.name);
        setAgoresAthlimata(responseData.athimataForAgora)

        setShowAgoraModal(true);
      } catch (err) {
  
      }
           
    }
  }

  

  const editAgoraHandler = async  (e) => {
    e.preventDefault();
    const athlhmataForAgores = Array.from(document.querySelectorAll('input[name="sports"]'))
    .filter(input => input.value)
    .map(input => parseInt(input.id, 10));

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/agores`,
        'PATCH',
        JSON.stringify({
          id: updatedAgoraId,
          name: e.target.editagoraname.value,
          sports: athlhmataForAgores
        }),
        {
          'Content-Type': 'application/json'
        }
      )

      const updatedAgora = {...agoresAll.find(agora => agora.id === responseData.agora.id)}
      const updatedIndex = agoresAll.findIndex((agor) =>  agor.id === updatedAgoraId);
      updatedAgora.name = responseData.agora.name;
      agoresAll[updatedIndex] = updatedAgora;
      setAgoresAll(agoresAll);
      setShowAgoraModal(false);
      setAgoresAthlimata([])
      setUpdatedAgoraName(null);
      setUpdatedAgoraId(null);
    } catch (err) {
      
    }
    
  }

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const submitAgoraHandler = async (e) => {
    e.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/agores`,
        'POST',
        JSON.stringify({
          name: agoraNewFormState.inputs.newagora.value
        }),
        {
          'Content-Type': 'application/json'
        }
      )
      setAgoresAll(prevAgores => [...prevAgores, responseData.agora]);
      setNewAgoraModal(true);
    } catch (err) {
      
    }
  }

  const confirmDeleteHandler = async () => {
      
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/agores`,
          'DELETE',
          JSON.stringify({
            id: updatedAgoraId
          }),
          {
            'Content-Type': 'application/json'
          }
        )

        setAgoresAll(prevAgores => prevAgores.filter(agor => agor.id !== responseData.id));
        setUpdatedAgoraName(null);
        setUpdatedAgoraId(null);
        setShowAgoraModal(false);
        setConfirmDeleteModal(false);
        setAgoresAthlimata([])
      } catch (err) {
        
      }
  }

  const cancelNewAgoraModal = () => {
    setNewAgoraModal(false);
  }


  return (
    <>    
          {showAgoraModal && <Backdrop onClick={cancelModal}/>}
          <ErrorModal error={error} onClear={clearError}/>
          {isLoading && <LoadingSpinner asOverlay/>}
          <Modal childClass="w-full" show={showAgoraModal} onsubmit={editAgoraHandler} onCancel={cancelModal} header="Επεξεργασία/διαγραφή Αγοράς" footerClass="flex w-full justify-between" footer={
            <>
              <Button type="submit" disabled={!agoraEditFormState.isValid} >
                Ενημέρωση
              </Button>
              <Button danger type="button" onClick={deleteModalHandler}>
                Διαγραφή
              </Button>
            </>}>
            <Input label="ID αγοράς" initialValue={updatedAgoraId} initialValid={true} id="editagoraid" name="editagoraid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={agoraEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
              <Input label="Μετονομασία Αγοράς" initialValue={updatedAgoraName} initialValid={true} id="editagoraname" name="editagoraname" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={agoraEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
              {athlhmataAll.length > 0 && (
                <>
                    <p>Αντιστοίχηση σε άθλημα</p>
                    {athlhmataAll.map(athlhma => {
                        let exeiAgora = false;
                        if (agoresAthlimata && agoresAthlimata.length > 0) {
                          const agoraSeAthlima = agoresAthlimata.find(athl => athl.athlima_id === athlhma.id);
                          if (agoraSeAthlima) exeiAgora = true;
                        }
                       return (<Input 
                        id = {athlhma.id}
                        name = "sports"
                        key={athlhma.id}
                        element = 'input'
                        type = 'checkbox'
                        label = {athlhma.name}
                        validators = {[]}
                        onInput = {agoraEditInputHandler}
                        value={athlhma.id}
                        initialValid={true}
                        initialName = {athlhma.name}
                        initialChecked = {exeiAgora}
                    />)
})}
                </>
                )}
        </Modal>
        <Modal
          childClass="w-full"
          show={confirmDeleteModal} 
          onCancel={cancelDeleteModal} 
          header="Διαγραφή αγοράς" 
          footerClass="flex w-full justify-between" 
          footer={
            <>
              <Button onClick={cancelDeleteModal}>
                Ακύρωση
              </Button>
              <Button danger onClick={confirmDeleteHandler}>
                Διαγραφή
              </Button>
            </>}
        >
          <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτήν την Αγορά; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
        </Modal>
        <Modal 
        childClass="w-full"
        show={newAgoraModal} 
        onCancel={cancelNewAgoraModal} 
        header="Προσθήκη χώρας" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelNewAgoraModal}>
              Ok
            </Button>
          </>}
      >
        <p>Η αγορά προστέθηκε με επιτυχία! </p>
      </Modal>
        <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Αγορά</h1>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στην αγορά για επεξεργασία/διαγραφή αλλά και προσθαφαίρεση αθλημάτων.</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
          {agoresAll && agoresAll.map((agora) => {
            const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
            return (
              <Button
                
                key={agora.id}// Add a unique key for each element in the list
                buttonClasses={classes}
                onClick={() => agoraClickHandler(agora.id)}
              >
                {agora.name}
              </Button>
            );
          })}
        </div>
        <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη Αγοράς</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitAgoraHandler}>
        <div className="mb-5">
        <Input label="Νέα αγορά" id="newagora" name="newagora" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={agoraNewInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
        </div>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!agoraNewFormState.isValid}>Προσθήκη</Button>
        </form>
    </>
  )
}
