import { useState, useEffect, useContext, useRef } from "react";
import { CSSTransition } from 'react-transition-group';

import { ErrorModal, Button, Modal, AuthContext, LoadingSpinner, useHttpClient } from "../../../shared";

export const PregamePicksHistory = ({userId, currentSeason, resultsOfUser, deleteTip, deleteResult, openTypes, resultsPagination}) => {
  const auth = useContext(AuthContext)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [resultForDelete, setResulForDelete] = useState(null)
  const [tipsResults, setTipsResults] = useState([])
  const [currentPage, setCurrentPage] = useState(1); 
  const [showPlhrofories, setShowPlhrofories] = useState({})
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [xwresAll, setXwresAll] = useState([])
  const [agoresAll, setAgoresAll] = useState([])
  const [etairiesAll, setEtairiesAll] = useState([])
  
  const isFirstResultRender = useRef(true);

  useEffect(() => {

    if (isFirstResultRender.current) {
      return;
    }

    const fetchLongTermTips = async () => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/userResults?page=${currentPage}&pageSize=20`,
          'POST',
          JSON.stringify({
            openTypes,
            queriedSeason: currentSeason
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        );

        setTipsResults(responseData.results);
        // setLongTermTipIds(responseData.longTermTips.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
      } catch (err) {
      }
    };

    

    fetchLongTermTips();
  }, [userId, currentPage, currentSeason, sendRequest, openTypes, auth.token]);

  useEffect(() => {
    if (currentSeason) {
      isFirstResultRender.current = true
      setCurrentPage(1)
    }
  }, [currentSeason])

  useEffect(() => {
    const fetchXwres = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/xwres`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setXwresAll(responseData.xwres)
      } catch (err) {
        
      }
    }

    fetchXwres()
  }, [sendRequest, auth.userId, auth.token])

  useEffect(() => {
    const fetchAgores = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/agores`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setAgoresAll(responseData.agores)
      } catch (err) {
        
      }
    }

    fetchAgores()
  }, [sendRequest, auth.userId, auth.token])

  useEffect(() => {
    const fetchEtairies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/etairies`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setEtairiesAll(responseData.etairies)
      } catch (err) {
        
      }
    }

    fetchEtairies()
  }, [sendRequest, auth.userId, auth.token])


  const countryById = (xwraId) => {
    const country = xwresAll.find(xwra => xwra.id === xwraId);
    if (country) {
      return country.name.slice(0, 3);
    } else {
      return 'Δεν βρεθηκε η χωρα'
    }
  }

  const agoraById = (agoraId) => {
    const agora = agoresAll.find(agora => agora.id === agoraId);
    if (agora) {
      return agora.name
    } else {
      
      return 'Δεν βρέθηκε η αγορά'
    }
  }

  const xwraImageById = (id) => {
    if (xwresAll && xwresAll.length > 0) {
      const xwraImage = xwresAll.find(xwr => xwr.id === id)?.image
      if (xwraImage)  {
        return `${process.env.REACT_APP_BACKEND_URL}/${xwraImage}`
      } else {
        return null
      }
    }
       
  }

  useEffect(() => {
    setTipsResults(resultsOfUser)
  }, [resultsOfUser])
  


  const togglePlhrofories = (id) => {
    setShowPlhrofories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const deleteTipHandler = (result) => {
    setConfirmDeleteModal(true)
    setResulForDelete(result)
  }

  const cancelDeleteModal = () => setConfirmDeleteModal(false)

  const confirmDeleteHandler = async () => {
    try {
      if (resultsPagination.totalPages > 1 && currentPage === resultsPagination.totalPages && tipsResults.length === 1) {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/tipResults/${resultForDelete.id}?page=${currentPage-1}&pageSize=20`,
        'DELETE',
        JSON.stringify({
          openTypes,
          queriedSeason: currentSeason
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      )

      if (responseData.newOverallInfo.length > 0) {
        deleteTip(responseData.newOverallInfo);
      } 

      if (responseData.statsBySeason.length > 0) {
        deleteResult(responseData.statsBySeason)
      }

      setTipsResults(responseData.results);
      // setLongTermTipIds(responseData.results.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
      setConfirmDeleteModal(false)
      setResulForDelete(null)
      resultsPagination.totalResults = resultsPagination.totalResults - 1
      handlePreviousPage()
      resultsPagination.totalPages = resultsPagination.totalPages - 1  
      } else {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/tipResults/${resultForDelete.id}?page=${currentPage}&pageSize=20`,
          'DELETE',
          JSON.stringify({
            openTypes,
            queriedSeason: currentSeason
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        )
  
        if (responseData.newOverallInfo.length > 0) {
          deleteTip(responseData.newOverallInfo);
        } 
  
        if (responseData.statsBySeason.length > 0) {
          deleteResult(responseData.statsBySeason)
        }
        setTipsResults(responseData.results);
        // setLongTermTipIds(responseData.results.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
        setConfirmDeleteModal(false)
        setResulForDelete(null)
        resultsPagination.totalResults = responseData.pagination.totalResults
        resultsPagination.totalPages = responseData.pagination.totalPages
      }  
      

    } catch (err) {
      setResulForDelete(null)
    }

  }

  const etairiesById = (etairiaId, odds) => {
    const etairia = etairiesAll.find(et => et.id === etairiaId);
    if (etairia) {
      return (
        <Button href={etairia.affiliate} buttonClasses="flex items-center	w-full">
          <div style={{backgroundColor: etairia.background_color}} className="flex items-center rounded-full border-1 	border-white w-11	h-11 overflow-hidden relative	left-1.5">
            <img src={`${process.env.REACT_APP_BACKEND_URL}/${etairia.image}`} alt='betting company' />
          </div>
          <span className="bg-white flex items-center justify-center h-8 font-bold leading-none grow">{odds.toFixed(2)}</span>
          <span style={{backgroundColor: 'crimson'}} className="text-white w-10 h-8 text-3xl font-bold leading-none">»</span>
        </Button>
      );
    } else {
      return (
        <Button buttonClasses="flex items-center w-full">
          <span className="bg-white flex items-center rounded-3xl justify-center h-8 font-bold leading-none grow">{odds.toFixed(2)}</span>
        </Button>
      );
    }
  }


  const resulProfitById = (money) => {
    
      if (!money) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">Δεν βρέθηκε αποτέλεσμα</p>;
      }
      let profitLoss = parseInt(money.replace(/[^0-9-]/g, ''), 10);
      if (!isNaN(profitLoss) && profitLoss >= 0) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-green-700">{money}</p>
      } else if (!isNaN(profitLoss) && profitLoss < 0) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-red-800">{money}</p>
      } else if (money === "-") {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>
      } else {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-red-800">Cancelled</p>
      }
    
  } 
  
  const totalPages = resultsPagination ? resultsPagination.totalPages : 1;

  const currentResults = tipsResults

  const handleNextPage = () => {
    isFirstResultRender.current = false
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    isFirstResultRender.current = false
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  

  

  return (
    <div className="mb-5">
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή προγνωστικού" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτό το προγνωστικό; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
          <div className='w-full bg-betblue overflow-x-auto sm:rounded-t-lg p-4 mt-6'>
            <h2 className="mb-1 text-xl font-medium text-white dark:text-white">Pre Game Picks History</h2>
          </div>
          <div className="liveContainer">
          {currentResults && currentResults.length > 0 ? (
          currentResults.map(result => (
            (
              <div key={result.id} className="bg-gray-100 mb-4 flex flex-wrap justify-between items-center p-4 text-center dark:bg-gray-700 dark:border-gray-700">
                {result.selectapotel1 !== null && result.selectapotel2 === null && result.selectapotel3 === null ? (
                  <>
                    <div className="flex justify-between items-center p-1 w-full">
                      <div className="flex gap-x-3 items-center">
                        <img className="w-7 h-5" src={xwraImageById(result.xwra1)} alt="flag" />
                        <p><span className="uppercase">{`${countryById(result.xwra1)}`}</span> ➣ {result.ghpedouxos1} - {result.filoxenoumenos1}</p>
                        {result.popuptext && <Button type="button" onClick={() => togglePlhrofories(result.id)}>Ανάλυση</Button>}
                      </div>
                      <div className="flex gap-x-3">

                      {auth.role === "admin" ? (
                      <>
                        <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                        <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                          <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                          <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                        </>
                        ) :  ""}

                        
                        <p>								
                          <span>🕑 {new Date(result.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                          <span>{result.time1}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                      <div className="flex-1 w-full">
                        <p>Αγορά</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora1)}`}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Προγνωστικό</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick1}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Stake</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.stake}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Αποτέλεσμα</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel1}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Odds</p>
                      {etairiesById(result.etairia, parseFloat(result.preresultodds))}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Profit/Loss</p>
                        {resulProfitById(result.moneyresult)} 
                      </div>
                    </div>
                    <CSSTransition
                      in={!!showPlhrofories[result.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{result.popuptext ? result.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                  </>
                ) : result.selectapotel2 !== null && result.selectapotel3 === null ? (
                  <>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3 items-center">
                      <img className="w-7 h-5" src={xwraImageById(result.xwra1)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(result.xwra1)}`}</span> ➣ {result.ghpedouxos1} - {result.filoxenoumenos1}</p>
                      {result.popuptext && <Button type="button" onClick={() => togglePlhrofories(result.id)}>Ανάλυση</Button>}
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(result.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{result.time1}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora1)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick1}</p>
                    </div>
                    <div className="flex-1 w-full">
                      
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel1}</p>
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3">
                      <img className="w-7 h-5" src={xwraImageById(result.xwra2)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(result.xwra2)}`}</span> ➣ {result.ghpedouxos2} - {result.filoxenoumenos2}</p>
                    </div>
                    <div className="flex gap-x-3">

                    {auth.role === "admin" ? (
                      <>
                        <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                        <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                          <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                          <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                        </>
                        ) :  ""}
                      <p>								
                        <span>🕑 {new Date(result.date2).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{result.time2}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora2)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick2}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Stake</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.stake}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel2}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Odds</p>
                    {etairiesById(result.etairia, parseFloat(result.preresultodds))}
                    </div>
                    <div className="flex-1 w-full">
                      <p>Profit/Loss</p>
                      {resulProfitById(result.moneyresult)} 
                    </div>
                  </div>
                  <CSSTransition
                      in={!!showPlhrofories[result.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{result.popuptext ? result.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                </>
                ) : result.selectapotel3 !== null? (
                  <>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3 items-center">
                      <img className="w-7 h-5" src={xwraImageById(result.xwra1)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(result.xwra1)}`}</span> ➣ {result.ghpedouxos1} - {result.filoxenoumenos1}</p>
                      {result.popuptext && <Button type="button" onClick={() => togglePlhrofories(result.id)}>Ανάλυση</Button>}
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(result.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{result.time1}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora1)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick1}</p>
                    </div>
                    <div className="flex-1 w-full">
                      
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel1}</p>
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3">
                      <img className="w-7 h-5" src={xwraImageById(result.xwra2)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(result.xwra2)}`}</span> ➣ {result.ghpedouxos2} - {result.filoxenoumenos2}</p>
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(result.date2).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{result.time2}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora2)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick2}</p>
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel2}</p>
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                      <div className="flex gap-x-3">
                        <img className="w-7 h-5" src={xwraImageById(result.xwra3)} alt="flag" />
                        <p><span className="uppercase">{`${countryById(result.xwra3)}`}</span> ➣ {result.ghpedouxos3} - {result.filoxenoumenos3}</p>
                      </div>
                      <div className="flex gap-x-3">

                      {auth.role === "admin" ? (
                      <>
                        <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                        <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                          <Button to={`/users/${userId}/tips/${result.tipid}`}>✍</Button>

                          <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${result.id}`}>✍</Button>


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(result)}>❌</Button>
                        </>
                        ) :  ""}
                        <p>								
                          <span>🕑 {new Date(result.date3).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                          <span>{result.time3}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                      <div className="flex-1 w-full">
                        <p>Αγορά</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(result.agora3)}`}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Προγνωστικό</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.pick3}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Stake</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.stake}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Αποτέλεσμα</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{result.keimenoapotel3}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Odds</p>
                      {etairiesById(result.etairia, parseFloat(result.preresultodds))}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Profit/Loss</p>
                        {resulProfitById(result.moneyresult)} 
                      </div>
                    </div>
                    <CSSTransition
                      in={!!showPlhrofories[result.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{result.popuptext ? result.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                </>
              ) : null}
              </div>
            )
          ))
        ) : (
          <p className="text-center mt-4">Δεν βρέθηκε ιστορικό για αυτήν την season</p>
        )}
          { totalPages > 1 && 
            <div className="flex justify-center mt-4 items-center">
              <Button disabled={currentPage === 1} onClick={handlePreviousPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
              «
              </Button>
              <span className="mx-4">{`${currentPage} από ${totalPages}`}</span>
              <Button disabled={currentPage === totalPages} onClick={handleNextPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
                »
              </Button>
            </div>
            }
      </div>
    </div>
  
  )
}
