import { useState, useEffect, useContext } from "react"
import { useNavigate } from 'react-router-dom'

import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_PASSWORD } from "../shared/util/validators"
import { useTitle, Button, Modal, Input, useForm, Backdrop, ErrorModal, AuthContext, LoadingSpinner, useHttpClient } from "../shared"


export const Xrhstes = ({pageTitle}) => {
  useTitle(pageTitle);
  const auth = useContext(AuthContext);

  const [usersLive, setUsersLive] = useState([]);
  const [showUsersModal, setShowUsersModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [newUsersModal, setNewUsersModal] = useState(false);
  const [deleteUsersModal, setDeleteUsersModal] = useState(false);
  const [updateUsersModal, setUpdateUsersModal] = useState(false);
  const [updatedUsersRole, setUpdatedUsersRole] = useState(null);
  const [updatedUsersName, setUpdatedUsersName] = useState(null);
  const [updatedUsersPassword, setUpdatedUsersPassword] = useState(null);
  const [usernameAfterUpdate, setUserNameAfterUpdate] = useState(null)
  const [updatedLongTerm, setUpdatedLongTerm] = useState(null);
  const [updatedLiveBet, setUpdatedLiveBet] = useState(null);
  const [updatedUsersId, setUpdatedUsersId] = useState(null);

  const { isLoading, error, sendRequest, clearError} = useHttpClient()
  const navigate = useNavigate()

  const [usersEditFormState, usersEditInputHandler, usersEditSetFormData] = useForm({
    editUsersId: {
      value: '',
      isValid: false
    },
    editUsers: {
      value: '',
      isValid: false
    },
    editpassword: {
      value: '',
      isValid: false
    },
    edituserrole: {
      value: '',
      isValid: false
    },
    editlongterm: {
      value: '',
      isValid: false
    },
    editlivebet: {
      value: '',
      isValid: false
    }
  },
  false);

  const [newXrhstesFormState, newXrhstesInputHandler] = useForm({
    newusername: {
      value: '',
      isValid: false
    },
    newpassword: {
      value: '',
      isValid: false
    },
    userrole: {
      value: '',
      isValid: false
    },
    longterm: {
      value: '',
      isValid: false
    },
    livebet: {
      value: '',
      isValid: false
    }
  },
  false);

  useEffect(() => {
    const fetchXrhstes = async () => {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xrhstes`)
      if (responseData) {
        setUsersLive(responseData.xrhstes)
      }
      
    }

    fetchXrhstes()
  }, [auth.userId, sendRequest])

  useEffect(() => {
    if (updatedUsersId) {
      const user = usersLive.find(user => user.id === updatedUsersId);
      if (user) {
        usersEditSetFormData({
          editUsersId: {
            value: user.id,
            isValid: true
          },
          editUsers: {
            value: user.username,
            isValid: true
          },
          editpassword: {
            value: user.password,
            isValid: true
          },
          edituserrole: {
            value: user.role,
            isValid: true
          },
          editlongterm: {
            value: user.long_term,
            isValid: false
          },
          editlivebet: {
            value: user.live_bet,
            isValid: false
          }
        }, true);
      }
    }
  }, [updatedUsersId, usersEditSetFormData, usersLive]);

  const cancelModal = () => {
    setShowUsersModal(false);
    setUpdatedUsersName(null);
    setUpdatedUsersId(null);
    setUpdatedUsersRole(null);
    setUpdatedLongTerm(null);
    setUpdatedLiveBet(null); 
    // if (error) {
    //   setError(false);
    // }
  }

  const updateHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xrhstes`,
        'PATCH',
        JSON.stringify({
          id: updatedUsersId,
          username: usersEditFormState.inputs.editUsers.value,
          password: usersEditFormState.inputs.editpassword.value,
          userRole: usersEditFormState.inputs.edituserrole.value,
          longTermAppear: usersEditFormState.inputs.editlongterm.value,
          liveBetAppear: usersEditFormState.inputs.editlivebet.value
        }),
        {
          'Content-Type': 'application/json'
        }
      )
      setUserNameAfterUpdate(usersEditFormState.inputs.editUsers.value)
      setUpdatedUsersPassword(usersEditFormState.inputs.editpassword.value)

      

      const updatedUser = {...usersLive.find(user => user.id === updatedUsersId)};
      const userIndex = usersLive.findIndex(user => user.id === updatedUsersId);
      updatedUser.role = responseData.xrhsths.role;
      updatedUser.username = responseData.xrhsths.username;
      updatedUser.password = responseData.xrhsths.password;
      updatedUser.long_term = responseData.xrhsths.long_term;
      updatedUser.live_bet = responseData.xrhsths.live_bet;
      usersLive[userIndex] = updatedUser;
      setShowUsersModal(false);
      setUsersLive(usersLive);
      setUpdatedUsersRole(null);
      setUpdatedLongTerm(null)
      setUpdatedLiveBet(null);
      setUpdateUsersModal(true) 
    } catch (err) {
      
    } 
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const usersClickHandler = (id) => {
    setShowUsersModal(true);
    const user = usersLive.find( use => use.id === id);
    if (user) {
      setUpdatedUsersName(user.username);
      setUpdatedUsersId(user.id);
      setUpdatedUsersRole(user.role); 
      setUpdatedLongTerm(user.long_term)
      setUpdatedLiveBet(user.live_bet);  
    }
  }

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
  }

  const confirmDeleteHandler = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xrhstes`,
          'DELETE',
          JSON.stringify({
            id: usersEditFormState.inputs.editUsersId.value
          }),
          {
            'Content-Type': 'application/json'
          }
        )

        setUsersLive(prevUsers => prevUsers.filter(user => user.id !== responseData.id));
        setShowUsersModal(false);
        setConfirmDeleteModal(false);
        setDeleteUsersModal(true);
        setUpdatedUsersName(null);
        setUpdatedUsersId(null);
        setUpdatedUsersRole(null); 
        setUpdatedLongTerm(null)
        setUpdatedLiveBet(null);
        
      } catch (err) {
        
      }
      
    
  }

  const cancelNewXrhsthsModal = () => {
    setNewUsersModal(false);
  }

  const cancelDeleteXrhsthsModal = () => {
    setDeleteUsersModal(false);
  }

  const cancelUpdateXrhsthsModal = () => {
    if (auth.userId === updatedUsersId && ((updatedUsersPassword !== '' && updatedUsersPassword !== null && updatedUsersPassword !== undefined) || updatedUsersName !== usernameAfterUpdate)) {
      setUpdatedUsersId(null);
      setUserNameAfterUpdate(null)
      setUpdatedUsersName(null);
      setUpdatedUsersPassword(null)
      setUpdateUsersModal(false);
      auth.logout();
      navigate('/');
    } else {
      setUpdatedUsersId(null);
      setUpdatedUsersName(null);
      setUserNameAfterUpdate(null)
      setUpdatedUsersPassword(null)
      setUpdateUsersModal(false);
    }
    
  }

  const submitXrhsthHandler = async (e) => {
    e.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/xrhstes`,
        'POST',
        JSON.stringify({
          username: newXrhstesFormState.inputs.newusername.value,
          password: newXrhstesFormState.inputs.newpassword.value,
          userRole: newXrhstesFormState.inputs.userrole.value,
          longTermAppear: newXrhstesFormState.inputs.longterm.value,
          liveBetAppear: newXrhstesFormState.inputs.livebet.value
        }),
        {
          'Content-Type': 'application/json'
        }
      )
      setUsersLive(prevUsers => [...prevUsers, responseData.xrhsths])
      setNewUsersModal(true);
    } catch (err) {
      
    }

  }
  
  

  return (
    <>
      {showUsersModal && <Backdrop onClick={cancelModal}/>}
        {isLoading && <LoadingSpinner  asOverlay/>}
        <ErrorModal error={error} onClear={clearError}/>
        <Modal childClass="w-full" show={showUsersModal} onCancel={cancelModal} header="Επεξεργασία/διαγραφή χρήστη" footerClass="flex w-full justify-between" footer={
            <>
              <Button inverse disabled={!usersEditFormState.isValid}  onClick={updateHandler}>
                Ενημέρωση
              </Button>
              <Button danger onClick={deleteModalHandler}>
                Διαγραφή
              </Button>
            </>}>
              <Input label="ID χρήστη" initialValue={updatedUsersId} initialValid={true} id="editUsersId" name="editUsersId" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={usersEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
              <Input label="Όνομα χρήστη" initialValue={updatedUsersName} initialValid={true} id="editUsers" name="editUsers" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={usersEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
              <Input label="Password" id="editpassword" validators={[]} name="editpassword" type="text" onInput={usersEditInputHandler}  initialValid={true} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει 10 με 16 χαρακτήρες, κεφαλαία και μικρά γράμματα, αριθμούς, ειδικούς χαρακτήρες, χωρίς κενά"/>
              <Input label={"Επιλέξτε τον ρόλο του χρήστη"} id="edituserrole" name="edituserrole" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={usersEditInputHandler} errorText="Πρέπει να επιλέξετε έναν ρόλο" initialSelected={updatedUsersRole} initialValue={updatedUsersRole} initialValid={true} options={['admin', 'user']} defaultOption="Επιλέξτε ρόλο" required/>
              <Input label={"Επιλέξτε που θα εμφανίζονται τα Long Term Bets"} id="editlongterm" name="editlongterm" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={usersEditInputHandler} errorText="Πρέπει να κάνετε μια επιλογή" initialSelected={updatedLongTerm} initialValue={updatedLongTerm} initialValid={true} options={['REGULAR', 'LONG TERM BET', 'LIVE BET']} defaultOption="Επιλέξτε το σημείο" required/>
              <Input label={"Επιλέξτε που θα εμφανίζονται τα Live Bet"} id="editlivebet" name="editlivebet" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={usersEditInputHandler} errorText="Πρέπει να κάνετε μια επιλογή" initialSelected={updatedLiveBet} initialValue={updatedLiveBet} initialValid={true} options={['REGULAR', 'LONG TERM BET', 'LIVE BET']} defaultOption="Επιλέξτε το σημείο" required/>
        </Modal>
        <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή Χρήστη" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτόν τον χρήστη; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
      <Modal 
        childClass="w-full"
        show={newUsersModal} 
        onCancel={cancelNewXrhsthsModal} 
        header="Προσθήκη χρήστη" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelNewXrhsthsModal}>
              Ok
            </Button>
          </>}
      >
        <p>Ο χρήστης προστέθηκε με επιτυχία! </p>
      </Modal>
      <Modal 
        childClass="w-full"
        show={deleteUsersModal} 
        onCancel={cancelDeleteXrhsthsModal} 
        header="Προσθήκη χρήστη" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteXrhsthsModal}>
              Ok
            </Button>
          </>}
      >
        <p>Ο χρήστης διαγράφηκε με επιτυχία! </p>
      </Modal>
      <Modal 
        childClass="w-full"
        show={updateUsersModal} 
        onCancel={cancelUpdateXrhsthsModal} 
        header="Προσθήκη χρήστη" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelUpdateXrhsthsModal}>
              Ok
            </Button>
          </>}
      >
        {auth.userId === updatedUsersId && ((updatedUsersPassword !== '' && updatedUsersPassword !== null && updatedUsersPassword !== undefined) || updatedUsersName !== usernameAfterUpdate) ?  <p>Ο χρήστης ενημερώθηκε με επιτυχία! θα μεταβείτε στην σελίδα σύνδεσης για να ξανακάνετε login με τα καινούρια σας στοιχεία</p> : <p>Ο χρήστης ενημερώθηκε με επιτυχία!</p>}
      </Modal>
      <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Χρήστες</h1>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στον χρήστη που θέλετε να επεξεργαστείτε/διαγράψετε</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
          {usersLive && usersLive.map((user) => {
            const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
            return (
              <Button
                key={user.id}// Add a unique key for each element in the list
                buttonClasses={classes}
                onClick={() => usersClickHandler(user.id)}
              >
                {user.username}
              </Button>
            );
          })}
        </div>
        <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη χρήστη</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitXrhsthHandler}>
          <div className="mb-5">
          <Input label="Email/Username" id="newusername" name="newusername" type="text" validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)]} onInput={newXrhstesInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει το λιγότερο 6 χαρακτήρες" required/>
          <Input label="Password" id="newpassword" name="newpassword" type="password" validators={[VALIDATOR_REQUIRE(), VALIDATOR_PASSWORD()]} onInput={newXrhstesInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει 10 με 16 χαρακτήρες, κεφαλαία και μικρά γράμματα, αριθμούς, ειδικούς χαρακτήρες, χωρίς κενά" required/>
          <Input label={"Επιλέξτε τον ρόλο του χρήστη"} id="userrole" name="userrole" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={newXrhstesInputHandler} errorText="Πρέπει να επιλέξετε έναν ρόλο" options={['admin', 'user']} defaultOption="Επιλέξτε ρόλο" required/>
          <Input label={"Επιλέξτε που θα εμφανίζονται τα Long Term Bets"} id="longterm" name="longterm" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={newXrhstesInputHandler} errorText="Πρέπει να κάνετε μια επιλογή" options={['REGULAR', 'LONG TERM BET', 'LIVE BET']} defaultOption="Επιλέξτε το σημείο" required/>
          <Input label={"Επιλέξτε που θα εμφανίζονται τα Live Bets"} id="livebet" name="livebet" type="select" validators={[VALIDATOR_REQUIRE()]} onInput={newXrhstesInputHandler} errorText="Πρέπει να κάνετε μια επιλογή" options={['REGULAR', 'LONG TERM BET', 'LIVE BET']} defaultOption="Επιλέξτε το σημείο" required/>
          </div>
          <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!newXrhstesFormState.isValid}>Προσθήκη</Button>
        </form>
    </>
  )
}

