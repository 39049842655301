export const Overall = ({currentSeason, statsPerSeasons, userOverall}) => {
  let profitClass, formattedProfit, formattedSeason, totalBets, totalYield, betsWon, betsLost, betsVoided, successRate, averageOdds, seasonProfit
  
  if (userOverall.length > 0 && userOverall[0].totalprofit) {
    profitClass = userOverall[0].totalprofit >= 0 ? 'text-green-400' : 'text-red-400';
    formattedProfit = userOverall[0].totalprofit >= 0 ? `+${userOverall[0].totalprofit}` : `${userOverall[0].totalprofit}`;
  } else {
    formattedProfit = 0
  }

  if (currentSeason && typeof currentSeason === 'string') {
      const seasonStartYear = currentSeason.split('-')[0];
      const seasonEndYear = currentSeason.split('-')[1];
  
      formattedSeason = `08/${seasonStartYear}-07/${seasonEndYear}`;
    }
  
  if (statsPerSeasons.length > 0 ) {
    totalBets = statsPerSeasons[0].totalbets || 0
    totalYield = statsPerSeasons[0].yield || 0;
    betsWon = statsPerSeasons[0].won || 0
    betsLost = statsPerSeasons[0].lost || 0
    betsVoided = statsPerSeasons[0].void || 0
    successRate = statsPerSeasons[0].successrate || 0
    averageOdds = statsPerSeasons[0].averageodds || 0
    seasonProfit = statsPerSeasons[0].seasonprofit || 0
  } 
  
  

  return (
    <>
      <div className='w-full bg-betblue overflow-x-auto shadow-md sm:rounded-t-lg'>
        <div className='flex justify-between items-center p-4'>
          <h2 className="mb-1 text-xl font-medium text-white dark:text-white">Προγνωστικά</h2>
          <p className="text-xl text-white">Overall Profit: <span className={profitClass}>{formattedProfit}</span></p> 
        </div>
      </div>
      <div className="bg-gray-100 flex md:flex-row flex-col flex-wrap justify-between items-center p-4 text-center dark:bg-gray-700 dark:border-gray-700">
        <p className="w-1/3">Season: {formattedSeason}</p>
        <p className="w-1/3">Won: {betsWon || 0}</p>
        <p className="w-1/3">Success rate: {successRate !== undefined ? `${successRate}%` : '0%'}</p>
        <p className="w-1/3">Total bets: {totalBets || 0}</p>
        <p className="w-1/3">Lost: {betsLost || 0}</p>
        <p className="w-1/3">Avg odds: {averageOdds || 0}</p>
        <p className="w-1/3">Yield: {totalYield !== undefined ? `${totalYield}%` : '0%'}</p>
        <p className="w-1/3">Void: {betsVoided || 0}</p>
        <p className="w-1/3">Profit: {seasonProfit || 0}</p>
      </div>
    </>
  );
};
