import { useState, useRef, useEffect } from 'react'

import { Button } from '../../../shared/index'
import './ImageUpload.css'


export const ImageUpload = ({id, name, center = null, onInput, errorText, label = null, initialValue}) => {
  
  const [file, setFile] = useState()
  const [previewUrl, setPreviewUrl] = useState(initialValue)
  const [isValid, setIsValid] = useState(false)

  const filePickerRef = useRef()
  useEffect(() => {
    if (!file) {
        return
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
        setPreviewUrl(fileReader.result)
    }
    fileReader.readAsDataURL(file)
  }, [file])
  
  const pickedHandler = event => {
    let pickedFile
    let fileIsValid = isValid
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    if (
      event.target.files &&
      event.target.files.length === 1 &&
      allowedTypes.includes(event.target.files[0].type)
    ) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      setIsValid(true);
      fileIsValid = true;
    } else {
      setIsValid(false);
      fileIsValid = false;
    }

    onInput(id, pickedFile, fileIsValid)
  }

  const pickImageHandler = () => {
    filePickerRef.current.click()
  }


  return (
    <div className='form-control'>
        <input id={id} name={name} style={{display: 'none'}} ref={filePickerRef} type="file" accept=".jpg,.png,.jpeg" onChange={pickedHandler}/>
        <div className={`image-upload ${center && 'center'}`}>
            <div className="image-upload__preview">
                {previewUrl && <img src={previewUrl} alt="Preview"/>}
                {!previewUrl && label && <p>{label}</p>}
                {!previewUrl && !label && <p>Επιλέξτε μια φωτογραφία</p>}
            </div>
            {previewUrl && <Button
                    type="button"
                    onClick={() => {
                    setFile();
                    setPreviewUrl();
                    setIsValid(false);
                    onInput(id, null, true);
                    }}
                >
                    Καθαρισμός
                </Button>
            }
            <Button type="button" onClick={pickImageHandler}>Μεταφόρτωση φωτογραφίας</Button>
        </div>
    </div>
  )
}
