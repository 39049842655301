import { useEffect, useState } from "react";
import { Button, LoadingSpinner, useHttpClient, ErrorModal } from "../../../shared";

// Function to calculate statistics based on the results for a specific market
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
    const moneyResult = Number(tip.moneyresult);
    return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
    const moneyResult = Number(tip.moneyresult);
    return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
    const stake = Number(tip.telikoposo);
    return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
    const odds = Number(tip.preresultodds);
    return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
    totalBets,
    winRate: Math.round(winRate),
    totalProfit: Math.round(totalProfit),
    yieldValue: yieldValue.toFixed(2),
    avgOdds: avgOdds.toFixed(2),
  };
}

export const Agoras = ({ resultedTips, userid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [agoresAll, setAgoresAll] = useState([])
  // Create a lookup object for market names
  const marketNameLookup = agoresAll.reduce((acc, agora) => {
    acc[agora.id] = agora.name;
    return acc;
  }, {});

  useEffect(() => {
    const fetchAgores = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/agores`
        )

        setAgoresAll(responseData.agores)
      } catch (err) {
        
      }
    }

    fetchAgores()
  }, [sendRequest, userid])

  // Group results by market
  const resultsByMarket = {};

  resultedTips.forEach((tip) => {
    const markets = [tip.agora1, tip.agora2, tip.agora3].filter(Boolean); // Get all non-empty markets
    markets.forEach((marketId) => {
      const marketName = marketNameLookup[marketId];
      if (marketName) {
        if (!resultsByMarket[marketName]) {
          resultsByMarket[marketName] = [];
        }
        resultsByMarket[marketName].push(tip); // Group tips by market
      }
    });
  });

  // Sort markets alphabetically for consistent display
  const sortedMarkets = Object.keys(resultsByMarket).sort();

  // Pagination setup
  const [currentPage, setCurrentPage] = useState(1);
  const marketsPerPage = 20;
  const totalPages = Math.ceil(sortedMarkets.length / marketsPerPage);

  // Sorting state
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');

  // Handle sorting
  const handleSort = (field) => {
    const newOrder = sortField === field && sortOrder === 'desc' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(newOrder);
  };

  // Apply sorting to markets
  const sortedMarketsStats = sortedMarkets.map((market) => {
    const stats = calculateEidos(resultsByMarket[market]);
    return { market, ...stats };
  });

  if (sortField) {
    sortedMarketsStats.sort((a, b) => {
      if (sortOrder === 'desc') {
        return b[sortField] - a[sortField];
      } else {
        return a[sortField] - b[sortField];
      }
    });
  }

  // Get current page data
  const currentMarkets = sortedMarketsStats.slice(
    (currentPage - 1) * marketsPerPage,
    currentPage * marketsPerPage
  );

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
    <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
          <tr className="stattr">
            <th scope="col" className="px-6 py-3">Market</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('totalBets')}>Picks ⇣</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('winRate')}>Win Rate ⇣</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('totalProfit')}>Profit ⇣</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('yieldValue')}>Yield ⇣</th>
            <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('avgOdds')}>Average Odds ⇣</th>
          </tr>
        </thead>
        <tbody>
          {currentMarkets.map(({ market, totalBets, winRate, totalProfit, yieldValue, avgOdds }) => (
            <tr key={market} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
              <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {market}
              </th>
              <td className="px-6 py-4">{totalBets}</td>
              <td className="px-6 py-4">{winRate}%</td>
              <td className="px-6 py-4">{totalProfit}</td>
              <td className="px-6 py-4">{yieldValue}%</td>
              <td className="px-6 py-4">{avgOdds}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {totalPages > 1 &&
        <div className="flex mt-4 items-center justify-center">
          <Button disabled={currentPage === 1} onClick={handlePreviousPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
            «
          </Button>
          <span className="mx-4">{`${currentPage} από ${totalPages}`}</span>
          <Button disabled={currentPage === totalPages} onClick={handleNextPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
            »
          </Button>
        </div>
      }
    </div>
  </>
  );
};
