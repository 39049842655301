
export const Card = (props) => {
  return (
    

<div className="w-full max-w-sm p-4 m-auto mt-14 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
    {props.children}
</div>

  )
}
