import { useEffect, useState } from "react";

import { LoadingSpinner, useHttpClient, ErrorModal  } from "../../../shared";

// Function to calculate statistics based on the results for a specific sport
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
    const moneyResult = Number(tip.moneyresult);
    return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
    const moneyResult = Number(tip.moneyresult);
    return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
    const stake = Number(tip.telikoposo);
    return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average stake
  const avgStake = totalBets !== 0 ? totalStake / totalBets : 0;

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
    const odds = Number(tip.preresultodds);
    return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
    totalBets,
    winRate: Math.round(winRate),
    totalProfit: Math.round(totalProfit),
    yield: yieldValue.toFixed(2),
    avgStake: avgStake.toFixed(2),
    avgOdds: avgOdds.toFixed(2),
  };
}

export const Athlimatos = ({ resultedTips, userid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [athlimataAll, setAthlimataAll] = useState([])
  // Create a lookup object for quick access to sport names by ID
  const sportNameLookup = athlimataAll.reduce((acc, athlima) => {
    acc[athlima.id] = athlima.name;
    return acc;
  }, {});

  useEffect(() => {
    const fetchAthlimata = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${userid}/athlimata`)

        setAthlimataAll(responseData.athlimata)
      } catch (err) {
        
      }
      
    }

    fetchAthlimata()
  }, [sendRequest, userid])

  // Group results by sport name
  const resultsBySport = {};

  resultedTips.forEach((tip) => {
    const sportIds = [tip.athlima1, tip.athlima2, tip.athlima3].filter(Boolean); // Get all non-empty sports
    sportIds.forEach((sportId) => {
      const sportName = sportNameLookup[sportId];
      if (sportName) {
        if (!resultsBySport[sportName]) {
          resultsBySport[sportName] = [];
        }
        resultsBySport[sportName].push(tip); // Group tips by sport
      }
    });
  });

  // Sort sports alphabetically for consistent display
  const sortedSports = Object.keys(resultsBySport).sort();

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
    <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
          <tr className="stattr">
            <th scope="col" className="px-6 py-3">Sport</th>
            <th scope="col" className="px-6 py-3">Picks</th>
            <th scope="col" className="px-6 py-3">Win Rate</th>
            <th scope="col" className="px-6 py-3">Profit</th>
            <th scope="col" className="px-6 py-3">Yield</th>
            <th scope="col" className="px-6 py-3">Avg Stake</th>
            <th scope="col" className="px-6 py-3">Avg Odds</th>
          </tr>
        </thead>
        <tbody>
          {sortedSports.map((sportName) => {
            const sportStats = calculateEidos(resultsBySport[sportName]);
            return (
              <tr key={sportName} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {sportName}
                </th>
                <td className="px-6 py-4">{sportStats.totalBets}</td>
                <td className="px-6 py-4">{sportStats.winRate}%</td>
                <td className="px-6 py-4">{sportStats.totalProfit}</td>
                <td className="px-6 py-4">{sportStats.yield}%</td>
                <td className="px-6 py-4">{sportStats.avgStake}</td>
                <td className="px-6 py-4">{sportStats.avgOdds}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </>
  );
};
