import ReactDOM from "react-dom"
import { CSSTransition } from "react-transition-group"

import './SideDrawer.css'

export const SideDrawer = props => {
    const content = (
        <CSSTransition
            in={props.show}
            timeout={200}
            classNames="slide-in-left"
            mountOnEnter
            unmountOnExit
        >
            <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen" aria-label="Sidenav" onClick={props.onClick}><div className="overflow-y-auto h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">{props.children}</div></aside>
        </CSSTransition>
    )
  return ReactDOM.createPortal(content, document.getElementById('drawer-hook'));
}
