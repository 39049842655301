import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle, Button, Modal, ErrorModal, LoadingSpinner, useHttpClient, Card } from "../shared";


export const EpeksergasiaApotelesmatwn = ({ pageTitle }) => {
  useTitle(pageTitle);

  const resultOptions = [
    { id: 'w', name: 'W (Won)' },
    { id: 'v', name: 'V (Void)' },
    { id: 'l', name: 'L (Lost)' },
    { id: 'w/2', name: 'W/2 (Half won)' },
    { id: 'l/2', name: 'L/2 (Half lost)' },
    { id: 'x', name: 'X (Canceled)' }
  ];

  const { userid } = useParams();
  const [selectedSeason, setSelectedSeason] = useState({});
  const [seasonsOverall, setseasonsOverall] = useState([]);
  const [queriedSeason, setQueriedSeason] = useState(null);


  const [keimenoapotelvalue, setKeimenoapotelvalue] = useState({});
  const [keimenoapotelvalue11, setKeimenoapotelvalue11] = useState({});
  const [keimenoapotelvalue12, setKeimenoapotelvalue12] = useState({});
  const [keimenoapotelvalue21, setKeimenoapotelvalue21] = useState({});
  const [keimenoapotelvalue22, setKeimenoapotelvalue22] = useState({});
  const [keimenoapotelvalue23, setKeimenoapotelvalue23] = useState({});

  const [selected, setSelected] = useState({});
  const [selected1, setSelected1] = useState({});
  const [selected2, setSelected2] = useState({});
  const [selected3, setSelected3] = useState({});

  const [results, setResults] = useState([]);
  const [showResultSubmitModal, setShowResultSubmitModal] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [currentUser, setCurrentUser] = useState(null)
  const resultsRef = useRef([]);
  const [prwtathlimataNames, setPrwtathlimataNames] = useState([])

  useEffect(() => {
      const fetchSeasons = async () => {
        try {
        
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/epeksergasia-apotelesmatwn`
        )

        const sortedOverallSeasons = responseData.seasons.sort((a, b) => {
          const yearA = parseInt(a.split('-')[0], 10);
          const yearB = parseInt(b.split('-')[0], 10);
          return yearB - yearA;
        });

        setseasonsOverall(sortedOverallSeasons);
        setQueriedSeason(sortedOverallSeasons[0]);
        } catch (err) {
          
        }
      }
  
      
      
      
      fetchSeasons()
  }, [sendRequest, userid]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userid}`)
        setCurrentUser(responseData.user)
      } catch (err) {
        
      }
      
    }

    fetchUser()
  }, [sendRequest, userid])

  useEffect(() => {
    if (!queriedSeason) return;
    
    const fetchApotelesmata = async () => {
      
      try {
        const existingIds = resultsRef.current.length > 0 ? resultsRef.current.map(result => result.id) : [];
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/epeksergasia-apotelesmatwn/season`,
          'POST',
          JSON.stringify({
            season: queriedSeason,
            existingIds
          }),
          {
            'Content-Type': 'application/json'
          }
        )

        setResults(prevResults => {
          const newResults = responseData.apotelesmata

          newResults.forEach(result => {
            if (result.keimenoapotel1 !== null && result.keimenoapotel2 === null && result.keimenoapotel3 === null) {
              setKeimenoapotelvalue(prevState => ({ ...prevState, [result.id]: result.keimenoapotel1 }));
              setSelected(prevState => ({...prevState, [result.id]: result.selectapotel1}))
            }
            
            if (result.keimenoapotel2 !== null && result.keimenoapotel3 === null) {
              setKeimenoapotelvalue11(prevState => ({ ...prevState, [result.id]: result.keimenoapotel1 }));
              setKeimenoapotelvalue12(prevState => ({ ...prevState, [result.id]: result.keimenoapotel2 }));
              setSelected1(prevState => ({...prevState, [result.id]: result.selectapotel1}));
              setSelected2(prevState => ({...prevState, [result.id]: result.selectapotel2}));
            }
    
            if (result.keimenoapotel3 !== null) {
              setKeimenoapotelvalue21(prevState => ({ ...prevState, [result.id]: result.keimenoapotel1 }));
              setKeimenoapotelvalue22(prevState => ({ ...prevState, [result.id]: result.keimenoapotel2 }));
              setKeimenoapotelvalue23(prevState => ({ ...prevState, [result.id]: result.keimenoapotel3 }));
              setSelected1(prevState => ({...prevState, [result.id]: result.selectapotel1}));
              setSelected2(prevState => ({...prevState, [result.id]: result.selectapotel2}));
              setSelected3(prevState => ({...prevState, [result.id]: result.selectapotel3}));
            }
          });

          const updatedResults = [...prevResults, ...newResults];
          resultsRef.current = updatedResults;
          return updatedResults;
        });
      } catch (err) {
        
      }
            
    }
    

    fetchApotelesmata()
  }, [userid, queriedSeason, sendRequest]);

  useEffect(() => {
    const fetchPrwtathlimata = async () => {
      
      
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prwtathlimatanames`
        )
        
        setPrwtathlimataNames(responseData.prwtathlimata)
      } catch (err) {
        
      }
      
    }

    fetchPrwtathlimata()
  }, [sendRequest, userid])

  const selectSeasonChangeHandler = (event, season) => {
    setKeimenoapotelvalue({});
    setKeimenoapotelvalue11({});
    setKeimenoapotelvalue12({});
    setKeimenoapotelvalue21({});
    setKeimenoapotelvalue22({});
    setKeimenoapotelvalue23({});

    setSelected({});
    setSelected1({});
    setSelected2({});
    setSelected3({});

    setResults([]);
    setSelectedSeason(prevState => ({ ...prevState, [season]: event.target.value }));
    if (event.target.value) {
      setQueriedSeason(event.target.value);
    }
    
    
    

  };

  const changeHandler = (e, resultId, type, field) => {
    if (type === 'single') {
      setKeimenoapotelvalue(prevState => ({ ...prevState, [resultId]: e.target.value }));
    } 

    if (type === 'double') {
      if (field === 'first') setKeimenoapotelvalue11(prevState => ({ ...prevState, [resultId]: e.target.value }));
      if (field === 'second') setKeimenoapotelvalue12(prevState => ({ ...prevState, [resultId]: e.target.value }));
    }

    if (type === 'triple') {
      if (field === 'first') setKeimenoapotelvalue21(prevState => ({ ...prevState, [resultId]: e.target.value }));
      if (field === 'second') setKeimenoapotelvalue22(prevState => ({ ...prevState, [resultId]: e.target.value }));
      if (field === 'third') setKeimenoapotelvalue23(prevState => ({ ...prevState, [resultId]: e.target.value }));
    }
  };

  const selectChangeHandler = (event, resultId, field) => {
    if (field === 'first') {
      setSelected1(prevState => ({ ...prevState, [resultId]: event.target.value }));
    } else if (field === 'second') {
      setSelected2(prevState => ({ ...prevState, [resultId]: event.target.value }));
    } else if (field === 'third') {
      setSelected3(prevState => ({ ...prevState, [resultId]: event.target.value }));
    } else {
      setSelected(prevState => ({ ...prevState, [resultId]: event.target.value }));
    }
  };

  const cancelResultSubmitModal = () => {
    setShowResultSubmitModal(false);
  }

  const submitApotelesmaHandler = async (event, result, type) => {
    event.preventDefault();
    console.log(result)
      

      if (type === 'single') {

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/epeksergasia-apotelesmatwn`,
            'PATCH',
            JSON.stringify({
              result,
              type,
              newKeimenoapotel1: event.target[`keimenoapotel${result.id}`].value,
              newSelectapotel1: event.target[`resultselect${result.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }
      }

      if (type === 'diplo') {

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/epeksergasia-apotelesmatwn`,
            'PATCH',
            JSON.stringify({
              result,
              type,
              newKeimenoapotel1: keimenoapotelvalue11[result.id],
              newKeimenoapotel2: event.target[`keimenoapotel2${result.id}`].value,
              newSelectapotel1: selected1[result.id],
              newSelectapotel2: event.target[`resultselect2${result.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }
            
        
      }

      if (type === 'triplo') {

        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/epeksergasia-apotelesmatwn`,
            'PATCH',
            JSON.stringify({
              result,
              type,
              newKeimenoapotel1: keimenoapotelvalue21[result.id],
              newKeimenoapotel2: keimenoapotelvalue22[result.id],
              newKeimenoapotel3: event.target[`keimenoapotel3${result.id}`].value,
              newSelectapotel1: selected1[result.id],
              newSelectapotel2: selected2[result.id],
              newSelectapotel3: event.target[`resultselect3${result.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }

      }
  }

  if (!currentUser) {
    return <Card><h1>Ο χρήστης δεν βρέθηκε</h1></Card>
  }
  if (results.length === 0) {
    return (
      <>
        <Button to={`/users/${currentUser.id}/prognwstika`}>Προβολή προγνωστικών</Button>
        <h1 className="mt-8 mb-3 text-2xl font-medium text-gray-900 dark:text-white">
            <span className="uppercase">{currentUser.username}</span> - Επεξεργασία Αποτελεσμάτων
          </h1>
          <h2 className="mb-1 mt-7 text-xl font-medium text-gray-900 dark:text-white" >Επιλέξτε την σεζόν για την οποία θέλετε να κάνετε επεξεργασία αποτελεσμάτων</h2>
        <div className="mb-5 w-fit">
        <select 
            id={`season`}
            name={`season`}
            onChange={(event) => selectSeasonChangeHandler(event, 'season')} 
            value={selectedSeason['season'] || ''}
                 className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
            >
            <option value={`${seasonsOverall && seasonsOverall.length > 0 ? seasonsOverall[0] : ""}`}>{seasonsOverall && seasonsOverall.length > 0 ? seasonsOverall[0] : "Επιλογή season"}</option>
                {seasonsOverall.map((option, index) => {
                 if (index > 0) return <option key={option} value={option}>{option}</option>
                 return ''
                })}
        </select>
        </div>
        <h2 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">Δεν βρέθηκαν αποτελέσματα για τον χρήστη {currentUser.username} σε αυτήν την σεζόν</h2>
      </>
    )
  }

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <Modal
          childClass="w-full"
          show={showResultSubmitModal} 
          onCancel={cancelResultSubmitModal} 
          header="Επεξεργασία αποτελέσματος" 
          footerClass="flex w-full justify-between" 
          footer={
              <Button onClick={cancelResultSubmitModal}>
                Ok
              </Button>
            }
        >
           <p>Το αποτέλεσμα ενημερώθηκε με επιτυχία! </p>
        </Modal>
      <Button to={`/users/${currentUser.id}/prognwstika`}>Προβολή προγνωστικών</Button>
      {results.length > 0 && ( 
        <>
          <h1 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">
            <span className="uppercase">{currentUser.username}</span> - Επεξεργασία Αποτελεσμάτων
          </h1>
          <h2 className="mb-1 mt-7 text-xl font-medium text-gray-900 dark:text-white" >Επιλέξτε την σεζόν για την οποία θέλετε να κάνετε επεξεργασία αποτελεσμάτων</h2>
        <div className="mb-5 w-fit">
        <select 
            id={`season`}
            name={`season`}
            onChange={(event) => selectSeasonChangeHandler(event, 'season')} 
            value={selectedSeason['season'] || ''}
                 className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
            >
            <option value={`${seasonsOverall && seasonsOverall.length > 0 ? seasonsOverall[0] : ""}`}>{seasonsOverall && seasonsOverall.length > 0 ? seasonsOverall[0] : "Επιλογή season"}</option>
                {seasonsOverall.map((option, index) => {
                 if (index > 0) return <option key={option} value={option}>{option}</option>
                 return ''
                })}
        </select>
        </div>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">ΠΡΩΤΑΘΛΗΜΑ</th>
                  <th scope="col" className="px-6 py-3">Η/Μ</th>
                  <th scope="col" className="px-6 py-3">ΩΡΑ</th>
                  <th scope="col" className="px-6 py-3">ΑΓΩΝΑΣ</th>
                  <th scope="col" className="px-6 py-3">TIPS</th>
                  <th scope="col" className="px-6 py-3">ΑΠΟΔΟΣΗ</th>
                  <th scope="col" className="px-6 py-3">STAKE</th>
                  <th scope="col" className="px-6 py-3 text-center">ΑΠΟΤΕΛΕΣΜΑ</th>
                </tr>
              </thead> 
              <tbody> 
                {results.map((result, index) => {

                  if (result.odds3 === null && result.odds2 === null) {
                    return (
                      <tr className="border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={result.id || index}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                        </th>
                        <td className="px-6 py-4">{new Date(result.date1).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{result.time1 ? result.time1.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{result.ghpedouxos1} - {result.filoxenoumenos1}</td>
                        <td className="px-6 py-4">{result.pick1}</td>
                        <td className="px-6 py-4">{result.odds1}</td>
                        <td className="px-6 py-4">{result.telikoposo}</td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, result, 'single')}>
                            <input 
                              id={`keimenoapotel${result.id}`}
                              name={`keimenoapotel${result.id}`}
                              key={`keimenokey${result.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, result.id, 'single')}
                              value={keimenoapotelvalue[result.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              required
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect${result.id}`}
                              name={`resultselect${result.id}`}
                              key={`resultkey${result.id}`}
                              onChange={(event) => selectChangeHandler(event, result.id)} 
                              value={selected[result.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                              required
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Ενημέρωση</Button>
                          </form>
                        </td>
                      </tr>
                      )
                  } else if (result.odds3 === null && result.odds2 !== null) {
                    return (
                      <>
                        <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`prwto${result.id || index}`}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'}
                        </th>
                        <td className="px-6 py-4">{new Date(result.date1).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{result.time1 ? result.time1.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{result.ghpedouxos1} - {result.filoxenoumenos1}</td>
                        <td className="px-6 py-4">{result.pick1}</td>
                        <td className="px-6 py-4">{result.odds1}</td>
                        <td className="px-6 py-4"></td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                            <input 
                              id={`keimenoapotel1${result.id}`}
                              name={`keimenoapotel1${result.id}`}
                              key={`keimenokey1${result.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, result.id, 'double', 'first')}
                              value={keimenoapotelvalue11[result.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect1${result.id}`}
                              name={`resultselect1${result.id}`}
                              key={`resultkey1${result.id}`}
                              onChange={(event) => selectChangeHandler(event, result.id, 'first')} 
                              value={selected1[result.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </form>
                        </td>
                      </tr>
                      <tr className="border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`deutero${result.id || index}`}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima2)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                      </th>
                      <td className="px-6 py-4">{new Date(result.date2).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{result.time2 ? result.time2.slice(0, 5) : 'N/A'}</td>
                      <td className="px-6 py-4">{result.ghpedouxos2} - {result.filoxenoumenos2}</td>
                      <td className="px-6 py-4">{result.pick2}</td>
                      <td className="px-6 py-4">{result.odds2}</td>
                      <td className="px-6 py-4">{result.telikoposo}</td>
                      <td className="px-6 py-4">
                        <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, result, 'diplo')}>
                          <input 
                            id={`keimenoapotel2${result.id}`}
                            name={`keimenoapotel2${result.id}`}
                            key={`keimenokey2${result.id}`} 
                            type='text'
                            onChange={(e) => changeHandler(e, result.id, 'double', 'second')}
                            value={keimenoapotelvalue12[result.id] || ''}
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                            
                            maxLength='7'
                          />
                          <select 
                              id={`resultselect2${result.id}`}
                              name={`resultselect2${result.id}`}
                              key={`resultkey2${result.id}`}
                              onChange={(event) => selectChangeHandler(event, result.id, 'second')} 
                              value={selected2[result.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                          >
                            <option value="">Αποτέλεσμα</option>
                            {resultOptions.map(option => (
                              <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                          </select>
                          <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Ενημέρωση</Button>
                        </form>
                      </td>
                    </tr>
                  </>
                    );
                  } else {
                    return (
                      <>
                          <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`prwto${result.id || index}`}>
                          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'}
                          </th>
                          <td className="px-6 py-4">{new Date(result.date1).toLocaleDateString('el-GR', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                          })}</td>
                          <td className="px-6 py-4">{result.time1 ? result.time1.slice(0, 5) : 'N/A'}</td>
                          <td className="px-6 py-4">{result.ghpedouxos1} - {result.filoxenoumenos1}</td>
                          <td className="px-6 py-4">{result.pick1}</td>
                          <td className="px-6 py-4">{result.odds1}</td>
                          <td className="px-6 py-4"></td>
                          <td className="px-6 py-4">
                            <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                              <input 
                                id={`keimenoapotel1${result.id}`}
                                name={`keimenoapotel1${result.id}`}
                                key={`keimenokey1${result.id}`} 
                                type='text'
                                onChange={(e) => changeHandler(e, result.id, 'triple', 'first')}
                                value={keimenoapotelvalue21[result.id] || ''}
                                className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                                maxLength='7'
                              />
                              <select 
                                id={`resultselect1${result.id}`}
                                name={`resultselect1${result.id}`}
                                key={`resultkey1${result.id}`}
                                onChange={(event) => selectChangeHandler(event, result.id, 'first')} 
                                value={selected1[result.id] || ''}
                                className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                              >
                                <option value="">Αποτέλεσμα</option>
                                {resultOptions.map(option => (
                                  <option key={option.id} value={option.id}>{option.name}</option>
                                ))}
                              </select>
                            </form>
                          </td>
                        </tr>
                        <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`deutero${result.id || index}`}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima2)?.name) || 'Δεν βρέθηκε πρωτάθλημα'}
                        </th>
                        <td className="px-6 py-4">{new Date(result.date2).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{result.time2 ? result.time2.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{result.ghpedouxos2} - {result.filoxenoumenos2}</td>
                        <td className="px-6 py-4">{result.pick2}</td>
                        <td className="px-6 py-4">{result.odds2}</td>
                        <td className="px-6 py-4"></td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                            <input 
                              id={`keimenoapotel2${result.id}`}
                              name={`keimenoapotel2${result.id}`}
                              key={`keimenokey2${result.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, result.id, 'triple', 'second')}
                              value={keimenoapotelvalue22[result.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect2${result.id}`}
                              name={`resultselect2${result.id}`}
                              key={`resultkey2${result.id}`}
                              onChange={(event) => selectChangeHandler(event, result.id, 'second')} 
                              value={selected2[result.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </form>
                        </td>
                      </tr>
                      <tr className="border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`trito${result.id || index}`}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === result.prwtathlima3)?.name) || 'Δεν βρέθηκε πρωτάθλημα'}
                        </th>
                        <td className="px-6 py-4">{new Date(result.date3).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{result.time3 ? result.time3.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{result.ghpedouxos3} - {result.filoxenoumenos3}</td>
                        <td className="px-6 py-4">{result.pick3}</td>
                        <td className="px-6 py-4">{result.odds3}</td>
                        <td className="px-6 py-4">{result.telikoposo}</td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, result, 'triplo')}>
                            <input 
                              id={`keimenoapotel3${result.id}`}
                              name={`keimenoapotel3${result.id}`}
                              key={`keimenokey3${result.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, result.id, 'triple', 'third')}
                              value={keimenoapotelvalue23[result.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect3${result.id}`}
                              name={`resultselect3${result.id}`}
                              key={`resultkey3${result.id}`}
                              onChange={(event) => selectChangeHandler(event, result.id, 'third')} 
                              value={selected3[result.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                            <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Ενημέρωση</Button>
                          </form>
                        </td>
                      </tr>
                    </>
                    )
                  }

                  
                }
                )
                }
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
