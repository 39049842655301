import { useState, useEffect, useContext, useRef } from "react";
import { CSSTransition } from 'react-transition-group';

import { ErrorModal, Button, Modal, AuthContext, LoadingSpinner, useHttpClient } from "../../../shared";

export const LongTermBets = ({userId, currentSeason, userTipsLongTerm, deleteTip, deleteResult, longTermTypes, longTermPagination}) => {
  const auth = useContext(AuthContext)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [tipId, setTipId] = useState(null)
  const [tipslongTerm, setTipslongTerm] = useState([])
  const [longTermTipIds, setLongTermTipIds] = useState([])
  const [longTermResults, setLongTermResults] = useState([])
  const [currentPage, setCurrentPage] = useState(1); 
  const [showLongTermBets, setShowLongTermBets] = useState(false)
  const [showPlhrofories, setShowPlhrofories] = useState({})
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [xwresAll, setXwresAll] = useState([])
  const [agoresAll, setAgoresAll] = useState([])
  const [etairiesAll, setEtairiesAll] = useState([])
  // const [pagination, setPagination] = useState(longTermPagination)

  const isFirstLongRender = useRef(true);

  
  useEffect(() => {

    if (isFirstLongRender.current) {
      return;
    }

    const fetchLongTermTips = async () => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/userLongTermTips?page=${currentPage}&pageSize=15`,
          'POST',
          JSON.stringify({
            longTermTypes,
            queriedSeason: currentSeason
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        );

        setTipslongTerm(responseData.longTermTips);
        setLongTermTipIds(responseData.longTermTips.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
      } catch (err) {
      }
    };

    

    fetchLongTermTips();
  }, [userId, currentPage, currentSeason, sendRequest, longTermTypes, auth.token]);

  useEffect(() => {
    if (currentSeason) {
      isFirstLongRender.current = true
      setCurrentPage(1)
    }
  }, [currentSeason])



  useEffect(() => {
    const fetchApotelesmata = async () => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/resultByTipId/`,
          'POST',
          JSON.stringify({
            ids: longTermTipIds
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setLongTermResults(responseData.apotelesmata)
      } catch (err) {
        
      }
      
    }

    if (longTermTipIds && longTermTipIds.length > 0) fetchApotelesmata()
    
  }, [longTermTipIds, userId, sendRequest, auth.token])

  useEffect(() => {
    const fetchXwres = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/xwres`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setXwresAll(responseData.xwres)
      } catch (err) {
        
      }
    }

    fetchXwres()
  }, [sendRequest, auth.userId, auth.token])

  useEffect(() => {
    const fetchAgores = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/agores`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setAgoresAll(responseData.agores)
      } catch (err) {
        
      }
    }

    fetchAgores()
  }, [sendRequest, auth.userId, auth.token])

  useEffect(() => {
    const fetchEtairies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prognwstika/etairies`, 'GET', null, {
            'Authorization': `Bearer ${auth.token}`
          }
        )

        setEtairiesAll(responseData.etairies)
      } catch (err) {
        
      }
    }

    fetchEtairies()
  }, [sendRequest, auth.userId, auth.token])

  const countryById = (xwraId) => {
    const country = xwresAll.find(xwra => xwra.id === xwraId);
    if (country) {
      return country.name.slice(0, 3);
    } else {
      return 'Δεν βρεθηκε η χωρα'
    }
  }

  const agoraById = (agoraId) => {
    const agora = agoresAll.find(agora => agora.id === agoraId);
    if (agora) {
      return agora.name
    } else {
      
      return 'Δεν βρέθηκε η αγορά'
    }
  }

  useEffect(() => {
    setTipslongTerm(userTipsLongTerm)
    setLongTermTipIds(userTipsLongTerm.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
  }, [userTipsLongTerm])


  const togglePlhrofories = (id) => {
    setShowPlhrofories((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const deleteTipHandler = (id) => {
    setConfirmDeleteModal(true)
    
    setTipId(id)
  }

  const cancelDeleteModal = () => setConfirmDeleteModal(false)

  const confirmDeleteHandler = async () => {
    
    try {
      if (longTermPagination.totalPages > 1 && currentPage === longTermPagination.totalPages && tipslongTerm.length === 1) {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/longterm/${tipId}?page=${currentPage-1}&pageSize=15`,
        'DELETE',
        JSON.stringify({
          longTermTypes,
          season: currentSeason
        }),
        {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${auth.token}`
        }
      )

      if (responseData.newOverallInfo.length > 0) {
        deleteTip(responseData.newOverallInfo);
      } 

      if (responseData.statsBySeason.length > 0) {
        deleteResult(responseData.statsBySeason)
      }

      setTipslongTerm(responseData.results);
      setLongTermTipIds(responseData.results.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
      setConfirmDeleteModal(false)
      setTipId(null)
      longTermPagination.totalLongTerm = longTermPagination.totalLongTerm - 1
      handlePreviousPage()
      longTermPagination.totalPages = longTermPagination.totalPages - 1  
      } else {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/prognwstika/longterm/${tipId}?page=${currentPage}&pageSize=15`,
          'DELETE',
          JSON.stringify({
            longTermTypes,
            season: currentSeason
          }),
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${auth.token}`
          }
        )
  
        if (responseData.newOverallInfo.length > 0) {
          deleteTip(responseData.newOverallInfo);
        } 
  
        if (responseData.statsBySeason.length > 0) {
          deleteResult(responseData.statsBySeason)
        }
        setTipslongTerm(responseData.results);
        setLongTermTipIds(responseData.results.filter(longTip => longTip.open === false)?.map(longTip =>  longTip.id ) || [])
        setConfirmDeleteModal(false)
        setTipId(null)
        longTermPagination.totalLongTerm = responseData.pagination.totalLongTerm
        longTermPagination.totalPages = responseData.pagination.totalPages
      }  
      

    } catch (err) {
      setTipId(null)
    }
  }

  const etairiesById = (etairiaId, odds) => {
    const etairia = etairiesAll.find(et => et.id === etairiaId);
    if (etairia) {
      return (
        <Button href={etairia.affiliate} buttonClasses="flex items-center	w-full">
          <div style={{backgroundColor: etairia.background_color}} className="flex items-center rounded-full border-1 	border-white w-11	h-11 overflow-hidden relative	left-1.5">
            <img src={`${process.env.REACT_APP_BACKEND_URL}/${etairia.image}`} alt='betting company' />
          </div>
          <span className="bg-white flex items-center justify-center h-8 font-bold leading-none grow">{odds.toFixed(2)}</span>
          <span style={{backgroundColor: 'crimson'}} className="text-white w-10 h-8 text-3xl font-bold leading-none">»</span>
        </Button>
      );
    } else {
      return (
        <Button buttonClasses="flex items-center w-full">
          <span className="bg-white flex items-center rounded-3xl justify-center h-8 font-bold leading-none grow">{odds.toFixed(2)}</span>
        </Button>
      );
    }
  }

  const xwraImageById = (id) => {
    if (xwresAll && xwresAll.length > 0) {
      const xwraImage = xwresAll.find(xwr => xwr.id === id)?.image
      if (xwraImage)  {
        return `${process.env.REACT_APP_BACKEND_URL}/${xwraImage}`
      } else {
        return null
      }
    }
       
  } 

  const resultIdByTipid = (id) => {
    const apotelesmaById = longTermResults.find(apotelesma => apotelesma.tipid === id);
    if (apotelesmaById) {
      return apotelesmaById.id
    } else {
      return 'Δεν βρέθηκε αποτέλεσμα'
    }
  }

  const resultById = (id, tipNumber) => {
    const apotelesmaById = longTermResults.find(apotelesma => apotelesma.tipid === id);
    if (apotelesmaById) {
      if (tipNumber === 1) {
        return apotelesmaById.keimenoapotel ? apotelesmaById.keimenoapotel : apotelesmaById.keimenoapotel1 ? apotelesmaById.keimenoapotel1 : 'Δεν βρέθηκε αποτέλεσμα'
      }
      if (tipNumber === 2) {
        return apotelesmaById.keimenoapotel2 ? apotelesmaById.keimenoapotel2 : 'Δεν βρέθηκε αποτέλεσμα'
      }
      if (tipNumber === 3) {
        return apotelesmaById.keimenoapotel3 ? apotelesmaById.keimenoapotel3 : 'Δεν βρέθηκε αποτέλεσμα'
      }
    } else {
      return 'Δεν βρέθηκε αποτέλεσμα'
    }
  }

  const resulProfitById = (id) => {
    const apotelesmaById = longTermResults.find(apotelesma => apotelesma.tipid === id);
    if (apotelesmaById) { 
      if (!apotelesmaById.moneyresult) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">Δεν βρέθηκε αποτέλεσμα</p>;
      }
      let profitLoss = parseInt(apotelesmaById.moneyresult.replace(/[^0-9-]/g, ''), 10);
      if (!isNaN(profitLoss) && profitLoss >= 0) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-green-700">{apotelesmaById.moneyresult}</p>
      } else if (!isNaN(profitLoss) && profitLoss < 0) {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-red-800">{apotelesmaById.moneyresult}</p>
      } else if (apotelesmaById.moneyresult === "-") {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>
      } else {
        return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white bg-red-800">Cancelled</p>
      }
    } else {
      return <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">Δεν βρέθηκε αποτέλεσμα</p>
    }
  }   
  
  const totalPages = longTermPagination ? longTermPagination.totalPages : 1;

  
  const currentTips = tipslongTerm

  const handleNextPage = () => {
    isFirstLongRender.current = false
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    isFirstLongRender.current = false
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };
  
  return (
    <div>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή προγνωστικού" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτό το προγνωστικό; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
          <Button type="button" buttonClasses="w-full bg-betblue text-left overflow-x-auto sm:rounded-t-lg p-4 mt-6 text-xl font-medium text-white dark:text-white" onClick={() => setShowLongTermBets(!showLongTermBets)}>Long Term Bets({longTermPagination ? longTermPagination.totalLongTerm : 0})</Button>
          <CSSTransition
                  in={showLongTermBets}
                  mountOnEnter
                  unmountOnExit
                  timeout={200}
                  classNames="modal"
          >
          <div className="longTermContainer">
          {currentTips && currentTips.length > 0 ? (
          currentTips.map(tip => (
            (
              <div key={tip.id} className="bg-gray-100 mb-4 flex flex-wrap justify-between items-center p-4 text-center dark:bg-gray-700 dark:border-gray-700">
                {tip.tiptype === 'mono' ? (
                  <>
                    <div className="flex justify-between items-center p-1 w-full">
                      <div className="flex gap-x-3 items-center">
                        <img className="w-7 h-5" src={xwraImageById(tip.xwra1)} alt="flag" />
                        <p><span className="uppercase">{`${countryById(tip.xwra1)}`}</span> ➣ {tip.ghpedouxos1} - {tip.filoxenoumenos1}</p>
                        {tip.popuptext && <Button type="button" onClick={() => togglePlhrofories(tip.id)}>Ανάλυση</Button>}
                      </div>
                      <div className="flex gap-x-3">

                      {auth.role === "admin" ? (
                      <>
                         <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                        {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                           <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                          {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                        </>
                        ) :  ""}

                       
                        <p>								
                          <span>🕑 {new Date(tip.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                          <span>{tip.time1}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                      <div className="flex-1 w-full">
                        <p>Αγορά</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora1)}`}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Προγνωστικό</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick1}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Stake</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.telikoposo}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Αποτέλεσμα</p>
                        {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 1)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Odds</p>
                      {etairiesById(tip.etairia, parseFloat(tip.odds1))}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Profit/Loss</p>
                        {tip.open === false ? resulProfitById(tip.id) : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                      </div>
                    </div>
                    <CSSTransition
                      in={!!showPlhrofories[tip.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{tip.popuptext ? tip.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                  </>
                ) : tip.tiptype === 'diplo' ? (
                  <>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3 items-center">
                      <img className="w-7 h-5" src={xwraImageById(tip.xwra1)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(tip.xwra1)}`}</span> ➣ {tip.ghpedouxos1} - {tip.filoxenoumenos1}</p>
                      {tip.popuptext && <Button type="button" onClick={() => togglePlhrofories(tip.id)}>Ανάλυση</Button>}
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(tip.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{tip.time1}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora1)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick1}</p>
                    </div>
                    <div className="flex-1 w-full">
                      
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 1)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3">
                      <img className="w-7 h-5" src={xwraImageById(tip.xwra2)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(tip.xwra2)}`}</span> ➣ {tip.ghpedouxos2} - {tip.filoxenoumenos2}</p>
                    </div>
                    <div className="flex gap-x-3">

                    {auth.role === "admin" ? (
                      <>
                         <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                        {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                           <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                          {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                        </>
                        ) :  ""}

                
                      <p>								
                        <span>🕑 {new Date(tip.date2).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{tip.time2}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora2)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick2}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Stake</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.telikoposo}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 2)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                    </div>
                    <div className="flex-1 w-full">
                      <p>Odds</p>
                    {etairiesById(tip.etairia, parseFloat(tip.odds1) * parseFloat(tip.odds2))}
                    </div>
                    <div className="flex-1 w-full">
                      <p>Profit/Loss</p>
                      {tip.open === false ? resulProfitById(tip.id) : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                    </div>
                  </div>
                  <CSSTransition
                      in={!!showPlhrofories[tip.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{tip.popuptext ? tip.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                </>
                ) : tip.tiptype === 'triplo' ? (
                  <>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3 items-center">
                      <img className="w-7 h-5" src={xwraImageById(tip.xwra1)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(tip.xwra1)}`}</span> ➣ {tip.ghpedouxos1} - {tip.filoxenoumenos1}</p>
                      {tip.popuptext && <Button type="button" onClick={() => togglePlhrofories(tip.id)}>Ανάλυση</Button>}
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(tip.date1).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{tip.time1}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora1)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick1}</p>
                    </div>
                    <div className="flex-1 w-full">
                      
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 1)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                    <div className="flex gap-x-3">
                      <img className="w-7 h-5" src={xwraImageById(tip.xwra2)} alt="flag" />
                      <p><span className="uppercase">{`${countryById(tip.xwra2)}`}</span> ➣ {tip.ghpedouxos2} - {tip.filoxenoumenos2}</p>
                    </div>
                    <div className="flex gap-x-3">
                      <p>								
                        <span>🕑 {new Date(tip.date2).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                        <span>{tip.time2}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                    <div className="flex-1 w-full">
                      <p>Αγορά</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora2)}`}</p>
                    </div>
                    <div className="flex-1 w-full">
                      <p>Προγνωστικό</p>
                      <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick2}</p>
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                      <p>Αποτέλεσμα</p>
                      {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 2)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                    <div className="flex-1 w-full">
                    </div>
                  </div>
                  <div className="flex justify-between items-center p-1 w-full">
                      <div className="flex gap-x-3">
                        <img className="w-7 h-5" src={xwraImageById(tip.xwra3)} alt="flag" />
                        <p><span className="uppercase">{`${countryById(tip.xwra3)}`}</span> ➣ {tip.ghpedouxos3} - {tip.filoxenoumenos3}</p>
                      </div>
                      <div className="flex gap-x-3">

                      {auth.role === "admin" ? (
                      <>
                         <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                        {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                        <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                      </>
                      )  : auth.userId === userId ? (
                        <>
                           <Button to={`/users/${userId}/tips/${tip.id}`}>✍</Button>

                          {tip.open === false ? <Button style={{filter:'hue-rotate(400deg)'}} to={`/users/${userId}/epeksergasia-apotelesmatwn/${resultIdByTipid(tip.id)}`}>✍</Button> : ''}


                          <Button type="button" buttonClasses="bg-transparent" onClick={() => deleteTipHandler(tip.id)}>❌</Button>
                        </>
                        ) :  ""}
                        
                        <p>								
                          <span>🕑 {new Date(tip.date3).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' })}</span> - 
                          <span>{tip.time3}</span>
                        </p>
                      </div>
                    </div>
                    <div className="flex md:flex-row flex-col justify-between items-center p-1 w-full gap-5">
                      <div className="flex-1 w-full">
                        <p>Αγορά</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{`${agoraById(tip.agora3)}`}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Προγνωστικό</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.pick3}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Stake</p>
                        <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{tip.telikoposo}</p>
                      </div>
                      <div className="flex-1 w-full">
                        <p>Αποτέλεσμα</p>
                        {tip.open === false ? <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">{resultById(tip.id, 3)}</p> : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Odds</p>
                      {etairiesById(tip.etairia, parseFloat(tip.odds1) * parseFloat(tip.odds2) * parseFloat(tip.odds3))}
                      </div>
                      <div className="flex-1 w-full">
                        <p>Profit/Loss</p>
                        {tip.open === false ? resulProfitById(tip.id) : <p className="content bg-betblue rounded-3xl pt-2 pb-2 text-white">-</p>}
                      </div>
                    </div>
                    <CSSTransition
                      in={!!showPlhrofories[tip.id]}
                      mountOnEnter
                      unmountOnExit
                      timeout={200}
                      classNames="modal"
                    >
                      <div className="text-center border border border-gray-300 rounded-lg p-2.5 w-full mt-3">
                        <p>{tip.popuptext ? tip.popuptext : ''}</p>
                      </div>
                    </CSSTransition>
                </>
              ) : null}
              </div>
            )
          ))
        ) : (
          <p className="text-center mt-4">Δεν βρέθηκαν Long Term προγνωστικά σε αυτήν την σεζόν</p>
        )}
          { totalPages > 1 && 
            <div className="flex justify-center mt-4 items-center">
              <Button disabled={currentPage === 1} onClick={handlePreviousPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
              «
              </Button>
              <span className="mx-4">{`${currentPage} από ${totalPages}`}</span>
              <Button disabled={currentPage === totalPages} onClick={handleNextPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
                »
              </Button>
            </div>
            }
      </div>
      </CSSTransition>
    </div>
  
  )
}
