import { useState, useEffect, useContext } from "react";

import { VALIDATOR_REQUIRE } from "../shared/util/validators"; 
import { useTitle, Button, Input, Backdrop, ErrorModal, Modal, useForm, LoadingSpinner, AuthContext, useHttpClient, ImageUpload } from "../shared/"

export const Prwtathlimata = ({pageTitle}) => {
  useTitle(pageTitle);
  const auth = useContext(AuthContext)
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
  const [athlimataAll, setAthlimataAll] = useState([])
  const [xwresAll, setXwresAll] = useState([]);
  const [prwtathlimataAll, setPrwtathlimataAll] = useState([])
  const [athlimataXwres, setAthlimataXwres] = useState([])
  const [prwtForAthlXwr, setPrwtForAthlXwr] = useState([])
  const [showPrwtathlimataModal, setShowPrwtathlimataModal] = useState(false)
  const [showAthlXwraModal, setShowAthlXwraModal] = useState(false)
  const [newPrwtModal, setNewPrwtModal] = useState(false)
  const [updatedPrwtathlimaName, setUpdatedPrwtathlimaName] = useState(null)
  const [updatedPrwtathlimaId, setUpdatedPrwtathlimaId] = useState(null)
  const [updatedPrwtathlimaImage, setUpdatedPrwtathlimaImage] = useState(false);
  const [updatedAthlimaId, setUpdatedAthlimaId] = useState(null);
  const [updatedXwraId, setUpdatedXwraId] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [prwtathlimaEditFormState, prwtathlimaEditInputHandler, prwtathlimaEditSetFormData] = useForm({
    editprwtathlimaid: {
      value: '',
      isValid: false
    },
    editname: {
      value: '',
      isValid: false
    },
    editprwtimg: {
      value: '',
      isValid: true
    }
  },
  false);

  // eslint-disable-next-line
  const [athlXwraEditFormState, athlXwraEditInputHandler] = useForm({
    editathlimaid: {
      value: '',
      isValid: false
    },
    editxwraid: {
      value: '',
      isValid: false
    },
    editname: {
      value: '',
      isValid: false
    }
  },
  false); 

  const [prwtNewFormState, prwtNewInputHandler, setPrwtNewFormData ] = useForm({
    newprwt: {
      value: '',
      isValid: false
    },
    newprwtimg: {
      value: '',
      isValid: true
    }
  },
  false);

  useEffect(() => {
    if (updatedPrwtathlimaId) {
      prwtathlimaEditSetFormData({
        editprwtathlimaid: {
          value: updatedPrwtathlimaId,
          isValid: true
        },
        editname: {
          value: updatedPrwtathlimaName,
          isValid: true
        }
      },
      true

      )
    }
  }, [updatedPrwtathlimaId, prwtathlimaEditSetFormData, updatedPrwtathlimaName])

  useEffect(() => {
    const fetchPrwtathlimataAndRel = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prwtathlimata`
        )
        
        setPrwtathlimataAll(responseData.prwtathlimata)
        setXwresAll(responseData.xwres)
        setAthlimataAll(responseData.athlimata)
        setAthlimataXwres(responseData.athlimataXwres)
      } catch (err) {
        
      }
      
    }

    fetchPrwtathlimataAndRel()
  }, [sendRequest, auth.userId])

  const prwtathlimataClickHandler = (id) => {

    const prwtathlima = prwtathlimataAll.find( prwt => prwt.id === id);

    if (prwtathlima) {
      setUpdatedPrwtathlimaName(prwtathlima.name);
      setUpdatedPrwtathlimaId(prwtathlima.id);
      setUpdatedPrwtathlimaImage(prwtathlima.image)
    }

    setShowPrwtathlimataModal(true);
  }

  const cancelModal = () => {
    if (showPrwtathlimataModal) {
      setShowPrwtathlimataModal(false);
      setUpdatedPrwtathlimaId(null);
      setUpdatedPrwtathlimaName(null);
      setPrwtForAthlXwr([])
    }

    if (showAthlXwraModal) {
      setShowAthlXwraModal(false);
      setUpdatedAthlimaId(null);
      setUpdatedXwraId(null);
    }
    
    // if (error) {
    //   setError(false);
    // }
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const updatePrwtathlimataHandler = async (e) => {
    e.preventDefault()
    const imageSrc = e.target.querySelector(".image-upload__preview img")?.src || null;
    console.log(imageSrc)
      try {
        
        const formData = new FormData()
        
        formData.append('id', updatedPrwtathlimaId)
        formData.append('name', prwtathlimaEditFormState.inputs.editname.value)
        if (prwtathlimaEditFormState.inputs.editprwtimg) {
          formData.append('image', prwtathlimaEditFormState.inputs.editprwtimg.value)
        }
        formData.append('previousImage', imageSrc)

        
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prwtathlimata/name`,
          'PATCH',
          formData
        )

        console.log(responseData)

      
        const updatedPrwtathlima = {...prwtathlimataAll.find(prwtathl => prwtathl.id === updatedPrwtathlimaId)};
        const prwtathlimaIndex = prwtathlimataAll.findIndex(prwtathl => prwtathl.id === updatedPrwtathlimaId);
        updatedPrwtathlima.name = responseData.prwtathlima.name;
        updatedPrwtathlima.image = responseData.prwtathlima.image;
        prwtathlimataAll[prwtathlimaIndex] = updatedPrwtathlima;
        setPrwtathlimataAll(prwtathlimataAll);
        setShowPrwtathlimataModal(false);
      } catch (err) {
        
      }
      
  }

  const confirmDeleteHandler = async () => {

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prwtathlimata`,
        'DELETE',
        JSON.stringify({
          id: updatedPrwtathlimaId
        }),
        {
          'Content-Type': 'application/json'
        }
      )
      
      setPrwtathlimataAll(prevPrwt => prevPrwt.filter( prwt => prwt.id !== responseData.id));
      setShowPrwtathlimataModal(false);
      setConfirmDeleteModal(false);
      setUpdatedPrwtathlimaId(null);
      setUpdatedPrwtathlimaName(null); 
    } catch (err) {
      
    }

  }

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
    setPrwtForAthlXwr([])
    setUpdatedPrwtathlimaId(null);
    setUpdatedPrwtathlimaName(null);
  }

  const athlimaXwraClickHandler = async (athlid, xwraid ) => {

    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/tasosnaz/prwtathlimata/${athlid}/${xwraid}`)

      setUpdatedAthlimaId(athlid);
      setUpdatedXwraId(xwraid);
  
      setPrwtForAthlXwr(responseData.prwtathlimataForAthlimaXwra) 
      
      setShowAthlXwraModal(true);
    } catch (err) {
      
    }
    
  }

  const editAthlXwraHandler = async (e) => {
    e.preventDefault();

    const prwtathlimataForAthlXwra = Array.from(document.querySelectorAll('input[name="championships"]'))
    .filter(input => input.value)
    .map(input => parseInt(input.id, 10));

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prwtathlimata`,
        'PATCH',
        JSON.stringify({
          athlimaId: updatedAthlimaId,
          xwraId: updatedXwraId,
          prwtathlimata: prwtathlimataForAthlXwra
        }),
        {
          'Content-Type': 'application/json'
        }
      )
      
      setUpdatedAthlimaId(null);
      setUpdatedXwraId(null);
  
      setPrwtForAthlXwr([]) 
      
      setShowAthlXwraModal(false);
    } catch (err) {
      
    }
  }

  const submitPrwtHandler = async (e) => {
    e.preventDefault();
      try {
        const formData = new FormData()
        formData.append('name', prwtNewFormState.inputs.newprwt.value)
        formData.append('image', prwtNewFormState.inputs.newprwtimg.value)
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/prwtathlimata`,
          'POST',
          formData
        )
  
        setPrwtathlimataAll(prevPrwt => [...prevPrwt, responseData.prwtathlima]);
        setPrwtNewFormData({
          newprwt: {
            value: '',
            isValid: false
          },
          newprwtimg: {
            value: '',
            isValid: true
          }
        }, false);
        setIsFormSubmitted(true);
        setNewPrwtModal(true);
      } catch (err) {
        
      }
      
      


  }


  const cancelNewPrwtModal = () => {
    setNewPrwtModal(false);
    setIsFormSubmitted(false);
  }



  

  return (
    <>
    {(showPrwtathlimataModal || showAthlXwraModal) && <Backdrop onClick={cancelModal}/>}
        <ErrorModal error={error} onClear={clearError}/>
        {isLoading && <LoadingSpinner asOverlay/>}
        <Modal childClass="w-full" show={showPrwtathlimataModal} onsubmit={updatePrwtathlimataHandler} onCancel={cancelModal} header="Επεξεργασία/διαγραφή πρωταθλήματος" footerClass="flex w-full justify-between" footer={
            <>
              <Button inverse type="submit" disabled={!prwtathlimaEditFormState.isValid}>
                Ενημέρωση
              </Button>
              <Button danger type="button" onClick={deleteModalHandler}>
                Διαγραφή
              </Button>
            </>}>
              <Input label="ID πρωταθλήματος" initialValue={updatedPrwtathlimaId} initialValid={true} id="editprwtathlimaid" name="editprwtathlimaid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={prwtathlimaEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
              <Input label="Μετονομασία πρωταθλήματος" initialValue={updatedPrwtathlimaName} initialValid={true} id="editname" name="editname" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={prwtathlimaEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required />
              <ImageUpload onInput={prwtathlimaEditInputHandler} initialValue={updatedPrwtathlimaImage !== null ? `${process.env.REACT_APP_BACKEND_URL}/${updatedPrwtathlimaImage}` : null} initialValid={true} id="editprwtimg" name="editprwtimg" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
        </Modal>
        <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή πρωταθλήματος" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτό το πρωτάθλημα; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
      <Modal childClass="w-full" show={showAthlXwraModal} onsubmit={editAthlXwraHandler} onCancel={cancelModal} header="Προσθήκη πρωταθλημάτων" footerClass="flex w-full justify-between" footer={
              <Button type="submit">
                Ενημέρωση
              </Button>
            }>
              {athlimataAll && athlimataAll.length > 0 && prwtathlimataAll && prwtathlimataAll.length > 0 && (
                <>
                    <p>Αντιστοίχηση πρωταθλημάτων στον συνδυασμό αθλήματος/χώρας</p>
                    <Input label="ID αθλήματος" initialValue={updatedAthlimaId} initialValid={true} id="editathlimaid" name="editathlimaid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={athlXwraEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
                    <Input label="ID χώρας" initialValue={updatedXwraId} initialValid={true} id="editxwraid" name="editxwraid" type="hidden" validators={[VALIDATOR_REQUIRE()]} onInput={athlXwraEditInputHandler} errorText="Το id είναι υποχρεωτικό" required disabled={true}/>
                    {prwtathlimataAll.map(prwtathlima => {
                        let exeiPrwtathlima = false;
                        const prwtathlimaseAthlima = prwtForAthlXwr.find(prwt => prwt.prwtathlima_id === prwtathlima.id)
                        if (prwtathlimaseAthlima) exeiPrwtathlima = true
                       return (<Input 
                        id = {prwtathlima.id}
                        name = "championships"
                        key={prwtathlima.id}
                        element = 'input'
                        type = 'checkbox'
                        label = {prwtathlima.name}
                        validators = {[]}
                        onInput = {athlXwraEditInputHandler}
                        value={prwtathlima.id}
                        initialValid={true}
                        initialName = {prwtathlima.name}
                        initialChecked = {exeiPrwtathlima}
                    />)
})}
                </>
                )}
        </Modal>
        <Modal
          childClass="w-full"
          show={newPrwtModal} 
          onCancel={cancelNewPrwtModal} 
          header="Προσθήκη πρωταθλήματος" 
          footerClass="flex w-full justify-between" 
          footer={
              <Button onClick={cancelNewPrwtModal}>
                Ok
              </Button>
            }
        >
           <p>Το πρωτάθλημα προστέθηκε με επιτυχία! </p>
        </Modal>
        <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Πρωταθλήματα</h1>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στο πρωτάθλημα που θέλετε να επεξεργαστείτε/διαγράψετε</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
          {prwtathlimataAll && prwtathlimataAll.map((prwtathlima) => {
            const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
            return (
              <Button
                key={prwtathlima.id}// Add a unique key for each element in the list
                buttonClasses={classes}
                onClick={() => prwtathlimataClickHandler(prwtathlima.id)}
              >
                {prwtathlima.name}
              </Button>
            );
          })}
        </div>
        <h2 className="mb-1 mt-7 text-2xl font-medium text-gray-900 dark:text-white">Πρωταθλήματα για κάθε συνδυασμό αθλήματος/χώρας</h2>
        <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στον συνδυασμό αθλήματος/χώρας για προσθήκη/αφαίρεση πρωταθλημάτων.</p>
        <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
        {athlimataAll && athlimataAll.length > 0 && 
            athlimataAll.map((athlima) => {

              const relatedXwres = athlimataXwres
                .filter((rel) => rel.athlima_id === athlima.id)
                .map((rel) => rel.xwra_id);

              return relatedXwres.length > 0 ? (
                relatedXwres.map((xwraId) => {
                  const xwra = xwresAll.find((x) => x.id === xwraId);
                  if (xwra) {
                    return (
                      <Button
                        key={`${athlima.id}-${xwra.id}`}
                        buttonClasses="block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white"
                        onClick={() => athlimaXwraClickHandler(athlima.id, xwra.id)}
                      >
                        {athlima.name} - {xwra.name}
                      </Button>
                    );
                  }
                  return null;
                })
              ) : null;
            })}
      </div>
      <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη Πρωταθλήματος</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitPrwtHandler}>
        <div className="mb-5">
        <Input label="Νέο πρωτάθλημα" id="newprwt" name="newprwt" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={prwtNewInputHandler} value={prwtNewFormState.inputs.newprwt.value} initialValid={prwtNewFormState.inputs.newprwt.isValid} formSubmitted={isFormSubmitted} errorText="Το πεδίο είναι υποχρεωτικό" required/>
        <ImageUpload onInput={prwtNewInputHandler} id="newprwtimg" name="newprwtimg" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
        </div>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!prwtNewFormState.isValid}>Προσθήκη</Button>
        </form>
    </>
  )
}
