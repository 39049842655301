import { useEffect, useState } from "react";
import { Button, LoadingSpinner, useHttpClient, ErrorModal } from "../../../shared";


// Function to calculate statistics based on the results for a specific combination of country and league
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
    const moneyResult = Number(tip.moneyresult);
    return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
    const moneyResult = Number(tip.moneyresult);
    return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
    const stake = Number(tip.telikoposo);
    return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
    const odds = Number(tip.preresultodds);
    return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
    totalBets,
    winRate: Math.round(winRate),
    totalProfit: Math.round(totalProfit),
    yieldValue: yieldValue.toFixed(2),
    avgOdds: avgOdds.toFixed(2),
  };
}

export const XwrwnPrwtathlimatwn = ({ resultedTips, userid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [xwresAll, setXwresAll] = useState([])
  const [prwtathlimataAll, setPrwtathlimataAll] = useState([])
  // Create lookup objects for country and league names
  const countryNameLookup = xwresAll.reduce((acc, xwra) => {
    acc[xwra.id] = xwra.name;
    return acc;
  }, {});

  const leagueNameLookup = prwtathlimataAll.reduce((acc, prwtathlima) => {
    acc[prwtathlima.id] = prwtathlima.name;
    return acc;
  }, {});

  useEffect(() => {
    const fetchPrwtathlimataAndXwres = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prwtathlimataandxwres`
        )

        setXwresAll(responseData.xwres)
        setPrwtathlimataAll(responseData.prwtathlimata)
      } catch (err) {
        
      }
    }

    fetchPrwtathlimataAndXwres()
  }, [sendRequest, userid])

  // Group results by country and league combinations
  const resultsByCountryLeague = {};

  resultedTips.forEach((tip) => {
    const combinations = [
      { countryId: tip.xwra1, leagueId: tip.prwtathlima1 },
      { countryId: tip.xwra2, leagueId: tip.prwtathlima2 },
      { countryId: tip.xwra3, leagueId: tip.prwtathlima3 },
    ];

    combinations.forEach(({ countryId, leagueId }) => {
      if (countryId && leagueId) {
        const countryName = countryNameLookup[countryId];
        const leagueName = leagueNameLookup[leagueId];
        if (countryName && leagueName) {
          const key = `${countryName} - ${leagueName}`;
          if (!resultsByCountryLeague[key]) {
            resultsByCountryLeague[key] = [];
          }
          resultsByCountryLeague[key].push(tip); // Group tips by country and league
        }
      }
    });
  });

  // Sort combinations alphabetically for consistent display
  const sortedCombinations = Object.keys(resultsByCountryLeague).sort();

  // Pagination setup
  const [currentPage, setCurrentPage] = useState(1);
  const combinationsPerPage = 20;
  const totalPages = Math.ceil(sortedCombinations.length / combinationsPerPage);

  // Sorting state
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState('desc');

  // Handle sorting
  const handleSort = (field) => {
    const newOrder = sortField === field && sortOrder === 'desc' ? 'asc' : 'desc';
    setSortField(field);
    setSortOrder(newOrder);
  };

  // Apply sorting to combinations
  const sortedCombinationsStats = sortedCombinations.map((combination) => {
    const stats = calculateEidos(resultsByCountryLeague[combination]);
    return { combination, ...stats };
  });

  if (sortField) {
    sortedCombinationsStats.sort((a, b) => {
      if (sortOrder === 'desc') {
        return b[sortField] - a[sortField];
      } else {
        return a[sortField] - b[sortField];
      }
    });
  }

  // Get current page data
  const currentCombinations = sortedCombinationsStats.slice(
    (currentPage - 1) * combinationsPerPage,
    currentPage * combinationsPerPage
  );

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
            <tr className="stattr">
              <th scope="col" className="px-6 py-3">Country</th>
              <th scope="col" className="px-6 py-3">Leagues</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('totalBets')}>Picks ⇣</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('winRate')}>Win Rate ⇣</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('totalProfit')}>Profit ⇣</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('yieldValue')}>Yield ⇣</th>
              <th scope="col" className="px-6 py-3 cursor-pointer" onClick={() => handleSort('avgOdds')}>Average Odds ⇣</th>
            </tr>
          </thead>
          <tbody>
            {currentCombinations.map(({ combination, totalBets, winRate, totalProfit, yieldValue, avgOdds }) => {
              const [country, league] = combination.split(' - ');
              return (
                <tr key={combination} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {country}
                  </th>
                  <td className="px-6 py-4">{league}</td>
                  <td className="px-6 py-4">{totalBets}</td>
                  <td className="px-6 py-4">{winRate}%</td>
                  <td className="px-6 py-4">{totalProfit}</td>
                  <td className="px-6 py-4">{yieldValue}%</td>
                  <td className="px-6 py-4">{avgOdds}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        { totalPages > 1 && 
              <div className="flex mt-4 items-center">
                <Button disabled={currentPage === 1} onClick={handlePreviousPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
                «
                </Button>
                <span className="mx-4">{`${currentPage} από ${totalPages}`}</span>
                <Button disabled={currentPage === totalPages} onClick={handleNextPage} buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800">
                  »
                </Button>
              </div>
              }
        
      </div>
    </>
  );
};
