import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import {Input, Button, useForm, ErrorModal, AuthContext, Modal, useTitle, useHttpClient, LoadingSpinner} from '../shared/'
import { VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH, VALIDATOR_PASSWORD } from '../shared/util/validators'

export const UserProfile = ({ pageTitle }) => {
    useTitle(pageTitle);
    const [showUsenameModal, setShowUsernameModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const params = useParams();
    const auth = useContext(AuthContext);
    const userId = params.userid;
    const navigate = useNavigate();
    const { isLoading, error, sendRequest, clearError } = useHttpClient()

    
    const [usernameFormState, usernameInputHandler, setUsernameFormData] = useForm({
        username: {
            value: '',
            isValid: false
        }
    }, false)

    const [passwordFormState, passwordInputHandler, setPasswordFormData] = useForm(
        {
          newpassword: {
            value: '',
            isValid: false
          },
          repeatPassword: {
            value: '',
            isValid: false
          }
        },
        false
      );


    useEffect(() => {
        const fetchUser = async () => {
            try {
                const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userId}`)
                setUsernameFormData({
                    username: {
                        value: responseData.user.username,
                        isValid: true
                    }
                })
                setPasswordFormData({
                    newpassword: {
                        value: '',
                        isValid: false
                    },
                    repeatpassword: {
                        value: '',
                        isValid: false
                    }
                })
            } catch (err) {
                
            }
            

        }

        fetchUser()
    }, [sendRequest, userId, setUsernameFormData, setPasswordFormData])
     
    

    const submitUsernameHandler = async (e) => {
        e.preventDefault();

        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/username`,
                'PATCH',
                JSON.stringify({
                    username: usernameFormState.inputs.username.value
                }),
                {'Content-Type': 'application/json'}
            )
            setShowUsernameModal(true);
        } catch (err) {
            
        }
    }

    const submitPasswordHandler = async e => {
        e.preventDefault();

        try {
            await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/user/${userId}/password`,
                'PATCH',
                JSON.stringify({
                    newPassword: passwordFormState.inputs.newpassword.value,
                    passwordVerification: passwordFormState.inputs.repeatpassword.value
                }),
                {
                    'Content-Type': 'application/json'
                }
            )

            setShowPasswordModal(true);
        } catch (err) {
            
        }
    }

    const cancelModal = () => {
        setShowUsernameModal(false)
        setShowPasswordModal(false)
        auth.logout();
        navigate('/');
    }

    if (isLoading) {
        return <LoadingSpinner asOverlay />
    }

  return (
    <>
        <ErrorModal error={error} onClear ={clearError} />
        <Modal show={showUsenameModal} onCancel={cancelModal} header="Αλλαγή username" footer={<Button type="submit" onClick={cancelModal}>Οκ</Button>}>
            <p>To username σας άλλαξε με επιτυχία θα μεταβείτε στην σελίδα σύνδεσης για να ξανακάνετε login με το καινούριο σας username</p>
        </Modal>
        <Modal show={showPasswordModal} onCancel={cancelModal} header="Αλλαγή password" footer={<Button type="submit" onClick={cancelModal}>Οκ</Button>}>
            <p>To password σας άλλαξε με επιτυχία θα μεταβείτε στην σελίδα σύνδεσης για να ξανακάνετε login με το καινούριο σας password</p>
        </Modal>
        <h1 className="text-xl font-medium text-gray-900 dark:text-white mb-5">Αλλαγή user Name</h1>
        <form className="w-full mx-auto mb-9" onSubmit={submitUsernameHandler}>
        <div className="mb-5">
        <Input label="Email/Username" initialValue={usernameFormState.inputs.username.value} initialValid={usernameFormState.inputs.username.isValid} id="username" name="username" type="text" validators={[VALIDATOR_REQUIRE(), VALIDATOR_MINLENGTH(6)]} onInput={usernameInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει το λιγότερο 6 χαρακτήρες" required/>
        </div>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!usernameFormState.isValid}>Αλλαγή</Button>
        </form>
        <h2 className="text-xl font-medium text-gray-900 dark:text-white mb-5">Αλλαγή Password</h2>
        <form className="w-full mx-auto mb-9" onSubmit={submitPasswordHandler}>
        <div className="mb-5">
        <Input label="Νέο Password" id="newpassword" name="newpassword" type="password" validators={[VALIDATOR_REQUIRE(), VALIDATOR_PASSWORD()]} onInput={passwordInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει 10 με 16 χαρακτήρες, κεφαλαία και μικρά γράμματα, αριθμούς, ειδικούς χαρακτήρες, χωρίς κενά" required/>
        <Input label="Επαλήθευση Νέου Password" id="repeatpassword" name="repeatpassword" type="password" validators={[VALIDATOR_REQUIRE(), VALIDATOR_PASSWORD()]} onInput={passwordInputHandler} errorText="Το πεδίο είναι υποχρεωτικό και πρέπει να περιλαμβάνει 10 με 16 χαρακτήρες, κεφαλαία και μικρά γράμματα, αριθμούς, ειδικούς χαρακτήρες, χωρίς κενά" required/>
        </div>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!passwordFormState.isValid}>Αλλαγή</Button>
        </form>
    </>
  )
}

