import { useEffect, useState } from "react";

import { LoadingSpinner, useHttpClient, ErrorModal  } from "../../../shared";


// Function to calculate statistics based on the results for a specific bookmaker
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
    const moneyResult = Number(tip.moneyresult);
    return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
    const moneyResult = Number(tip.moneyresult);
    return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
    const stake = Number(tip.telikoposo);
    return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average stake
  const avgStake = totalBets !== 0 ? totalStake / totalBets : 0;

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
    const odds = Number(tip.preresultodds);
    return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
    totalBets,
    winRate: Math.round(winRate),
    totalProfit: Math.round(totalProfit),
    yield: yieldValue.toFixed(2),
    avgStake: avgStake.toFixed(2),
    avgOdds: avgOdds.toFixed(2),
  };
}

export const Bookmakers = ({ resultedTips, userid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [etairiesAll, setEtairiesAll] = useState([])
  // Create a lookup object for quick access to bookmaker names by ID
  const bookmakerNameLookup = etairiesAll.reduce((acc, etairia) => {
    acc[etairia.id] = etairia.name;
    return acc;
  }, {});

  useEffect(() => {
    const fetchEtairies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/etairies`
        )

        setEtairiesAll(responseData.etairies)
      } catch (err) {
        
      }
    }

    fetchEtairies()
  }, [sendRequest, userid])

  // Group results by bookmaker name using the 'etairia' field
  const resultsByBookmaker = {};

  resultedTips.forEach((tip) => {
    const bookmakerId = tip.etairia;
    const bookmakerName = bookmakerNameLookup[bookmakerId];
    if (bookmakerName) {
      if (!resultsByBookmaker[bookmakerName]) {
        resultsByBookmaker[bookmakerName] = [];
      }
      resultsByBookmaker[bookmakerName].push(tip); // Group tips by bookmaker
    }
  });

  // Sort bookmakers alphabetically for consistent display
  const sortedBookmakers = Object.keys(resultsByBookmaker).sort();

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
    <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
          <tr className="stattr">
            <th scope="col" className="px-6 py-3">Bookmaker</th>
            <th scope="col" className="px-6 py-3">Picks</th>
            <th scope="col" className="px-6 py-3">Win Rate</th>
            <th scope="col" className="px-6 py-3">Profit</th>
            <th scope="col" className="px-6 py-3">Yield</th>
            <th scope="col" className="px-6 py-3">Avg Stake</th>
            <th scope="col" className="px-6 py-3">Avg Odds</th>
          </tr>
        </thead>
        <tbody>
          {sortedBookmakers.map((bookmakerName) => {
            const bookmakerStats = calculateEidos(resultsByBookmaker[bookmakerName]);
            return (
              <tr key={bookmakerName} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {bookmakerName}
                </th>
                <td className="px-6 py-4">{bookmakerStats.totalBets}</td>
                <td className="px-6 py-4">{bookmakerStats.winRate}%</td>
                <td className="px-6 py-4">{bookmakerStats.totalProfit}</td>
                <td className="px-6 py-4">{bookmakerStats.yield}%</td>
                <td className="px-6 py-4">{bookmakerStats.avgStake}</td>
                <td className="px-6 py-4">{bookmakerStats.avgOdds}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </>
  );
};
