import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTitle, Button, Modal, ErrorModal, LoadingSpinner, useHttpClient, Card } from "../shared";

const Apotelesmata = ({ pageTitle }) => {
  useTitle(pageTitle);

  const resultOptions = [
    { id: 'w', name: 'W (Won)' },
    { id: 'v', name: 'V (Void)' },
    { id: 'l', name: 'L (Lost)' },
    { id: 'w/2', name: 'W/2 (Half won)' },
    { id: 'l/2', name: 'L/2 (Half lost)' },
    { id: 'x', name: 'X (Canceled)' }
  ];

  const { userid } = useParams();
  const [keimenoapotelvalue, setKeimenoapotelvalue] = useState({});
  const [keimenoapotelvalue11, setKeimenoapotelvalue11] = useState({});
  const [keimenoapotelvalue12, setKeimenoapotelvalue12] = useState({});
  const [keimenoapotelvalue21, setKeimenoapotelvalue21] = useState({});
  const [keimenoapotelvalue22, setKeimenoapotelvalue22] = useState({});
  const [keimenoapotelvalue23, setKeimenoapotelvalue23] = useState({});

  const [selected, setSelected] = useState({});
  const [selected1, setSelected1] = useState({});
  const [selected2, setSelected2] = useState({});
  const [selected3, setSelected3] = useState({});

  const [openTips, setOpenTips] = useState([]);
  const [showResultSubmitModal, setShowResultSubmitModal] = useState(false);
  const {isLoading, error, sendRequest, clearError} = useHttpClient()
  const [currentUser, setCurrentUser] = useState(null)
  const [prwtathlimataNames, setPrwtathlimataNames] = useState([])

  useEffect(() => {
    const fetchOpenTips = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/apotelesmata`,
        )

        setOpenTips(responseData.apotelesmata);
      } catch (err) {
        
      }
    }

    fetchOpenTips()
    
  }, [sendRequest, userid]);
  

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userid}`)
        setCurrentUser(responseData.user)
      } catch (err) {
        
      }
      
    }

    fetchUser()
  }, [sendRequest, userid])

  useEffect(() => {
    const fetchPrwtathlimata = async () => {
      
      
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prwtathlimatanames`
        )
        
        setPrwtathlimataNames(responseData.prwtathlimata)
      } catch (err) {
        
      }
      


    }

    fetchPrwtathlimata()
  }, [sendRequest, userid])

  const changeHandler = (e, tipId, type, field) => {
    if (type === 'single') {
      setKeimenoapotelvalue(prevState => ({ ...prevState, [tipId]: e.target.value }));
    } 

    if (type === 'double') {
      if (field === 'first') setKeimenoapotelvalue11(prevState => ({ ...prevState, [tipId]: e.target.value }));
      if (field === 'second') setKeimenoapotelvalue12(prevState => ({ ...prevState, [tipId]: e.target.value }));
    }

    if (type === 'triple') {
      if (field === 'first') setKeimenoapotelvalue21(prevState => ({ ...prevState, [tipId]: e.target.value }));
      if (field === 'second') setKeimenoapotelvalue22(prevState => ({ ...prevState, [tipId]: e.target.value }));
      if (field === 'third') setKeimenoapotelvalue23(prevState => ({ ...prevState, [tipId]: e.target.value }));
    }
    
  };

  const selectChangeHandler = (event, tipId, field) => {
    if (field === 'first') {
      setSelected1(prevState => ({ ...prevState, [tipId]: event.target.value }));
    } else if (field === 'second') {
      setSelected2(prevState => ({ ...prevState, [tipId]: event.target.value }));
    } else if (field === 'third') {
      setSelected3(prevState => ({ ...prevState, [tipId]: event.target.value }));
    } else {
      setSelected(prevState => ({ ...prevState, [tipId]: event.target.value }));
    }
  };

  const cancelResultSubmitModal = () => {
    setShowResultSubmitModal(false);
  }

  const submitApotelesmaHandler = async (event, openTip, type) => {
    event.preventDefault();      
  
      if (type === 'single') {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/apotelesmata`,
            'POST',
            JSON.stringify({
              openTip,
              type,
              keimenoapotel1: event.target[`keimenoapotel${openTip.id}`].value,
              resultselect1: event.target[`resultselect${openTip.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )

          setOpenTips(responseData.openTips);
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }
        

      }
      
      if (type === 'diplo') {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/apotelesmata`,
            'POST',
            JSON.stringify({
              openTip,
              type,
              keimenoapotel1: keimenoapotelvalue11[openTip.id],
              resultselect1: selected1[openTip.id],
              keimenoapotel2: event.target[`keimenoapotel2${openTip.id}`].value,
              resultselect2: event.target[`resultselect2${openTip.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )

          setOpenTips(responseData.openTips);
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }
 
        
      }
  
      if (type === 'triplo') {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/apotelesmata`,
            'POST',
            JSON.stringify({
              openTip,
              type,
              keimenoapotel1: keimenoapotelvalue21[openTip.id],
              resultselect1: selected1[openTip.id],
              keimenoapotel2: keimenoapotelvalue22[openTip.id],
              resultselect2: selected2[openTip.id],
              keimenoapotel3: event.target[`keimenoapotel3${openTip.id}`].value,
              resultselect3: event.target[`resultselect3${openTip.id}`].value
            }),
            {
              'Content-Type': 'application/json'
            }
          )

          setOpenTips(responseData.openTips);
          setShowResultSubmitModal(true);
        } catch (err) {
          
        }
        
      }  
  };
  
  
  

  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <Modal
          childClass="w-full"
          show={showResultSubmitModal} 
          onCancel={cancelResultSubmitModal} 
          header="Προσθήκη αποτελέσματος" 
          footerClass="flex w-full justify-between" 
          footer={
              <Button onClick={cancelResultSubmitModal}>
                Ok
              </Button>
            }
        >
           <p>Το αποτέλεσμα προστέθηκε με επιτυχία! Για την επεξεργασία του μεταβείτε στην σελίδα της επεξεργασίας αποτελεσμάτων</p>
        </Modal>
      { currentUser && <Button to={`/users/${currentUser.id}/prognwstika`}>Προβολή προγνωστικών</Button> }
      { currentUser ? 
      ( openTips.length > 0 ? ( 
        <>
          <h1 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">
            <span className="uppercase">{currentUser.username}</span> - Προσθήκη Αποτελεσμάτων
          </h1>
          <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className=" border-white text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">ΠΡΩΤΑΘΛΗΜΑ</th>
                  <th scope="col" className="px-6 py-3">Η/Μ</th>
                  <th scope="col" className="px-6 py-3">ΩΡΑ</th>
                  <th scope="col" className="px-6 py-3">ΑΓΩΝΑΣ</th>
                  <th scope="col" className="px-6 py-3">TIPS</th>
                  <th scope="col" className="px-6 py-3">ΑΠΟΔΟΣΗ</th>
                  <th scope="col" className="px-6 py-3">STAKE</th>
                  <th scope="col" className="px-6 py-3 text-center">ΑΠΟΤΕΛΕΣΜΑ</th>
                </tr>
              </thead> 
              <tbody> 
                {openTips.map((openTip, index) => {
                  // const rowStyle = getRowStyle(rowIndex);
                  if (openTip.tiptype === 'mono') {
                    // rowIndex++;
                    return (<tr className= "border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={openTip.id || index}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                      </th>
                      <td className="px-6 py-4">{new Date(openTip.date1).toLocaleDateString('el-GR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}</td>
                      <td className="px-6 py-4">{openTip.time1 ? openTip.time1.slice(0, 5) : 'N/A'}</td>
                      <td className="px-6 py-4">{openTip.ghpedouxos1} - {openTip.filoxenoumenos1}</td>
                      <td className="px-6 py-4">{openTip.pick1}</td>
                      <td className="px-6 py-4">{openTip.odds1}</td>
                      <td className="px-6 py-4">{openTip.telikoposo}</td>
                      <td className="px-6 py-4">
                        <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, openTip, 'single')}>
                          <input 
                            id={`keimenoapotel${openTip.id}`}
                            name={`keimenoapotel${openTip.id}`}
                            key={`keimenokey${openTip.id}`} 
                            type='text'
                            onChange={(e) => changeHandler(e, openTip.id, 'single')}
                            value={keimenoapotelvalue[openTip.id] || ''}
                            className='w-20 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                            
                            maxLength='7'
                          />
                          <select 
                            id={`resultselect${openTip.id}`}
                            name={`resultselect${openTip.id}`}
                            key={`resultkey${openTip.id}`}
                            onChange={(event) => selectChangeHandler(event, openTip.id)} 
                            value={selected[openTip.id] || ''}
                            className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                          >
                            <option value="">Αποτέλεσμα</option>
                            {resultOptions.map(option => (
                              <option key={option.id} value={option.id}>{option.name}</option>
                            ))}
                          </select>
                          <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Προσθήκη</Button>
                        </form>
                      </td>
                    </tr>
                )} else if (openTip.tiptype === 'diplo') {
                  // rowIndex++;
                  return (
                    <>
                        <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`prwto${openTip.id || index}`}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                        </th>
                        <td className="px-6 py-4">{new Date(openTip.date1).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{openTip.time1 ? openTip.time1.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{openTip.ghpedouxos1} - {openTip.filoxenoumenos1}</td>
                        <td className="px-6 py-4">{openTip.pick1}</td>
                        <td className="px-6 py-4">{openTip.odds1}</td>
                        <td className="px-6 py-4"></td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                            <input 
                              id={`keimenoapotel1${openTip.id}`}
                              name={`keimenoapotel1${openTip.id}`}
                              key={`keimenokey1${openTip.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, openTip.id, 'double', 'first')}
                              value={keimenoapotelvalue11[openTip.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect1${openTip.id}`}
                              name={`resultselect1${openTip.id}`}
                              key={`resultkey1${openTip.id}`}
                              onChange={(event) => selectChangeHandler(event, openTip.id, 'first')} 
                              value={selected1[openTip.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </form>
                        </td>
                      </tr>
                      <tr className="border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`deutero${openTip.id || index}`}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima2)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                      </th>
                      <td className="px-6 py-4">{new Date(openTip.date2).toLocaleDateString('el-GR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}</td>
                      <td className="px-6 py-4">{openTip.time2 ? openTip.time2.slice(0, 5) : 'N/A'}</td>
                      <td className="px-6 py-4">{openTip.ghpedouxos2} - {openTip.filoxenoumenos2}</td>
                      <td className="px-6 py-4">{openTip.pick2}</td>
                      <td className="px-6 py-4">{openTip.odds2}</td>
                      <td className="px-6 py-4">{openTip.telikoposo}</td>
                      <td className="px-6 py-4">
                        <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, openTip, 'diplo')}>
                          <input 
                            id={`keimenoapotel2${openTip.id}`}
                            name={`keimenoapotel2${openTip.id}`}
                            key={`keimenokey2${openTip.id}`} 
                            type='text'
                            onChange={(e) => changeHandler(e, openTip.id, 'double', 'second')}
                            value={keimenoapotelvalue12[openTip.id] || ''}
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                            
                            maxLength='7'
                          />
                           <select 
                              id={`resultselect2${openTip.id}`}
                              name={`resultselect2${openTip.id}`}
                              key={`resultkey2${openTip.id}`}
                              onChange={(event) => selectChangeHandler(event, openTip.id, 'second')} 
                              value={selected2[openTip.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Προσθήκη</Button>
                        </form>
                      </td>
                    </tr>
                  </>
                  )
                } else if (openTip.tiptype === 'triplo') {
                  // rowIndex++;
                  return (
                    <>
                        <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`prwto${openTip.id || index}`}>
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                          {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima1)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                        </th>
                        <td className="px-6 py-4">{new Date(openTip.date1).toLocaleDateString('el-GR', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        })}</td>
                        <td className="px-6 py-4">{openTip.time1 ? openTip.time1.slice(0, 5) : 'N/A'}</td>
                        <td className="px-6 py-4">{openTip.ghpedouxos1} - {openTip.filoxenoumenos1}</td>
                        <td className="px-6 py-4">{openTip.pick1}</td>
                        <td className="px-6 py-4">{openTip.odds1}</td>
                        <td className="px-6 py-4"></td>
                        <td className="px-6 py-4">
                          <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                            <input 
                              id={`keimenoapotel1${openTip.id}`}
                              name={`keimenoapotel1${openTip.id}`}
                              key={`keimenokey1${openTip.id}`} 
                              type='text'
                              onChange={(e) => changeHandler(e, openTip.id, 'triple', 'first')}
                              value={keimenoapotelvalue21[openTip.id] || ''}
                              className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                              maxLength='7'
                            />
                            <select 
                              id={`resultselect1${openTip.id}`}
                              name={`resultselect1${openTip.id}`}
                              key={`resultkey1${openTip.id}`}
                              onChange={(event) => selectChangeHandler(event, openTip.id, 'first')} 
                              value={selected1[openTip.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          </form>
                        </td>
                      </tr>
                      <tr className="bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`deutero${openTip.id || index}`}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima2)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                      </th>
                      <td className="px-6 py-4">{new Date(openTip.date2).toLocaleDateString('el-GR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}</td>
                      <td className="px-6 py-4">{openTip.time2 ? openTip.time2.slice(0, 5) : 'N/A'}</td>
                      <td className="px-6 py-4">{openTip.ghpedouxos2} - {openTip.filoxenoumenos2}</td>
                      <td className="px-6 py-4">{openTip.pick2}</td>
                      <td className="px-6 py-4">{openTip.odds2}</td>
                      <td className="px-6 py-4"></td>
                      <td className="px-6 py-4">
                        <form className="flex w-full max-w-fit mx-auto gap-x-3.5">
                          <input 
                            id={`keimenoapotel2${openTip.id}`}
                            name={`keimenoapotel2${openTip.id}`}
                            key={`keimenokey2${openTip.id}`} 
                            type='text'
                            onChange={(e) => changeHandler(e, openTip.id, 'triple', 'second')}
                            value={keimenoapotelvalue22[openTip.id] || ''}
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                            maxLength='7'
                          />
                          <select 
                              id={`resultselect2${openTip.id}`}
                              name={`resultselect2${openTip.id}`}
                              key={`resultkey2${openTip.id}`}
                              onChange={(event) => selectChangeHandler(event, openTip.id, 'second')} 
                              value={selected2[openTip.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                        </form>
                      </td>
                    </tr>
                    <tr className="border-b-8 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700" key={`trito${openTip.id || index}`}>
                      <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        {(prwtathlimataNames && prwtathlimataNames.find(prwt => prwt.id === openTip.prwtathlima3)?.name) || 'Δεν βρέθηκε πρωτάθλημα'  }
                      </th>
                      <td className="px-6 py-4">{new Date(openTip.date3).toLocaleDateString('el-GR', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      })}</td>
                      <td className="px-6 py-4">{openTip.time3 ? openTip.time3.slice(0, 5) : 'N/A'}</td>
                      <td className="px-6 py-4">{openTip.ghpedouxos3} - {openTip.filoxenoumenos3}</td>
                      <td className="px-6 py-4">{openTip.pick3}</td>
                      <td className="px-6 py-4">{openTip.odds3}</td>
                      <td className="px-6 py-4">{openTip.telikoposo}</td>
                      <td className="px-6 py-4">
                        <form className="flex w-full max-w-fit mx-auto gap-x-3.5" onSubmit={(e) => submitApotelesmaHandler(e, openTip, 'triplo')}>
                          <input 
                            id={`keimenoapotel3${openTip.id}`}
                            name={`keimenoapotel3${openTip.id}`}
                            key={`keimenokey3${openTip.id}`} 
                            type='text'
                            onChange={(e) => changeHandler(e, openTip.id, 'triple', 'third')}
                            value={keimenoapotelvalue23[openTip.id] || ''}
                            className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg w-20 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white'
                            maxLength='7'
                          />
                          <select 
                              id={`resultselect3${openTip.id}`}
                              name={`resultselect3${openTip.id}`}
                              key={`resultkey3${openTip.id}`}
                              onChange={(event) => selectChangeHandler(event, openTip.id, 'third')} 
                              value={selected3[openTip.id] || ''}
                              className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
                            >
                              <option value="">Αποτέλεσμα</option>
                              {resultOptions.map(option => (
                                <option key={option.id} value={option.id}>{option.name}</option>
                              ))}
                            </select>
                          <Button type='submit' buttonClasses="text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800" >Προσθήκη</Button>
                        </form>
                      </td>
                    </tr>
                  </>
                  )
                }
                return null
                }
                )
                }
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <h1 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">
          Δεν βρέθηκαν ανοιχτά Tips για τον χρήστη {currentUser.username}
        </h1>
      )
    ) : (
      <Card>
        <h1>Ο χρήστης δεν βρέθηκε</h1>
      </Card>
    )}
    </>
  );
};

export default Apotelesmata