import { Routes, Route } from "react-router-dom";
import { Suspense, useContext, lazy } from "react";

import { AuthContext, MainNavigation, LoadingSpinner } from "../shared";
import { UserLogin, PageNotFound, UsersList} from "../pages";


const UserProfile = lazy(() => import('../pages').then(module => ({ default: module.UserProfile })));
const Prognwstika = lazy(() => import('../pages/Prognwstika'));
const Tips = lazy(() => import('../pages').then(module => ({ default: module.Tips })));
const Apotelesmata = lazy(() => import('../pages/Apotelesmata'));
const Athlimata = lazy(() => import('../pages').then(module => ({ default: module.Athlimata })));
const EpeksergasiaApotelesmatwn = lazy(() => import('../pages').then(module => ({ default: module.EpeksergasiaApotelesmatwn })));
const Etaireies = lazy(() => import('../pages').then(module => ({ default: module.Etaireies })));
const Prwtathlimata = lazy(() => import('../pages').then(module => ({ default: module.Prwtathlimata })));
const Xwres = lazy(() => import('../pages').then(module => ({ default: module.Xwres })));
const Agores = lazy(() => import('../pages').then(module => ({ default: module.Agores })));
const Xrhstes = lazy(() => import('../pages').then(module => ({ default: module.Xrhstes })));
const Statistika = lazy(() => import('../pages').then(module => ({ default: module.Statistika })));
const EpeksergasiaTips = lazy(() => import('../pages').then(module => ({ default: module.EpeksergasiaTips })));
const EpeksergasiaMemonwmenouApotelesmatos = lazy(() => import('../pages').then(module => ({ default: module.EpeksergasiaMemonwmenouApotelesmatos })));



export const AllRoutes = () => {
  const auth = useContext(AuthContext);

  const routes = !auth.isLoggedIn ? (
    <>
      <Route path="/" element={<UserLogin pageTitle="Login" />} />
      <Route path="*" element={<PageNotFound pageTitle="Page not found" />} />
    </>
  ) : (
    <>
      <Route path="/" element={<UsersList pageTitle="Χρήστες" />} />
      <Route path="/user/:userid" element={<UserProfile pageTitle="Προφίλ Χρήστη" />} />
      <Route path="/users/:userid/prognwstika" element={<Prognwstika pageTitle="Προγνωστικά Χρήστη" />} />
      <Route path="/users/:userid/statistika" element={<Statistika pageTitle="Στατιστικά Χρήστη" />} />
      <Route path="/users/:userid/tips" element={<Tips pageTitle="Προσθήκη Tip" />} />
      <Route path="/users/:userid/tips/:tipid" element={<EpeksergasiaTips pageTitle="Επεξεργασία Tip" />} />
      <Route path="/users/:userid/apotelesmata" element={<Apotelesmata pageTitle="Προσθήκη Αποτελεσμάτων" />} />
      <Route path="/users/:userid/epeksergasia-apotelesmatwn" element={<EpeksergasiaApotelesmatwn pageTitle="Επεξεργασία Αποτελεσμάτων" />} />
      <Route path="/users/:userid/epeksergasia-apotelesmatwn/:apotelesmaid" element={<EpeksergasiaMemonwmenouApotelesmatos pageTitle="Επεξεργασία Αποτελέσματος" />} />

      {/* Admin-only routes */}
      {auth.role === 'admin' && (
        <>
          <Route path="/users/:userid/agores" element={<Agores pageTitle="Αγορές" />} />
          <Route path="/users/:userid/athlimata" element={<Athlimata pageTitle="Αθλήματα" />} />
          <Route path="/users/:userid/etaireies" element={<Etaireies pageTitle="Εταιρείες" />} />
          <Route path="/users/:userid/prwtathlimata" element={<Prwtathlimata pageTitle="Πρωταθλήματα" />} />
          <Route path="/users/:userid/xrhstes" element={<Xrhstes pageTitle="Χρήστες" />} />
          <Route path="/users/:userid/xwres" element={<Xwres pageTitle="Χώρες" />} />
        </>
      )}

      {/* Catch-all route for unmatched paths */}
      <Route path="*" element={<PageNotFound pageTitle="Page not found" />} />
    </>
  );

  return (
    <>
      <MainNavigation />
      <main className='min-h-90h px-4 lg:px-6 mt-9 mb-9'>
      <Suspense fallback={<LoadingSpinner asOverlay/>}>
        <Routes>
          {routes}
          {/* Fallback catch-all route */}
          <Route path="*" element={<PageNotFound pageTitle="Page not found" />} />
        </Routes>
      </Suspense>
      </main>
    </>
  );
};
