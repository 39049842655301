import { useEffect, useContext, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useTitle, Card, Button, LiveBets, LongTermBets, OpenBets, Overall, PregamePicksHistory, LoadingSpinner, useHttpClient, ErrorModal, AuthContext }
 from "../shared";

const Prognwstika = ( { pageTitle } ) => {
  useTitle(pageTitle);

  const userid = parseInt(useParams().userid, 10);
  const auth = useContext(AuthContext);
  const [selected, setSelected] = useState({});
  const [seasonsOverall, setseasonsOverall] = useState([]);
  const [queriedSeason, setQueriedSeason] = useState(null);
  const [seasonStats, setSeasonStats] = useState([]);
  const [openTips, setOpenTips] = useState([]);
  const [longTermTips, setLongTermTips] = useState([]);
  const [liveTips, setLiveTips] = useState([]);
  const [tipResults, setTipResults] = useState([]);
  const [userInfoOverall, setUserInfoOverall] = useState([])
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [currentUser, setCurrentUser] = useState(null)
  const [openTipsPagination, setOpenTipsPagination] = useState(null)
  const [longTermTipsPagination, setLongTermTipsPagination] = useState(null)
  const [liveTipsPagination, setLiveTipsPagination] = useState(null)
  const [resultsPagination, setResultsPagination] = useState(null)

  const openTypes = useRef(['REGULAR']);
  const longTermTypes = useRef([]);
  const liveTypes = useRef([]);

    useEffect(() => {
      const fetchUser = async () => {
        try {
          const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userid}`, 'GET', null, {
            Authorization: `Bearer ${auth.token}`
          })
          setCurrentUser(responseData.user)
        } catch (err) {
          
        }
      }

      fetchUser()
    }, [sendRequest, userid, auth.token])

    useEffect(() => {
      const fetchUserOverall =  async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/userOverall`,
            'GET',
            null,
            {
              Authorization: `Bearer ${auth.token}`
            }
          )

          const userOverall = responseData.overall;
          setUserInfoOverall(userOverall)
          if (userOverall.length > 0) {
            // Ensure we get a flat array of seasons, without duplicates
            const userOverallSeasons = userOverall[0].season;
        
            // Sort the seasons by the starting year
            const sortedOverallSeasons = userOverallSeasons.sort((a, b) => {
              const yearA = parseInt(a.split('-')[0], 10);
              const yearB = parseInt(b.split('-')[0], 10);
              return yearB - yearA;
            });
        
            // Set the properly formatted and sorted seasons to state
            setseasonsOverall(sortedOverallSeasons);
            setQueriedSeason(sortedOverallSeasons[0]);
          }
        } catch (err) {
          
        }
        
      }

      fetchUserOverall()
      
    }, [sendRequest, userid, auth.token]);
    

    useEffect(() => {
      if (queriedSeason) {
        const fetchUserStats = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/statsBySeason`,
              'POST',
              JSON.stringify({
                queriedSeason
              }),
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            )

            const statsBySeason = responseData.userStatsBySeason
            setSeasonStats(statsBySeason);
          } catch (err) {
            
          }
        }

        fetchUserStats()
      }
    }, [queriedSeason, userid, sendRequest, auth.token])


    useEffect(() => {
      if (currentUser && queriedSeason) {
        openTypes.current = ['REGULAR'];

        if (currentUser.long_term === 'REGULAR') {
          openTypes.current.push('LONG TERM BET');
        }     
        if (currentUser.live_bet === 'REGULAR') {
          openTypes.current.push('LIVE BET');
        } 
        
        const fetchOpenTips = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/userOpenTips?page=1&pageSize=15`,
              'POST',
              JSON.stringify({
                openTypes: openTypes.current
              }),
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            )

            const userOpenTips = responseData.openTips
            setOpenTips(userOpenTips)
            setOpenTipsPagination(responseData.pagination)
          } catch (err) {
            
          }

          
        }

        fetchOpenTips()
      }
    }, [currentUser, userid, sendRequest, queriedSeason, auth.token])

    useEffect(() => {
      if (currentUser && queriedSeason) {

        longTermTypes.current = [];

        if (currentUser.long_term === 'LONG TERM BET') {
          longTermTypes.current.push('LONG TERM BET');
        }

        if (currentUser.live_bet === 'LONG TERM BET') {
          longTermTypes.current.push('LIVE BET');
        }

        const fetchLongTermTips = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/userLongTermTips?page=1&pageSize=15`,
              'POST',
              JSON.stringify({
                longTermTypes: longTermTypes.current,
                queriedSeason
              }),
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            )

            const userLongTermTips = responseData.longTermTips
            setLongTermTips(userLongTermTips)
            setLongTermTipsPagination(responseData.pagination)
          } catch (err) {
            
          }

          
        }

        
          
        
        if (longTermTypes.current.length > 0) {
          fetchLongTermTips()
        }
      }
    }, [currentUser, queriedSeason, sendRequest, userid, auth.token])

    useEffect(() => {
      if (currentUser && queriedSeason) {

        liveTypes.current = [];

        if (currentUser.long_term === 'LIVE BET') {
          liveTypes.current.push('LONG TERM BET')
        }
    
         else if (currentUser.live_bet === 'LIVE BET') {
          liveTypes.current.push('LIVE BET')
        }

        const fetchLiveTips = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/userLiveTips?page=1&pageSize=15`,
              'POST',
              JSON.stringify({
                liveTypes: liveTypes.current,
                queriedSeason
              }),
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
              }
            )

            const userLiveTips = responseData.liveTips
            setLiveTips(userLiveTips)
            setLiveTipsPagination(responseData.pagination)
          } catch (err) {
            
          }

          
        }   
        
        if (liveTypes.current.length > 0) {
          fetchLiveTips()
        }
      }
    }, [currentUser, queriedSeason, sendRequest, userid, auth.token])
    
    useEffect(() => {
      if (currentUser && queriedSeason) {
        openTypes.current = ['REGULAR'];

        if (currentUser.long_term === 'REGULAR') {
          openTypes.current.push('LONG TERM BET');
        }     
        if (currentUser.live_bet === 'REGULAR') {
          openTypes.current.push('LIVE BET');
        } 

        const fetchResults = async () => {
          try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/prognwstika/userResults?page=1&pageSize=20`,
              'POST',
              JSON.stringify({
                openTypes: openTypes.current,
                queriedSeason
              }),
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth.token}`
                
              }
            )
            
            const userResults = responseData.results
            setTipResults(userResults)
            setResultsPagination(responseData.pagination)
          } catch (err) {
            
          }

          
        }   
        fetchResults()
      }
      
    }, [userid, queriedSeason, sendRequest, currentUser, auth.token])

  if (!currentUser) {
    
      return (
        <Card>
          <h1>Ο χρήστης δεν βρέθηκε</h1>
        </Card>
      );
    
  }


  const selectChangeHandler = (event, season) => {
    setSelected(prevState => ({ ...prevState, [season]: event.target.value }));
    if (event.target.value) {
      setQueriedSeason(event.target.value);
    }
  };

  
  const deletedOpenTip = (overall) => {
    const userOverall = overall;
      if (userOverall.length > 0)  {
        setUserInfoOverall(userOverall)

        if (
          userOverall[0].season.length !== userInfoOverall[0].season.length
        ) {
          const userOverallSeasons = overall[0].season;
  
          const sortedOverallSeasons = userOverallSeasons.sort((a, b) => {
            const yearA = parseInt(a.split('-')[0], 10);
            const yearB = parseInt(b.split('-')[0], 10);
            return yearB - yearA;
          });
          setseasonsOverall(sortedOverallSeasons);
          setQueriedSeason(sortedOverallSeasons[0]);
        }

        
      }
      
      
  }

  const deletedTipResult = (statsPerSeason) => {
    setSeasonStats(statsPerSeason)
  }

  


  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <div className='flex justify-between border-1' >
        <h1 className="mb-3 text-2xl font-medium text-gray-900 dark:text-white">
          <span className="uppercase">{currentUser.username}</span> - Προγνωστικά
        </h1>
        <div className='flex gap-x-1'>
          <Button to={`/users/${currentUser.id}/prognwstika`} buttonClasses={`text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800`}>⚽ Bets</Button>
          <Button to={`/users/${currentUser.id}/statistika`} buttonClasses={`text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm lg:text-lg px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-betblue focus:outline-none dark:focus:ring-blue-800`}>📈 Στατιστικά</Button>
        </div>
      </div>

      <h2 className="mb-1 mt-7 text-xl font-medium text-gray-900 dark:text-white" >Overall</h2>
        <div className="mb-5 w-fit">
        <select 
            id={`season`}
            name={`season`}
            onChange={(event) => selectChangeHandler(event, 'season')} 
            value={selected['season'] || ''}
                 className='w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer'
            >
            <option value="">Επιλογή season</option>
                {seasonsOverall.map(option => (
            <option key={option} value={option}>{option}</option>
            ))}
        </select>
        </div>
        <div>
            <Overall currentSeason={queriedSeason} statsPerSeasons={seasonStats} userOverall={userInfoOverall}/>
            <OpenBets currentSeason={queriedSeason} userId={userid} userTipsOpen={openTips} deleteTip={deletedOpenTip} openTypes={openTypes.current} openPagination={openTipsPagination}/>
            
            {longTermTypes.current.length > 0 ? (
              <LongTermBets userId={userid} currentSeason={queriedSeason} userTipsLongTerm={longTermTips} deleteTip={deletedOpenTip} deleteResult={deletedTipResult} longTermTypes={longTermTypes.current} longTermPagination={longTermTipsPagination}/>
            ) : null}

            {/* Conditionally render LiveBets component only if necessary */}
            {liveTypes.current.length > 0 ? (
              <LiveBets userId={userid} currentSeason={queriedSeason} userTipsLive={liveTips} deleteTip={deletedOpenTip} deleteResult={deletedTipResult} liveTypes={liveTypes.current} livePagination={liveTipsPagination}/>
            ) : null}
            <PregamePicksHistory userId={userid} currentSeason={queriedSeason} resultsOfUser={tipResults} deleteTip={deletedOpenTip} deleteResult={deletedTipResult} openTypes={openTypes.current} resultsPagination = {resultsPagination}/>
        </div>
    </>
  )
}

export default Prognwstika