
// Function to calculate statistics based on the results for a specific month
function calculateEidos(results) {
  const totalBets = results.length;

  // Calculate the number of won bets
  const wonBets = results.filter(tip => {
      const moneyResult = Number(tip.moneyresult);
      return !isNaN(moneyResult) && moneyResult > 0;
  }).length;

  // Calculate total profit
  const totalProfit = results.reduce((acc, tip) => {
      const moneyResult = Number(tip.moneyresult);
      return acc + (isNaN(moneyResult) ? 0 : moneyResult);
  }, 0);

  // Calculate total stake
  const totalStake = results.reduce((acc, tip) => {
      const stake = Number(tip.telikoposo);
      return acc + (isNaN(stake) ? 0 : stake);
  }, 0);

  // Calculate average stake
  const avgStake = totalBets !== 0 ? totalStake / totalBets : 0;

  // Calculate average odds
  const totalOdds = results.reduce((acc, tip) => {
      const odds = Number(tip.preresultodds);
      return acc + (isNaN(odds) ? 0 : odds);
  }, 0);
  const avgOdds = totalBets !== 0 ? totalOdds / totalBets : 0;

  // Calculate win rate
  const winRate = totalBets !== 0 ? (wonBets / totalBets) * 100 : 0;

  // Calculate yield
  const yieldValue = totalStake !== 0 ? (totalProfit / totalStake) * 100 : 0;

  return {
      totalBets,
      winRate: Math.round(winRate),
      totalProfit: Math.round(totalProfit),
      yield: yieldValue.toFixed(2),
      avgStake: avgStake.toFixed(2),
      avgOdds: avgOdds.toFixed(2)
  };
}

export const Mhna = ({ resultedTips }) => {
  // Group results by month/year (e.g., "08/2024")
  const resultsByMonth = {};

  resultedTips.forEach(tip => {
    const date = tip.date1; // e.g., "2024-09-18"
    if (date) {
      const [year, month] = date.split("-"); // Extract year and month from date
      const monthYear = `${month}/${year}`; // Format as "MM/YYYY"
      if (!resultsByMonth[monthYear]) {
        resultsByMonth[monthYear] = [];
      }
      resultsByMonth[monthYear].push(tip); // Group tips by month
    }
  });

  

  // Sort months in chronological order
  const sortedMonths = Object.keys(resultsByMonth).sort((a, b) => {
    const [monthA, yearA] = a.split("/").map(Number);
    const [monthB, yearB] = b.split("/").map(Number);

    if (yearA === yearB) {
      return monthA - monthB; // Compare months if years are the same
    } else {
      return yearA - yearB; // Compare years if different
    }
  });

  return (
    <div className="relative mt-4 overflow-x-auto sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-betblue dark:bg-gray-700 dark:text-gray-400">
          <tr className="stattr">
            <th scope="col" className="px-6 py-3">Month</th>
            <th scope="col" className="px-6 py-3">Picks</th>
            <th scope="col" className="px-6 py-3">Win Rate</th>
            <th scope="col" className="px-6 py-3">Profit</th>
            <th scope="col" className="px-6 py-3">Yield</th>
            <th scope="col" className="px-6 py-3">Avg Stake</th>
            <th scope="col" className="px-6 py-3">Avg Odds</th>
          </tr>
        </thead>
        <tbody>
          {sortedMonths.map(monthYear => {
            const monthlyStats = calculateEidos(resultsByMonth[monthYear]);
            return (
              <tr key={monthYear} className="stattr border-b-2 border-white bg-gray-100 dark:bg-gray-700 dark:border-gray-700">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {monthYear}
                </th>
                <td className="px-6 py-4">{monthlyStats.totalBets}</td>
                <td className="px-6 py-4">{monthlyStats.winRate}%</td>
                <td className="px-6 py-4">{monthlyStats.totalProfit}</td>
                <td className="px-6 py-4">{monthlyStats.yield}%</td>
                <td className="px-6 py-4">{monthlyStats.avgStake}</td>
                <td className="px-6 py-4">{monthlyStats.avgOdds}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
