import { Modal, Button } from '../../index'

export const ErrorModal = props => {
  return (
    <Modal
        show={!!props.error}
        onCancel={props.onClear}
        header="Σφάλμα"
        footer={<Button type="button" onClick={props.onClear}>Okay</Button> }
    >
        <p>{props.error} </p>
    </Modal>
  )
}
