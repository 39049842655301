import { useState, useCallback, useEffect, useRef, useContext } from "react"

import { AuthContext } from "../../shared"

export const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const auth = useContext(AuthContext)

    const activeHttpRequests = useRef([])



    const sendRequest = useCallback(async (url, method = 'GET', body = null, headers= {}) => {
        setIsLoading(true)
        const httpAbortCtrl = new AbortController();
        activeHttpRequests.current.push(httpAbortCtrl)
        if (!headers.hasOwnProperty("Authorization") && auth.token) {
            headers = {
                ...headers,
                Authorization: `Bearer ${auth.token}`
            };
        }


        try {
            const response = await fetch(url, {
                method,
                body,
                headers,
                signal: httpAbortCtrl.signal
            })

            const responseData = await response.json()

            activeHttpRequests.current = activeHttpRequests.current.filter(
                reqCtrl => reqCtrl !== httpAbortCtrl
            )

            if (!response.ok) {
                throw new Error(responseData.message)
            }

            setIsLoading(false)
            return responseData
        } catch (err) {
            if (!err.message.includes('The operation was aborted')) {
                console.log('Error message:', err.message);
                setError(err.message);
                setIsLoading(false);
                throw err;
            }
        }

    }, [auth.token])

    const clearError = () => {
        setError(null)
    }


    useEffect(() => {
        return () => {
            // eslint-disable-next-line
            activeHttpRequests.current.forEach(abortCtrl => {
                    abortCtrl.abort()                
            })
        }
    }, [])

    return { isLoading, error, sendRequest, clearError }
}