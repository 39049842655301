import { useReducer, useEffect, useState } from "react"


import { validate } from "../../util/validators"

const inputReducer = (state, action) => {
    switch(action.type) {
        case 'CHANGE': 
        return {
            ...state,
            value: action.val,
            isValid: validate(action.val, action.validators)
            
        }
        case 'TOUCH':
         return {
            ...state,
            isTouched: true
         }
         case 'SET':
            return {
                value: action.value,
                isValid: action.isValid,
                isTouched: false
            };
         default: 
            return state
    }
}
// let checkboxChanges
// let selectedChanges
export const Input = (props) => {
    const classes = props.inputClasses ? props.inputClasses : `mb-4 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white ${props.additionalInputClasses}`;
    const [checked, setChecked] = useState(props.initialChecked || false)
    const [selected, setSelected] = useState(props.initialSelected || '');
    
    const [inputState, dispatch] = useReducer(inputReducer, {
        value: props.initialValue || props.initialSelected || '',
        isValid: props.initialValid || false,
        isTouched: false,
        username: props.initialName || ''
    })
    
    const {id, onInput, numberButton, onResetComplete, selectItem} = props;
    const {value, isValid, isTouched, username} = inputState
    
    
    useEffect(() => {
        if (props.formSubmitted) { // Only run effect if form is submitted
          dispatch({
            type: 'SET',
            value: props.value || '',
            isValid: props.initialValid || false
          });
        }
        
      }, [props.value, props.initialValid, props.formSubmitted]);

      useEffect(() => {
        if (numberButton) {
            if (id === "popuptext") {
                dispatch({
                    type: 'SET',
                    value: '',
                    isValid: true
                  });
            } else  {
                dispatch({
                    type: 'SET',
                    value: '',
                    isValid: false
                  });
            }
            
            setSelected('');

            if (onResetComplete) onResetComplete();
          }

          
          
      }, [numberButton, onResetComplete, id]);

      useEffect(() => {
        if (props.season) { // Only run effect if form is submitted
          dispatch({
            type: 'SET',
            value: props.season || '',
            isValid: true
          });
        
        }
        
      }, [props.season]);

    useEffect(() => {
        
        onInput(id, value, isValid, username)
    }, [id, value, isValid, onInput, username ])
    

    const changeHandler = (e) => {
        
        dispatch({
            type: 'CHANGE',
            val: e.target.value,
            validators: props.validators
        })

        if (props.type === 'date' && e.target.id === 'date1' && e.target.value) {
            if (props.dateValue) {
                props.dateValue(e.target.value);
            }
        }
    }

    const checkboxChangeHandler = event => {
        // checkboxChanges = event;
        setChecked(!checked);
    }

    const selectChangeHandler = (event) => {
        setSelected(event.target.value);
        dispatch({
            type: 'CHANGE',
            val: event.target.value,
            validators: props.validators
        })
        if (selectItem) selectItem(event);
      };



    const touchHandler = () => {
        dispatch({
            type: 'TOUCH'
        })
    }

    const element = 
    props.type === 'text' || props.type === 'password' || props.type === 'number' || props.type === 'hidden' ? (
        <input 
            id={props.id}
            name ={props.name}
            type={props.type}
            onChange={changeHandler}
            onBlur={touchHandler}
            placeholder={props.placeholder}
            value={props.season || value}
            className={classes}
            required={props.required}
            disabled={props.disabled}
            readOnly={props.readonly}
        />
    ) 
    : props.type === 'select' ? (
        <select 
            id={props.id}
            name={props.name}
            key={props.id} 
            select={selected}
            onChange={selectChangeHandler} 
            value={selected}
            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 cursor-pointer mb-7`}
        >
            <option value="" >{props.defaultOption}</option>
            {props.options && props.options.map(option => (
                typeof option === 'object' ?
                <option key={option.id} value={option.id}>{option.name}</option>
                :
                <option key={option} value={option}>{option}</option>
            ))}
        </select>
    )
    : props.type === 'checkbox' ? (
        <input 
            id={props.id}
            name={props.name}
            key={props.id} 
            type={props.type} 
            placeholder={props.placeholder}
            checked={checked}
            onChange={checkboxChangeHandler} 
            value={checked ? props.value : ''}
            className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
        />
    )
    : props.type === 'date' ? (
        <input 
            id={props.id}
            name={props.name}
            key={props.id}
            type={props.type}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={value}
            className={classes}
            required={props.required}
        />
    )
    : props.type === 'time' ? (
        <input 
            id={props.id}
            name={props.name}
            key={props.id}
            type={props.type}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={value}
            className={classes}
            required={props.required}
        />
    )
    : (
        <textarea 
            id={props.id}
            name={props.name}
            key={props.id}
            rows={props.rows || 3}
            onChange={changeHandler}
            onBlur={touchHandler}
            value={value}
            className={classes}
        />
    )

    if (props.type === 'checkbox') {
        return (
            <div className="flex items-center mb-4">
                {element}
                <label htmlFor={props.id} className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{props.label}</label>
            </div>
        )
    } 


  return (
    <div>
              <label htmlFor={props.id} className={`block mb-2 text-sm font-medium text-gray-900 dark:text-white ${props.type === 'hidden' ? 'not-visible' : ''}`}>{props.label}</label>
              {element}
              {!isValid && isTouched && <p className="-mt-2 text-red-500">{props.errorText}</p>}
    </div>
  )
}