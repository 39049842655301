import { useState, useEffect, useRef, useCallback } from "react";
import { VALIDATOR_REQUIRE, VALIDATOR_NUMBER } from "../shared/util/validators";
import { useTitle, Button, useForm, Input, Card, ErrorModal, Modal, LoadingSpinner, useHttpClient } from "../shared";
import { CSSTransition } from 'react-transition-group';
import { useParams } from 'react-router-dom';

export const Tips = ({pageTitle}) => {
  useTitle(pageTitle);
  const { userid } = useParams();
  const [tipFormState, tipInputHandler, setFormData] = useForm({
    athlima1: {
      value: '',
      isValid: false
    },
    xwra1: {
      value: '',
      isValid: false
    },
    prwtathlima1: {
      value: '',
      isValid: false
    }
  }, false);
  const [showAgoresXwresField1, setShowAgoresXwresField1] = useState(false);
  const [showPrwtathlimaField1, setShowPrwtathlimaField1] = useState(false);
  const [showAgoresXwresField2, setShowAgoresXwresField2] = useState(false);
  const [showPrwtathlimaField2, setShowPrwtathlimaField2] = useState(false);
  const [showAgoresXwresField3, setShowAgoresXwresField3] = useState(false);
  const [showPrwtathlimaField3, setShowPrwtathlimaField3] = useState(false);
  const [xwresBashAthlimatos1, setXwresBashAthlimatos1] = useState([]);
  const [xwresBashAthlimatos2, setXwresBashAthlimatos2] = useState([]);
  const [xwresBashAthlimatos3, setXwresBashAthlimatos3] = useState([]);
  const [prwtathlimataBashXwrwn1, setPrwtathlimataBashXwrwn1] = useState([]);
  const [prwtathlimataBashXwrwn2, setPrwtathlimataBashXwrwn2] = useState([]);
  const [prwtathlimataBashXwrwn3, setPrwtathlimataBashXwrwn3] = useState([]);
  const [agoresBashAthlimatos1, setAgoresBashAthlimatos1] = useState([]);
  const [agoresBashAthlimatos2, setAgoresBashAthlimatos2] = useState([]);
  const [agoresBashAthlimatos3, setAgoresBashAthlimatos3] = useState([]);
  const [tipSeason, setTipSeason] = useState(null);
  const [allTips, setAllTips] = useState(null);
  const [showTipSubmitModal, setShowTipSubmitModal] = useState(false);
  const {isLoading, error, sendRequest, clearError} = useHttpClient()
  const [allAthlimata, setAllAthlimata] = useState([])
  const [allEtaireies, setAllEtairies] = useState([])
  const [currentUser, setCurrentUser] = useState(null)

  const resetRef = useRef(false);
  const numberOfTipsRef = useRef(1);

  useEffect(() => {
    const fetchAthlimata = async () => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/tips/athlimata`
        )

        setAllAthlimata(responseData.athlimata)
      } catch (err) {
        
      }

      
    }

    fetchAthlimata()
  }, [sendRequest, userid])

  // const allAthlimata = useMemo(() => dummyathlimata.map((athl) => ({
  //   name: athl.name,
  //   id: athl.id,
  // })), []);

  useEffect(() => {
    const fetchEtairies = async () => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/etaireies`
        )

        setAllEtairies(responseData.etairies)
      } catch (err) {
        
      }

      
    }

    fetchEtairies()
  }, [sendRequest, userid])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${userid}`)
        setCurrentUser(responseData.user)
      } catch (err) {
        
      }
      
    }

    fetchUser()
  }, [sendRequest, userid])
  
  const afterItemSelect = useCallback( async (e) => {
    if (e.target.value && e.target.id) {
      if (e.target.id.includes('athlima')) {
        
        const athlimaById = allAthlimata.find(athl => athl.id === parseInt(e.target.value, 10));

        if (!athlimaById) {

          return;
        }        

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/tips/xwres`,
            'POST',
            JSON.stringify({
              athlimaId: parseInt(e.target.value, 10)
            }),
            {
              'Content-Type': 'application/json'
            }
          )

            if (e.target.id.includes('1')) {
              setXwresBashAthlimatos1(responseData.xwres);
              if (!showAgoresXwresField1) {
                setShowAgoresXwresField1(true);
              }
            } else if (e.target.id.includes('2')) {
              setXwresBashAthlimatos2(responseData.xwres);
              if (!showAgoresXwresField2) {
                setShowAgoresXwresField2(true);
              }
            } else if (e.target.id.includes('3')) {
              setXwresBashAthlimatos3(responseData.xwres);
              if (!showAgoresXwresField3) {
                setShowAgoresXwresField3(true);
              }
            }

            if (e.target.id === "athlima1" && e.target.value) {
              const xwraField = document.getElementById("xwra1");
              const prwtathlimaField = document.getElementById("prwtathlima1");

              if (xwraField) {
                const changeEvent = new Event("change", { bubbles: true });
                xwraField.value = "";
                xwraField.dispatchEvent(changeEvent); 
              }

              if (prwtathlimaField) {
                const changeEvent = new Event("change", { bubbles: true });
                prwtathlimaField.value = "";
                prwtathlimaField.dispatchEvent(changeEvent); 
              }
            }

            if (e.target.id === "athlima2" && e.target.value) {

              const xwraField = document.getElementById("xwra2");
              const prwtathlimaField = document.getElementById("prwtathlima2");

              if (xwraField) {
                const changeEvent = new Event("change", { bubbles: true });
                xwraField.value = "";
                xwraField.dispatchEvent(changeEvent); 
              }

              if (prwtathlimaField) {
                const changeEvent = new Event("change", { bubbles: true });
                prwtathlimaField.value = "";
                prwtathlimaField.dispatchEvent(changeEvent); 
              }
            }

            if (e.target.id === "athlima3" && e.target.value) {

              const xwraField = document.getElementById("xwra3");
              const prwtathlimaField = document.getElementById("prwtathlima3");

              if (xwraField) {
                const changeEvent = new Event("change", { bubbles: true });
                xwraField.value = "";
                xwraField.dispatchEvent(changeEvent); 
              }

              if (prwtathlimaField) {
                const changeEvent = new Event("change", { bubbles: true });
                prwtathlimaField.value = "";
                prwtathlimaField.dispatchEvent(changeEvent);
              }
            }


        } catch (err) {
          if (e.target.id.includes('1')) {
            setXwresBashAthlimatos1([]);
            if (!showAgoresXwresField1) {
              setShowAgoresXwresField1(true);
            }
          } else if (e.target.id.includes('2')) {
            setXwresBashAthlimatos2([]);
            if (!showAgoresXwresField2) {
              setShowAgoresXwresField2(true);
            }
          } else if (e.target.id.includes('3')) {
            setXwresBashAthlimatos3([]);
            if (!showAgoresXwresField3) {
              setShowAgoresXwresField3(true);
            }
          }
        }

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/tips/agores`,
            'POST',
            JSON.stringify({
              athlimaId: parseInt(e.target.value, 10)
            }),
            {
              'Content-Type': 'application/json'
            }
          )

          if (e.target.id.includes('1')) {
            setAgoresBashAthlimatos1(responseData.agores);
            if (!showAgoresXwresField1) {
              setShowAgoresXwresField1(true);
            }
          } else if (e.target.id.includes('2')) {
            setAgoresBashAthlimatos2(responseData.agores);
            if (!showAgoresXwresField2) {
              setShowAgoresXwresField2(true);
            }
          } else if (e.target.id.includes('3')) {
            setAgoresBashAthlimatos3(responseData.agores);
            if (!showAgoresXwresField3) {
              setShowAgoresXwresField3(true);
            }
          }
        } catch (err) {
          if (e.target.id.includes('1')) {
            setAgoresBashAthlimatos1([]);
            if (!showAgoresXwresField1) {
              setShowAgoresXwresField1(true);
            }
          } else if (e.target.id.includes('2')) {
            setAgoresBashAthlimatos2([]);
            if (!showAgoresXwresField2) {
              setShowAgoresXwresField2(true);
            }
          } else if (e.target.id.includes('3')) {
            setAgoresBashAthlimatos3([]);
            if (!showAgoresXwresField3) {
              setShowAgoresXwresField3(true);
            }
          }
        }

          



      } else if (e.target.id.includes('xwra')) {
        const xwraId = e.target.value;
        const tipIndex = e.target.id.charAt(e.target.id.length - 1);
        let athlimaId;
        if (tipIndex === '1') {
           athlimaId = tipFormState.inputs.athlima1.value;
        } else if (tipIndex === '2') {
           athlimaId = tipFormState.inputs.athlima2.value;
        } else if (tipIndex === '3') {
           athlimaId = tipFormState.inputs.athlima3.value;
        }
        if (athlimaId.length > 0 ) {

            try {
              const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/users/tasosnaz/tips/prwtathlimata`,
                'POST',
                JSON.stringify({
                  athlimaId: parseInt(athlimaId, 10),
                  xwraId: parseInt(xwraId, 10)
                }),
                {
                  'Content-Type': 'application/json'
                }
              )

              if (tipIndex === '1') {
                setPrwtathlimataBashXwrwn1(responseData.prwtathlimata);
                if (!showPrwtathlimaField1) {
                  setShowPrwtathlimaField1(true);
                }
              } else if (tipIndex === '2') {
                setPrwtathlimataBashXwrwn2(responseData.prwtathlimata);
                if (!showPrwtathlimaField2) {
                  setShowPrwtathlimaField2(true);
                }
              } else if (tipIndex === '3') {
                setPrwtathlimataBashXwrwn3(responseData.prwtathlimata);
                if (!showPrwtathlimaField3) {
                  setShowPrwtathlimaField3(true);
                }
              }
            } catch (err) {
              if (tipIndex === '1') {
                setPrwtathlimataBashXwrwn1([]);
                if (!showPrwtathlimaField1) {
                  setShowPrwtathlimaField1(true);
                }
              } else if (tipIndex === '2') {
                setPrwtathlimataBashXwrwn2([]);
                if (!showPrwtathlimaField2) {
                  setShowPrwtathlimaField2(true);
                }
              } else if (tipIndex === '3') {
                setPrwtathlimataBashXwrwn3([]);
                if (!showPrwtathlimaField3) {
                  setShowPrwtathlimaField3(true);
                }
              }
            }

        }
      }
      
    }
  }, [tipFormState.inputs, showAgoresXwresField1, showAgoresXwresField2, showAgoresXwresField3, showPrwtathlimaField1, showPrwtathlimaField2, showPrwtathlimaField3, allAthlimata, sendRequest, userid])

  const afterDateSelect = useCallback((date) => {
    const [year, month] = date.split('-').map(Number);

    let seasonStartYear, seasonEndYear;
    
    if (month >= 8) {
      seasonStartYear = year;
      seasonEndYear = year + 1;
    } else {
      seasonStartYear = year - 1;
      seasonEndYear = year;
    }

    const season = `${seasonStartYear}-${seasonEndYear}`;
    if (season) {
      setTipSeason(season);
    }
  }, [])
  
  useEffect(() => {
    if (!document.getElementById('athlima2')) {
    setAllTips(
      <div key='tip1'>
        <h2 key="header1" className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">
          Πρώτο Tip
        </h2>
        {[
          <Input
            key="athlima1"
            label="Άθλημα"
            id="athlima1"
            name="athlima1"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε ένα άθλημα"
            defaultOption="Επιλέξτε ένα άθλημα"
            options={allAthlimata}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            selectItem={(e) => afterItemSelect(e)}
          />
          ,
          <CSSTransition
              key="agora1-transition"
              in={showAgoresXwresField1}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
          <Input
            key="agora1"
            label="Αγορά"
            id="agora1"
            name="agora1"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια αγορά"
            defaultOption="Επιλέξτε μια αγορά"
            options={agoresBashAthlimatos1}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          </CSSTransition>
          ,
          <CSSTransition
              key="xwra1-transition"
              in={showAgoresXwresField1}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
          <Input
            key="xwra1"
            label="Χώρα"
            id="xwra1"
            name="xwra1"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια χώρα"
            defaultOption="Επιλέξτε μια χώρα"
            options={xwresBashAthlimatos1}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            selectItem={(e) => afterItemSelect(e)}
          />
          </CSSTransition>
          ,
          <CSSTransition
              key="prwtathlima1-transition"
              in={showPrwtathlimaField1}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
          <Input
            key="prwtathlima1"
            label="Πρωτάθλημα"
            id="prwtathlima1"
            name="prwtathlima1"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε ένα πρωτάθλημα"
            defaultOption="Επιλέξτε ένα πρωτάθλημα"
            options={prwtathlimataBashXwrwn1}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          </CSSTransition>,
          <Input
            key="ghpedouxos1"
            label="Γηπεδούχος"
            id="ghpedouxos1"
            name="ghpedouxos1"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />,
          <Input
            key="filoxenoumenos1"
            label="Φιλοξενούμενος"
            id="filoxenoumenos1"
            name="filoxenoumenos1"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />,
          <Input
            key="date1"
            label="Ημερομηνία"
            id="date1"
            name="date1"
            type="date"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
            dateValue={(dateV) => afterDateSelect(dateV)}
          />,
          <Input
            key="time1"
            label="Ώρα"
            id="time1"
            name="time1"
            type="time"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />,
          <Input
            key="pick1"
            label="Pick"
            id="pick1"
            name="pick1"
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />,
          <Input
            key="odds1"
            label="Odds"
            id="odds1"
            name="odds1"
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />,
          <Input
            key="popuptext"
            label="Πληροφορίες"
            id="popuptext"
            rows="4"
            name="popuptext"
            validators={[]}
            onInput={tipInputHandler}
            numberButton={resetRef.current}
            value=""
            initialValid={true}
            onResetComplete={() => { resetRef.current = false; }}
            required
            inputClasses="block mb-4 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />,
          <Input
            key="etairia"
            label="Εταιρεία"
            id="etairia"
            name="etairia"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια εταιρεία"
            defaultOption="Επιλέξτε μια εταιρεία"
            options={allEtaireies}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />,
          <Input
            key="telikoposo"
            label="Τελικό ποσό tip"
            id="telikoposo"
            name="telikoposo"
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />,
          <Input
            key="eidostip"
            label="Τύπος tip"
            id="eidostip"
            name="eidostip"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε έναν τύπο"
            defaultOption="Επιλέξτε έναν τύπο"
            options={['LIVE BET', 'LONG TERM BET', 'REGULAR']}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />,
          <Input 
              key="kleidwmeno"
              id = "kleidwmeno"
              element = 'input'
              type = 'checkbox'
              label = 'Κλειδωμένο'
              validators = {[]}
              onInput = {tipInputHandler}
              value='kleidwmeno'
              initialValid={true}
              initialName = 'Κλειδωμενο'
            />,
            <Input
              key="season"
              label="Season"
              id="season"
              name="season"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              onInput={tipInputHandler}
              errorText="Το πεδίο είναι υποχρεωτικό"
              numberButton={resetRef.current}
              value=""
              initialValid={false}
              onResetComplete={() => { resetRef.current = false; }}
              required
              season={tipSeason}
            />
        ]}
      </div>
    );
  } else {
    const tipsArray = [];
    for (let i = 1; i <= numberOfTipsRef.current; i++) {
      let tipHeading = i === 1 ? 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Πρώτο Tip </h2> 
        : i === 2 ? 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Δεύτερο Tip</h2> 
        : 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Τρίτο Tip</h2>;
      tipsArray.push(
        <div key={`tip${i}`}>
        {tipHeading}
        <Input
          key={`athlima${i}`}
          label="Άθλημα"
          id={`athlima${i}`}
          name={`athlima${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε ένα άθλημα"
          defaultOption="Επιλέξτε ένα άθλημα"
          options={allAthlimata}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
          selectItem={(e) => afterItemSelect(e)}
        />
        <CSSTransition
              key={`agora${i}-transition`}
              in={i === 1 ? showAgoresXwresField1 : i === 2 ? showAgoresXwresField2 : i === 3 ? showAgoresXwresField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
            key={`agora${i}`}
            label="Αγορά"
            id={`agora${i}`}
            name={`agora${i}`}
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια αγορά"
            defaultOption="Επιλέξτε μια αγορά"
            options={ i === 1 ? agoresBashAthlimatos1 : i === 2 ? agoresBashAthlimatos2 : i === 3 ? agoresBashAthlimatos3 : []}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          </CSSTransition>
          <CSSTransition
              key={`xwra${i}-transition`}
              in={i === 1 ? showAgoresXwresField1 : i === 2 ? showAgoresXwresField2 : i === 3 ? showAgoresXwresField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
          key={`xwra${i}`}
          label="Χώρα"
          id={`xwra${i}`}
          name={`xwra${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε μια χώρα"
          defaultOption="Επιλέξτε μια χώρα"
          options={ i === 1 ? xwresBashAthlimatos1 : i === 2 ? xwresBashAthlimatos2 : i === 3 ? xwresBashAthlimatos3 : []}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
          selectItem={(e) => afterItemSelect(e)}
        />
        </CSSTransition>
        <CSSTransition
              key={`prwtathlima${i}-transition`}
              in={i === 1 ? showPrwtathlimaField1 : i === 2 ? showPrwtathlimaField2 : i === 3 ? showPrwtathlimaField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
          key={`prwtathlima${i}`}
          label="Πρωτάθλημα"
          id={`prwtathlima${i}`}
          name={`prwtathlima${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε ένα πρωτάθλημα"
          defaultOption="Επιλέξτε ένα πρωτάθλημα"
          options={ i === 1 ? prwtathlimataBashXwrwn1 : i === 2 ? prwtathlimataBashXwrwn2 : i === 3 ? prwtathlimataBashXwrwn3 : []}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
        />
        </CSSTransition>
        <Input
            key={`ghpedouxos${i}`}
            label="Γηπεδούχος"
            id={`ghpedouxos${i}`}
            name={`ghpedouxos${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`filoxenoumenos${i}`}
            label="Φιλοξενούμενος"
            id={`filoxenoumenos${i}`}
            name={`filoxenoumenos${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`date${i}`}
            label="Ημερομηνία"
            id={`date${i}`}
            name={`date${i}`}
            type="date"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
            dateValue={ i === 1 ? (dateV) => afterDateSelect(dateV) : ''}
          />
          <Input
            key={`time${i}`}
            label="Ώρα"
            id={`time${i}`}
            name={`time${i}`}
            type="time"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
          <Input
            key={`pick${i}`}
            label="Pick"
            id={`pick${i}`}
            name={`pick${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`odds${i}`}
            label="Odds"
            id={`odds${i}`}
            name={`odds${i}`}
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
        </div>
      );
    }
    tipsArray.push(
      <>
      <Input
            key="popuptext"
            label="Πληροφορίες"
            id="popuptext"
            rows="4"
            name="popuptext"
            validators={[]}
            onInput={tipInputHandler}
            numberButton={resetRef.current}
            value=""
            initialValid={true}
            onResetComplete={() => { resetRef.current = false; }}
            required
            inputClasses="block mb-4 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <Input
            key="etairia"
            label="Εταιρεία"
            id="etairia"
            name="etairia"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια εταιρεία"
            defaultOption="Επιλέξτε μια εταιρεία"
            options={allEtaireies}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key="telikoposo"
            label="Τελικό ποσό tip"
            id="telikoposo"
            name="telikoposo"
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
          <Input
            key="eidostip"
            label="Τύπος tip"
            id="eidostip"
            name="eidostip"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε έναν τύπο"
            defaultOption="Επιλέξτε έναν τύπο"
            options={['LIVE BET', 'LONG TERM BET', 'REGULAR']}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input 
              key="kleidwmeno"
              id = "kleidwmeno"
              element = 'input'
              type = 'checkbox'
              label = 'Κλειδωμένο'
              validators = {[]}
              onInput = {tipInputHandler}
              value='kleidwmeno'
              initialValid={true}
              initialName = 'Κλειδωμενο'
            />
             <Input
              key="season"
              label="Season"
              id="season"
              name="season"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              onInput={tipInputHandler}
              errorText="Το πεδίο είναι υποχρεωτικό"
              numberButton={resetRef.current}
              value=""
              initialValid={false}
              onResetComplete={() => { resetRef.current = false; }}
              required
              season={tipSeason}
            />
      </>
    )
    setAllTips(tipsArray);
  }

}, [allAthlimata, allEtaireies, tipInputHandler, xwresBashAthlimatos1, xwresBashAthlimatos2, xwresBashAthlimatos3, prwtathlimataBashXwrwn1, prwtathlimataBashXwrwn2, prwtathlimataBashXwrwn3, agoresBashAthlimatos1, agoresBashAthlimatos2, agoresBashAthlimatos3, afterItemSelect, afterDateSelect, showAgoresXwresField1, showAgoresXwresField2, showAgoresXwresField3, showPrwtathlimaField1, showPrwtathlimaField2, showPrwtathlimaField3, tipSeason]);
  
  useEffect(() => {
    if (document.getElementById('firstbtn')) {
      document.getElementById('firstbtn').classList.add('clicked');
    }
  }, []);
  

  const numberOfTips = (event, number) => {
    resetRef.current = true;
    numberOfTipsRef.current = number;
    const tipsArray = [];
    const formData = {};
    setTipSeason(null);
    setShowAgoresXwresField1(false);
    setShowAgoresXwresField2(false);
    setShowAgoresXwresField3(false);
    setShowPrwtathlimaField1(false);
    setShowPrwtathlimaField2(false);
    setShowPrwtathlimaField3(false);
    const allButtons = document.querySelectorAll('.tipBtn');
    allButtons.forEach((btn) => {
      btn.classList.remove('clicked');
    });
    if (event && event.target) {
      event.target.classList.add('clicked');
    } else if (number) {
      const buttonId = number === 1 ? 'firstbtn' : number === 2 ? 'secondbtn' : 'thirdbtn';
      document.getElementById(buttonId)?.classList.add('clicked');
    }
    
    
    for (let i = 1; i <= number; i++) {
      let tipHeading = i === 1 ? 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Πρώτο Tip </h2> 
        : i === 2 ? 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Δεύτερο Tip</h2> 
        : 
        <h2 key={`header${i}`} className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Τρίτο Tip</h2>;
      tipsArray.push(
        <div key={`tip${i}`}>
        {tipHeading}
        <Input
          key={`athlima${i}`}
          label="Άθλημα"
          id={`athlima${i}`}
          name={`athlima${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε ένα άθλημα"
          defaultOption="Επιλέξτε ένα άθλημα"
          options={allAthlimata}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
          selectItem={(e) => afterItemSelect(e)}
        />
        <CSSTransition
              key={`agora${i}-transition`}
              in={i === 1 ? showAgoresXwresField1 : i === 2 ? showAgoresXwresField2 : i === 3 ? showAgoresXwresField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
            key={`agora${i}`}
            label="Αγορά"
            id={`agora${i}`}
            name={`agora${i}`}
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια αγορά"
            defaultOption="Επιλέξτε μια αγορά"
            options={ i === 1 ? agoresBashAthlimatos1 : i === 2 ? agoresBashAthlimatos2 : i === 3 ? agoresBashAthlimatos3 : []}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          </CSSTransition>
          <CSSTransition
              key={`xwra${i}-transition`}
              in={i === 1 ? showAgoresXwresField1 : i === 2 ? showAgoresXwresField2 : i === 3 ? showAgoresXwresField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
          key={`xwra${i}`}
          label="Χώρα"
          id={`xwra${i}`}
          name={`xwra${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε μια χώρα"
          defaultOption="Επιλέξτε μια χώρα"
          options={ i === 1 ? xwresBashAthlimatos1 : i === 2 ? xwresBashAthlimatos2 : i === 3 ? xwresBashAthlimatos3 : []}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
          selectItem={(e) => afterItemSelect(e)}
        />
        </CSSTransition>
        <CSSTransition
              key={`prwtathlima${i}-transition`}
              in={i === 1 ? showPrwtathlimaField1 : i === 2 ? showPrwtathlimaField2 : i === 3 ? showPrwtathlimaField3 : false}
              mountOnEnter
              unmountOnExit
              timeout={200}
              classNames="modal"
          >
        <Input
          key={`prwtathlima${i}`}
          label="Πρωτάθλημα"
          id={`prwtathlima${i}`}
          name={`prwtathlima${i}`}
          type="select"
          validators={[VALIDATOR_REQUIRE()]}
          onInput={tipInputHandler}
          errorText="Πρέπει να επιλέξετε ένα πρωτάθλημα"
          defaultOption="Επιλέξτε ένα πρωτάθλημα"
          options={ i === 1 ? prwtathlimataBashXwrwn1 : i === 2 ? prwtathlimataBashXwrwn2 : i === 3 ? prwtathlimataBashXwrwn3 : []}
          numberButton={resetRef.current}
          value=""
          initialValid={false}
          onResetComplete={() => { resetRef.current = false; }}
          required
        />
        </CSSTransition>
        <Input
            key={`ghpedouxos${i}`}
            label="Γηπεδούχος"
            id={`ghpedouxos${i}`}
            name={`ghpedouxos${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`filoxenoumenos${i}`}
            label="Γηπεδούχος"
            id={`filoxenoumenos${i}`}
            name={`filoxenoumenos${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`date${i}`}
            label="Ημερομηνία"
            id={`date${i}`}
            name={`date${i}`}
            type="date"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
            dateValue={(dateV) => afterDateSelect(dateV)}
          />
          <Input
            key={`time${i}`}
            label="Ώρα"
            id={`time${i}`}
            name={`time${i}`}
            type="time"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
          <Input
            key={`pick${i}`}
            label="Pick"
            id={`pick${i}`}
            name={`pick${i}`}
            type="text"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key={`odds${i}`}
            label="Odds"
            id={`odds${i}`}
            name={`odds${i}`}
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
        </div>
      );
      formData[`athlima${i}`] = {
        value: '',
        isValid: false
      };
      formData[`agora${i}`] = {
        value: '',
        isValid: false
      };
      formData[`xwra${i}`] = {
        value: '',
        isValid: false
      };
      formData[`prwtathlima${i}`] = {
        value: '',
        isValid: false
      };
      formData[`ghpedouxos${i}`] = {
        value: '',
        isValid: false
      };
      formData[`filoxenoumenos${i}`] = {
        value: '',
        isValid: false
      };
      formData[`date${i}`] = {
        value: '',
        isValid: false
      };
      formData[`time${i}`] = {
        value: '',
        isValid: false
      };
      formData[`pick${i}`] = {
        value: '',
        isValid: false
      };
      formData[`odds${i}`] = {
        value: '',
        isValid: false
      };
    }

    tipsArray.push(
      <>
      <Input
            key="popuptext"
            label="Πληροφορίες"
            id="popuptext"
            rows="4"
            name="popuptext"
            validators={[]}
            onInput={tipInputHandler}
            numberButton={resetRef.current}
            value=""
            initialValid={true}
            onResetComplete={() => { resetRef.current = false; }}
            required
            inputClasses="block mb-4 p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
          <Input
            key="etairia"
            label="Εταιρεία"
            id="etairia"
            name="etairia"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε μια εταιρεία"
            defaultOption="Επιλέξτε μια εταιρεία"
            options={allEtaireies}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input
            key="telikoposo"
            label="Τελικό ποσό tip"
            id="telikoposo"
            name="telikoposo"
            type="number"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_NUMBER()]}
            onInput={tipInputHandler}
            errorText="Το πεδίο είναι υποχρεωτικό και δέχεται μόνο αριθμούς"
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
            additionalInputClasses="!w-fit"
          />
          <Input
            key="eidostip"
            label="Τύπος tip"
            id="eidostip"
            name="eidostip"
            type="select"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={tipInputHandler}
            errorText="Πρέπει να επιλέξετε έναν τύπο"
            defaultOption="Επιλέξτε έναν τύπο"
            options={['LIVE BET', 'LONG TERM BET', 'REGULAR']}
            numberButton={resetRef.current}
            value=""
            initialValid={false}
            onResetComplete={() => { resetRef.current = false; }}
            required
          />
          <Input 
              key="kleidwmeno"
              id = "kleidwmeno"
              element = 'input'
              type = 'checkbox'
              label = 'Κλειδωμένο'
              validators = {[]}
              onInput = {tipInputHandler}
              value='kleidwmeno'
              initialValid={true}
              initialName = 'Κλειδωμενο'
            />
             <Input
              key="season"
              label="Season"
              id="season"
              name="season"
              type="text"
              validators={[VALIDATOR_REQUIRE()]}
              onInput={tipInputHandler}
              errorText="Το πεδίο είναι υποχρεωτικό"
              numberButton={resetRef.current}
              value=""
              initialValid={false}
              onResetComplete={() => { resetRef.current = false; }}
              required
            />
      </>
    )
    formData['popuptext'] = {
      value: '',
      isValid: true
    };
    formData['etairia'] = {
      value: '',
      isValid: false
    };
    formData['telikoposo'] = {
      value: '',
      isValid: false
    };
    formData['eidostip'] = {
      value: '',
      isValid: false
    };
    formData['kleidwmeno'] = {
      value: '',
      isValid: true
    };
    formData['season'] = {
      value: '',
      isValid: false
    };
    
    setAllTips(tipsArray);
    setFormData(formData, false);
  };

  const cancelTipSubmitModal = () => {
    setShowTipSubmitModal(false);
  }
  
  

  const submitTipHandler = async (event) => {
    event.preventDefault();
    const betType = numberOfTipsRef.current === 1 ? 'mono' : numberOfTipsRef.current === 2 ? 'diplo' : numberOfTipsRef.current === 3 ? 'triplo' : '';
    const userId = currentUser.id;

    let tipData = {
        open: true,
        userId: userId,
        tiptype: betType,
        popuptext: event.target.popuptext.value,
        etairia: event.target.etairia.value,
        telikoposo: event.target.telikoposo.value,
        eidostip: event.target.eidostip.value,
        kleidwmeno: event.target.kleidwmeno.value,
        season: event.target.season.value
    };

    if (event.target.athlima3) {
        tipData = {
            ...tipData,
            athlima1: event.target.athlima1.value,
            agora1: event.target.agora1.value,
            xwra1: event.target.xwra1.value,
            prwtathlima1: event.target.prwtathlima1.value,
            ghpedouxos1: event.target.ghpedouxos1.value,
            filoxenoumenos1: event.target.filoxenoumenos1.value,
            date1: event.target.date1.value,
            time1: event.target.time1.value,
            pick1: event.target.pick1.value,
            odds1: event.target.odds1.value,
            athlima2: event.target.athlima2.value,
            agora2: event.target.agora2.value,
            xwra2: event.target.xwra2.value,
            prwtathlima2: event.target.prwtathlima2.value,
            ghpedouxos2: event.target.ghpedouxos2.value,
            filoxenoumenos2: event.target.filoxenoumenos2.value,
            date2: event.target.date2.value,
            time2: event.target.time2.value,
            pick2: event.target.pick2.value,
            odds2: event.target.odds2.value,
            athlima3: event.target.athlima3.value,
            agora3: event.target.agora3.value,
            xwra3: event.target.xwra3.value,
            prwtathlima3: event.target.prwtathlima3.value,
            ghpedouxos3: event.target.ghpedouxos3.value,
            filoxenoumenos3: event.target.filoxenoumenos3.value,
            date3: event.target.date3.value,
            time3: event.target.time3.value,
            pick3: event.target.pick3.value,
            odds3: event.target.odds3.value,
        };
    } else if (event.target.athlima2) {
        tipData = {
            ...tipData,
            athlima1: event.target.athlima1.value,
            agora1: event.target.agora1.value,
            xwra1: event.target.xwra1.value,
            prwtathlima1: event.target.prwtathlima1.value,
            ghpedouxos1: event.target.ghpedouxos1.value,
            filoxenoumenos1: event.target.filoxenoumenos1.value,
            date1: event.target.date1.value,
            time1: event.target.time1.value,
            pick1: event.target.pick1.value,
            odds1: event.target.odds1.value,
            athlima2: event.target.athlima2.value,
            agora2: event.target.agora2.value,
            xwra2: event.target.xwra2.value,
            prwtathlima2: event.target.prwtathlima2.value,
            ghpedouxos2: event.target.ghpedouxos2.value,
            filoxenoumenos2: event.target.filoxenoumenos2.value,
            date2: event.target.date2.value,
            time2: event.target.time2.value,
            pick2: event.target.pick2.value,
            odds2: event.target.odds2.value,
        };
    } else if (event.target.athlima1) {
        tipData = {
            ...tipData,
            athlima1: event.target.athlima1.value,
            agora1: event.target.agora1.value,
            xwra1: event.target.xwra1.value,
            prwtathlima1: event.target.prwtathlima1.value,
            ghpedouxos1: event.target.ghpedouxos1.value,
            filoxenoumenos1: event.target.filoxenoumenos1.value,
            date1: event.target.date1.value,
            time1: event.target.time1.value,
            pick1: event.target.pick1.value,
            odds1: event.target.odds1.value,
        };
    } else {
        return;
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${userid}/tips`,
        'POST',
        JSON.stringify({
          tipData
        }),
        {
          'Content-Type': 'application/json'
        }
      )

      numberOfTips('', numberOfTipsRef.current);
      setShowTipSubmitModal(true);
    } catch (err) {
      
    }
};




  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <Modal
          childClass="w-full"
          show={showTipSubmitModal} 
          onCancel={cancelTipSubmitModal} 
          header="Προσθήκη tip" 
          footerClass="flex w-full justify-between" 
          footer={
              <Button onClick={cancelTipSubmitModal}>
                Ok
              </Button>
            }
        >
           <p>Το tip σας προστέθηκε με επιτυχία! </p>
        </Modal>
      {currentUser ? (
        <>
      <h1 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">
        <span className="uppercase">{currentUser.username}</span> - Προσθήκη Tips
      </h1>
      <div className="inline-flex rounded-md shadow-sm mb-8" role="group">
        <Button
          type="button"
          id="firstbtn"
          onClick={(event) => numberOfTips(event, 1)}
          buttonClasses="tipBtn px-4 py-2 text-sm font-medium text-white bg-betblue border border-blue-700 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-blue-600 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Μονό
        </Button>
        <Button
          type="button"
          id="secondbtn"
          onClick={(event) => numberOfTips(event, 2)}
          buttonClasses="tipBtn px-4 py-2 text-sm font-medium text-white bg-betblue border-t border-b border-transparent border-l border-r border-blue-700 hover:bg-gray-100 hover:text-blue-700 dark:bg-blue-600 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Διπλό
        </Button>
        <Button
          type="button"
          id="thirdbtn"
          onClick={(event) => numberOfTips(event, 3)}
          buttonClasses="tipBtn px-4 py-2 text-sm font-medium text-white bg-betblue border border-blue-700 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 dark:bg-blue-600 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white"
        >
          Τριπλό
        </Button>
      </div>
      <form className="w-full mx-auto mb-9" onSubmit={submitTipHandler}>
        <div className="mb-5">
          {allTips}
        </div>
        <Button
          type="submit"
          buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800"
          disabled={!tipFormState.isValid}
        >
          Προσθήκη
        </Button>
      </form>
      </>
      ) : (<Card>
            <h1>Ο χρήστης δεν βρέθηκε</h1>
          </Card>)}
      
    </>
  );
};
