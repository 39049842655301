import { useState, useEffect, useContext } from "react"

import { VALIDATOR_REQUIRE } from "../shared/util/validators"
import { useTitle, Button, Modal, Input, useForm, Backdrop, AuthContext, ErrorModal, LoadingSpinner, useHttpClient, ImageUpload } from "../shared"

export const Athlimata = ({pageTitle}) => {
  useTitle(pageTitle);
  const [showAthlimaModal, setShowAthlimaModal] = useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [newAthlimaModal, setNewAthlimaModal] = useState(false);
  const [athlimataAll, setAthlimataAll] = useState([])
  const [updatedAthlimaName, setUpdatedAthlimaName] = useState(null);
  const [updatedAthlimaId, setUpdatedAthlimaId] = useState(null);
  const [updatedAthlimaImage, setUpdatedAthlimaImage] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const auth = useContext(AuthContext);

  const [athlimaEditFormState, athlimaEditInputHandler, athlimaEditSetFormData] = useForm({
    editAthlima: {
      value: '',
      isValid: false
    },
    editathlimafwto: {
      value: '',
      isValid: true
    }
  },
  false);

  const [newAthlimaFormState, newAthlimaInputHandler] = useForm({
    newathlima: {
      value: '',
      isValid: false
    },
    newathlimaimg: {
      value: '',
      isValid: true
    }
  },
  false);

  useEffect(() => {
    const fetchAthlimata = async () => {
      try {
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/athlimata`)

        setAthlimataAll(responseData.athlimata)
      } catch (err) {
        
      }
      
    }

    fetchAthlimata()
  }, [sendRequest, auth.userId])


  useEffect(() => {
    
    if (updatedAthlimaName && updatedAthlimaName.length > 1 ) {
      athlimaEditSetFormData({
        editAthlima: {
          value: updatedAthlimaName,
          isValid: true
        }
      },
      true
    )
    }
  }, [updatedAthlimaName, athlimaEditSetFormData])

  const athlimaClickHandler = (id) => {
    setShowAthlimaModal(true);
    const athlima = athlimataAll.find( athl => athl.id === id);
    if (athlima) {
      setUpdatedAthlimaName(athlima.name);
      setUpdatedAthlimaId(athlima.id);
      setUpdatedAthlimaImage(athlima.image)
    }
    
  }

  

  const cancelModal = () => {
    setShowAthlimaModal(false);
    setUpdatedAthlimaName(null);
    setUpdatedAthlimaId(null); 
  }

  const cancelDeleteModal = () => {
    setConfirmDeleteModal(false);
  }

  const cancelNewAhtlimaModal = () => {
    setNewAthlimaModal(false);
  }

  const updateHandler = async (e) => {
    e.preventDefault()
    const imageSrc = e.target.querySelector(".image-upload__preview img")?.src || null;
    try {
      const formData = new FormData()
      formData.append('name', athlimaEditFormState.inputs.editAthlima.value)
      if (athlimaEditFormState.inputs.editathlimafwto) {
        formData.append('image', athlimaEditFormState.inputs.editathlimafwto.value)
      }
      formData.append('previousImage', imageSrc)
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/athlimata/${updatedAthlimaId}`,
        'PATCH',
        formData
      )

      const updatedAthlimata = {...athlimataAll.find(athlima => athlima.id === updatedAthlimaId)};
      const athlimaIndex = athlimataAll.findIndex(athlima => athlima.id === updatedAthlimaId);
      updatedAthlimata.name = responseData.athlima.name;
      updatedAthlimata.image = responseData.athlima.image;
      athlimataAll[athlimaIndex] = updatedAthlimata;
      setShowAthlimaModal(false);
      setAthlimataAll(athlimataAll);
    } catch (err) {
      
    }
      
      
    
  }

  const deleteModalHandler = () => {
    setConfirmDeleteModal(true);
  }

  const confirmDeleteHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/athlimata/${updatedAthlimaId}`,
        'DELETE',
        null,
        {
          'Content-Type': 'application/json'
        }
      )
      
      setAthlimataAll(prevAthlimata => prevAthlimata.filter(athl => athl.id !== responseData.id));
      setShowAthlimaModal(false);
      setConfirmDeleteModal(false);
      setUpdatedAthlimaId(null);
    } catch (err) {
      
    }
    
    
  }


  const submitAthlimaHandler = async (e) => {
    e.preventDefault();
    
    try {
      const formData = new FormData()
      formData.append('name', newAthlimaFormState.inputs.newathlima.value)
      formData.append('image', newAthlimaFormState.inputs.newathlimaimg.value)
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/${auth.userId}/athlimata`,
        'POST',
        formData
      )

      setAthlimataAll(prevAthlimata => [...prevAthlimata, responseData.athlima]);
      setNewAthlimaModal(true);
    } catch (err) {
      
    }

  }

  

  

  return (
    <>
      {showAthlimaModal && <Backdrop onClick={cancelModal}/>}
      {isLoading && <LoadingSpinner asOverlay/>}
      <ErrorModal error={error} onClear={clearError}/>
      <Modal childClass="w-full" show={showAthlimaModal} onsubmit={updateHandler} onCancel={cancelModal} header="Επεξεργασία/διαγραφή αθλήματος" footerClass="flex w-full justify-between" footer={
          <>
            <Button inverse type="submit" disabled={!athlimaEditFormState.isValid}>
              Ενημέρωση
            </Button>
            <Button danger type="button" onClick={deleteModalHandler}>
              Διαγραφή
            </Button>
          </>}>
            <Input label="Μετονομασία αθλήματος" initialValue={updatedAthlimaName} initialValid={true} id="editAthlima" name="editAthlima" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={athlimaEditInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
            <ImageUpload label="Προσθήκη φωτογραφίας" onInput={athlimaEditInputHandler} initialValue={updatedAthlimaImage !== null ? `${process.env.REACT_APP_BACKEND_URL}/${updatedAthlimaImage}` : null} initialValid={true} id="editathlimafwto" name="editathlimafwto" errorText="Παρακαλώ προσθέστε μια εικόνα"/>
      </Modal>
      <Modal
        childClass="w-full"
        show={confirmDeleteModal} 
        onCancel={cancelDeleteModal} 
        header="Διαγραφή αθλήματος" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelDeleteModal}>
              Ακύρωση
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Διαγραφή
            </Button>
          </>}
      >
        <p>Θέλετε να προχωρήσετε και να διαγράψετε αυτό το άθλημα; Η ενέργεια αυτή δεν μπορεί να αναιρεθεί στη συνέχεια. </p>
      </Modal>
      <Modal 
        childClass="w-full"
        show={newAthlimaModal} 
        onCancel={cancelNewAhtlimaModal} 
        header="Προσθήκη αθλήματος" 
        footerClass="flex w-full justify-between" 
        footer={
          <>
            <Button onClick={cancelNewAhtlimaModal}>
              Ok
            </Button>
          </>}
      >
        <p>Το άθλημα προστέθηκε με επιτυχία! </p>
      </Modal>
      <h1 className="mb-1 text-2xl font-medium text-gray-900 dark:text-white">Αθλήματα</h1>
      <p className="mb-7 text-base font-medium text-gray-900 dark:text-white">Κάντε κλικ στο άθλημα που θέλετε να επεξεργαστείτε/διαγράψετε</p>
      <div className="flex flex-wrap gap-2.5 justify-between w-full text-sm font-medium text-gray-900 bg-white dark:text-white">
        {athlimataAll && athlimataAll.length > 0 ? ( athlimataAll.map((athlima) => {
          const classes = "block basis-1/5 px-4 py-2 text-white border-b rounded-lg border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
          return (
            <Button
              key={athlima.id}// Add a unique key for each element in the list
              buttonClasses={classes}
              onClick={() => athlimaClickHandler(athlima.id)}
            >
              {athlima.name}
            </Button>
          );
        }) ) : (
          <p className="text-base">Δεν βρέθηκαν αθλήματα</p>
        )}
      </div>
      <h2 className="mt-8 mb-3 text-xl font-medium text-gray-900 dark:text-white">Προσθήκη Αθλήματος</h2>
      <form className="w-full mx-auto mb-9" onSubmit={submitAthlimaHandler}>
        <div className="mb-5">
        <Input label="Νέο άθλημα" id="newathlima" name="newathlima" type="text" validators={[VALIDATOR_REQUIRE()]} onInput={newAthlimaInputHandler} errorText="Το πεδίο είναι υποχρεωτικό" required/>
        </div>
        <ImageUpload onInput={newAthlimaInputHandler} id="newathlimaimg" name="newathlimaimg"errorText="Παρακαλώ προσθέστε μια εικόνα"/>
        <Button type="submit" buttonClasses="w-full text-white bg-betblue hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-betblue dark:focus:ring-blue-800" disabled={!newAthlimaFormState.isValid}>Προσθήκη</Button>
        </form>
    </>
  )
}
