import { NavLink, useLocation, useNavigate, matchPath } from "react-router-dom"
import { useContext } from "react";

import { AuthContext } from "../../../shared";

export const NavLinks = () => {
    const auth = useContext(AuthContext);
    
    
   const location = useLocation();
   const navigate = useNavigate();
   const logoutHandler = () => {
    auth.logout();
    navigate('/');
   }

  return (
    <ul className="flex flex-wrap flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                        {location.pathname === '/' && <li>
                            <NavLink to={`/user/${auth.userId}`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προφιλ</NavLink>
                        </li>
                        }
                        {location.pathname === `/user/${auth.userId}` && <li>
                            <NavLink to={`/users/${auth.userId}/prognwstika`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προγνωστικά</NavLink>
                        </li>
                        }
                        {location.pathname === `/users/${auth.userId}/prognwstika` && 
                        <>
                            <li> 
                                <NavLink to={`/users/${auth.userId}/tips`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Tips</NavLink>
                            </li>
                            <li>
                                <NavLink to={`/users/${auth.userId}/apotelesmata`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Αποτελεσμάτων</NavLink>
                            </li>
                        </>
                        
                        }
                        {location.pathname === `/users/${auth.otherUserId}/prognwstika` && 
                        <>
                            <li>

                            <NavLink to={`/users/${auth.role === 'admin' ? auth.otherUserId : auth.userId}/tips`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50 lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50 lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Tips</NavLink>
                        </li>
                        <li>
                            <NavLink to={`/users/${auth.role === 'admin' ? auth.otherUserId : auth.userId}/apotelesmata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50 lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50 lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Αποτελεσμάτων</NavLink>
                        </li>
                        </>
                        
                        }
                        {location.pathname === `/users/${auth.userId}/statistika` && 
                        <>
                            <li>
                                <NavLink to={`/users/${auth.userId}/tips`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Tips</NavLink>
                            </li>
                            <li>
                                <NavLink to={`/users/${auth.userId}/apotelesmata`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Αποτελεσμάτων</NavLink>
                            </li>
                        </>
                        
                        }
                        {location.pathname === `/users/${auth.otherUserId}/statistika` && 
                        <>
                            <li>
                                <NavLink to={`/users/${auth.otherUserId}/tips`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Tips</NavLink>
                            </li>
                            <li>
                                <NavLink to={`/users/${auth.otherUserId}/apotelesmata`} className={({isActive}) => isActive ? "current block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" : "block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"}>Προσθήκη Αποτελεσμάτων</NavLink>
                            </li>
                        </>
                        
                        }
                        {location.pathname !== `/users/${auth.userId}/prognwstika` && location.pathname !== `/users/${auth.userId}/statistika` && matchPath(`/users/${auth.userId}/*`, location.pathname) && (
                        <>
                            <li>
                                <NavLink to={`/users/${auth.userId}/epeksergasia-apotelesmatwn`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Επεξεργασία Αποτελεσμάτων</NavLink>
                            </li>
                            <li>
                                <NavLink to={`/users/${auth.userId}/apotelesmata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Αποτελεσμάτων</NavLink>
                            </li>
                            <li>
                                <NavLink to={`/users/${auth.userId}/tips`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Tips</NavLink>
                            </li>

                            {/* Admin-only links */}
                            {auth.role === 'admin' && (
                                <>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/agores`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Αγορές</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/athlimata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Αθλήματα</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/etaireies`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Εταιρείες</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/prwtathlimata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Πρωταθλήματα</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/xrhstes`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Χρήστες</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`/users/${auth.userId}/xwres`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Χώρες</NavLink>
                                    </li>
                                </>
                            )}
                        </>
                    )}
                        {location.pathname !== `/users/${auth.otherUserId}/prognwstika` && location.pathname !== `/users/${auth.otherUserId}/statistika` && matchPath(`/users/${auth.otherUserId}/*`, location.pathname) && (
                            <>
                                    {auth.role === 'admin' && (
                                        <>
                                            <li>
                                                <NavLink to={`/users/${auth.otherUserId}/epeksergasia-apotelesmatwn`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Επεξεργασία Αποτελεσμάτων</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.otherUserId}/apotelesmata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Αποτελεσμάτων</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.otherUserId}/tips`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Tips</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/agores`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Αγορές</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/athlimata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Αθλήματα</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/etaireies`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Εταιρείες</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/prwtathlimata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Πρωταθλήματα</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/xrhstes`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Χρήστες</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/xwres`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Χώρες</NavLink>
                                            </li>
                                        </>
                                    )}
                                    {auth.role !== 'admin' && (
                                        <>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/epeksergasia-apotelesmatwn`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Επεξεργασία Αποτελεσμάτων</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/apotelesmata`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Αποτελεσμάτων</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={`/users/${auth.userId}/tips`} className={({ isActive }) => isActive ? "current block py-2 pr-4 pl-3 text-white ..." : "block py-2 pr-4 pl-3 text-white ..."}>Προσθήκη Tips</NavLink>
                                            </li>
                                        </>
                                    )}
                                </>
                            )}
                        <li>
                            <button type="button" onClick={logoutHandler} className="block py-2 pr-4 pl-3 text-white hover:text-betblue hover:bg-gray-50  lg:border-0 lg:hover:text-primary-700 lg:p-1 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Logout</button>
                        </li>
                    </ul>
  )
}
