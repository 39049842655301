import { useContext, useEffect, useState } from "react";
import { useTitle, Button, AuthContext, ErrorModal, LoadingSpinner, useHttpClient } from "../shared";



export const UsersList = ({ pageTitle }) => {
  useTitle(pageTitle);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient()
  const [loadedUsers, setLoadedUsers] = useState()

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const responseData = await sendRequest(process.env.REACT_APP_BACKEND_URL, 'GET', null, {
          Authorization: `Bearer ${auth.token}`
        });

        setLoadedUsers(responseData.xrhstes)
      } catch (err) {
    
      }
    };

    fetchUsers();
  }, [sendRequest, auth.token])
  
  const clickHandler = (id) => {
    if (id !== auth.userId) {
      auth.getOtherUserId(id);
    }
    
    return;
  }


  return (
    <>
      <ErrorModal error={error} onClear={clearError}/>
      {isLoading && <LoadingSpinner asOverlay/>}
      <div className="w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
        {!isLoading && loadedUsers && loadedUsers.map((user, index) => {
          const classes = (index === 0) ? "block w-full px-4 py-2 text-white bg-betblue border-b border-gray-200 rounded-t-lg cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:bg-gray-800 dark:border-gray-600" : index === loadedUsers.length - 1 ? "block w-full px-4 py-2 text-white rounded-b-lg cursor-pointer bg-betblue hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white" : "block w-full px-4 py-2 text-white border-b border-gray-200 bg-betblue cursor-pointer hover:bg-gray-100 hover:text-blue-700 dark:border-gray-600 dark:hover:bg-gray-600 dark:hover:text-white dark:focus:ring-gray-500 dark:focus:text-white";
          return (
            <Button
              key={user.id} // Add a unique key for each element in the list
              id={user.id}
              to={`/users/${user.id}/prognwstika`}
              buttonClasses={classes}
              onClick={() => clickHandler(user.id)}
            >
              {user.username}
            </Button>
          );
        })}
      </div>
    </>
  )
}
