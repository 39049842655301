import { useContext, useState } from "react";
import { Link } from "react-router-dom";

import { AuthContext, NavLinks, SideDrawer, Backdrop } from "../../../shared"

import  logowhite  from '../../../assets/logowhite.png'


export const MainNavigation = props => {
    const auth = useContext(AuthContext);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawerHandler = () => {
        setIsDrawerOpen(true)
    } 

    const closeDrawerHandler = () => {
        setIsDrawerOpen(false)
    }
  return (
    <header >
        {isDrawerOpen && <Backdrop onClick={closeDrawerHandler} />}
        <SideDrawer show={isDrawerOpen} onClick={closeDrawerHandler}>
        <nav className="bg-betblue border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800 h-full">
            <div className="flex flex-wrap justify-between items-center mx-auto w-full">
                <div className="justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                    <NavLinks />
                </div>
            </div>
        </nav>
        </SideDrawer>
        <nav className="bg-betblue border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
            <div className="flex flex-wrap justify-between items-center mx-auto w-full">
                <Link to="/" className="flex items-center">
                    <img src={logowhite} className="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
                </Link>
                { auth.isLoggedIn &&  <div className="flex items-center lg:order-2 lg:hidden">
                    <button onClick={openDrawerHandler} data-collapse-toggle="mobile-menu-2" type="button" className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg lg:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                        <span className="sr-only">Open main menu</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                        <svg className="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                </div> }
                <div className="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                { auth.isLoggedIn && <NavLinks />}
                </div>
            </div>
        </nav>
    </header>
  )
}
