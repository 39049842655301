import React from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { Backdrop } from './Backdrop';
import './Modal.css'

  const ModalOverlay = props => {
    const content = (
                <div className="modal relative p-4 w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <header className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                {props.header}
                            </h3>
                            <svg xmlns="http://www.w3.org/2000/svg" onClick={props.onCancel} width="24" height="24" viewBox="0 0 24 24">
                                <circle cx="12" cy="12" r="11" stroke="black" strokeWidth="2" fill="none"/>
                                <path fill="none" stroke="black" strokeWidth="2" d="M6 6l12 12M6 18L18 6"/>
                            </svg>


                        </header>
                        <div className="p-4 md:p-5">
                            <form className="space-y-4 w-full" onSubmit={ props.onsubmit ? props.onsubmit : event => event.preventDefault()   }>
                               
                                <div className="flex justify-between">
                                    <div className="flex items-start w-full">
                                        <div className={props.childClass}>
                                            {props.children}
                                        </div>
                                        
                                    </div>
                                </div>
                                <footer className={props.footerClass}>
                                            {props.footer}
                                </footer>
                            </form>
                        </div>
                    </div>
                </div>
            
    );
    return ReactDOM.createPortal(content, document.getElementById('modal-hook'));
  };
  
  export const Modal = props => {
    return (
            <React.Fragment>
                {props.show && <Backdrop onClick={props.onCancel} /> }
                <CSSTransition
                    in={props.show}
                    mountOnEnter
                    unmountOnExit
                    timeout={200}
                    classNames="modal"
                >
                    <ModalOverlay {...props} />
                </CSSTransition>
            </React.Fragment>
        )
  }

